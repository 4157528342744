<template>
  <div
    :class="{
      [spanClass]: true,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'GridCell',
  props: {
    span: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    spanClass() {
      return this.span ? `col-${this.span}` : 'col';
    },
  },
};
</script>
