<template>
  <div
    :class="{
      'input-error': validationmessage,
      'input-disabled': disabled,
      'input-base': true,
      'hp-input-class': true,
    }"
  >
    <fieldset>
      <label v-if="fieldlabel">
        <legend>
          {{ fieldlabel }}
          <hp-tooltip
            :label="label"
            :helpertextcontent="helpertextcontent"
          >
            <template #helpertextcontent>
              <slot name="helpertextcontent" />
            </template>
          </hp-tooltip>
        </legend>
      </label>

      <q-field
        no-error-icon
        :error="!!validationmessage"
        hide-hint
        @blur="onBlur"
        @focus="onFocus"
      >
        <div class="column">
          <div
            v-for="(item, index) in smartOptions"
            :key="index"
          >
            <q-checkbox
              v-model="selection"
              :val="item.value"
              :label="item.label"
              :disable="item.disable || disabled"
              @input="onInput"
            />
            <slot :name="`checkboxhelpertextcontent-${index}`" />
          </div>
        </div>
        <template #error>
          <div class="error">
            {{ validationmessage }}
          </div>
        </template>
      </q-field>
    </fieldset>
  </div>
</template>
<script>
import HpTooltip from '../tooltip/Tooltip.vue';
import { apiClient } from '@/modules/core/services/api';

export default {
  name: 'HpCheckboxes',
  components: {
    HpTooltip,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    helpertextcontent: {
      type: String,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    optionsUrl: {
      type: String,
    },
    validationmessage: {
      type: String,
    },
    value: {
      type: [Array, Boolean, String],
    },
    required: {
      type: Boolean,
    },
  },
  data() {
    return {
      selection: this.value || [],
      smartOptions: this.options || [],
    };
  },
  computed: {
    fieldlabel() {
      return this.required ? `${this.label} *` : this.label;
    },

  },
  watch: {
    selection() {
      // if clause will only not happen in unit testing
      if (this.$eventBus) this.$eventBus.$emit('controlUpdated', { label: this.label });
    },
    optionsUrl() {
      this.loadOptions();
    },
  },
  created() {
    this.loadOptions();
  },
  methods: {
    onBlur() {
      this.$emit('change', this.value);
    },
    onFocus() {
      this.$emit('focus', this.value);
    },
    onInput(value) {
      this.$emit('change', value);
      this.$emit('input', value);

      let displayValue = null;
      if (Array.isArray(value)) {
        displayValue = this.smartOptions.filter(r => value.includes(r.value)).map(s => s.label);
      } else {
        displayValue = this.smartOptions.find(r => r.value === value).label;
      }

      this.$emit('update-display-value', displayValue);
      if (this.$eventBus) this.$eventBus.$emit('controlUpdated', { label: this.label });
    },
    async loadOptions() {
      if (this.options || !this.optionsUrl) return;

      try {
        const { data: { data: smartOptions } } = await apiClient.get(this.optionsUrl);
        this.smartOptions = smartOptions;

        const selectedItems = this.smartOptions
          .reduce((acc, { value, selected, disable }) => {
            if (selected || (!disable && this.value?.includes(value))) {
              acc.push(value);
            }
            return acc;
          }, []);

        this.onInput(selectedItems);
        this.selection = selectedItems;
        this.$emit('blur', selectedItems);
      } catch (error) {
        this.$emit('error', error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
fieldset {
  border: none;
  margin: 0;
  padding: 0;
  legend {
    display: block;
  }
}
</style>
