<template>
  <q-btn-dropdown
    dropdown-icon="apps"
    size="1.1rem"
    class="link-button"
    flat
    :ripple="false"
    :content-style="{ minWidth: 220 }"
    padding="0 1rem 0 0"
  >
    <q-list>
      <q-item
        v-close-popup
        clickable
        dense
      >
        <q-item-section>
          <div class="section-grid">
            <q-icon
              name="radio_button_checked"
              class="selected-icon"
            />
            <q-item-label>
              <router-link
                :to="{ name: 'home'}"
                class="text-body2 selected-item"
              >
                Hosting Portal
              </router-link>
            </q-item-label>
          </div>
        </q-item-section>
      </q-item>
      <q-item
        v-for="(link, index) of links"
        :key="index"
        v-close-popup
        clickable
        dense
      >
        <q-item-section>
          <div class="section-grid">
            <div />
            <q-item-label>
              <a
                :href="link.url"
                target="_blank"
                rel="noopener noreferrer"
                class="text-body2"
              >
                {{ link.name }}
              </a>
            </q-item-label>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
export default {
  name: 'LinkButton',
  computed: {
    links() {
      return [{
        name: 'Web Hosting',
        url: '//hosting.gsk.com',
      }, {
        name: 'Infrastructure Management Portal',
        url: '//infra-mgmt.gsk.com',
      }, {
        name: 'IAM Portal',
        url: '//imservices.gsk.com/iamportal',
      }, {
        name: 'Developer Portal',
        url: '//developer.gsk.com',
      }, {
        name: 'CODE Orange',
        url: '//codeorange.gsk.com',
      },
      {
        name: 'Digital Marketing Self-Service',
        url: '//dmss.gsk.com/',
      },
      ];
    },
  },
};
</script>
<style lang="scss">

.q-menu.q-position-engine {
  .q-list--dense > .q-item, .q-item--dense {
    padding-left: .5rem;
  }

  .section-grid {
    display: grid;
    grid-template-columns: 1rem auto;

    .text-body2 {
      white-space: pre;
      font-weight: 500;
      color: $color-typography;
    }

    .selected-icon {
      position: relative;
      top: .2rem;
      color: $color-accent;
    }

    .selected-item {
      color: $color-accent;
    }
  }
}

.link-button {
  &.q-hoverable.q-btn i {
    color: $color-typography;
  }

  &.q-hoverable.q-btn:hover i {
     color: rgb(21, 113, 125);
  }

  &.q-hoverable.q-btn {
    i:hover {
      opacity: 0.5;
    }
    .q-focus-helper {
      display:none;
    }
  }
}

</style>
