import PageNotFound from '../views/pageNotFound/PageNotFound.vue';
import Forbidden from '../views/Forbidden.vue';
import ServerError from '../views/ServerError.vue';

const routes = [
  {
    path: '/server-error',
    name: 'serverError',
    component: ServerError,
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
  },
  {
    path: '/page-not-found',
    name: 'pageNotFound',
    alias: '*',
    component: PageNotFound,
  },
];

export default routes;
