<template>
  <div>
    <template v-if="!skipPreamble">
      <p class="text-body2 text-secondary">
        As the Business Owner and Technical Owner of the Cloud resource, I acknowledge that I have read, understood and completed all of the prerequisites outlined on the
        <a
          :href="overviewLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          Overview Page
        </a>,
        before submitting this self-service form.
      </p>
      <p class="text-body2 q-mb-2 text-secondary">
        Please note - If you are a requester submitting this form on behalf of the Business Owner and Technical Owner, you are obliged to share and confirm the Prerequisites & Acknowledgements with the individuals prior to completing the form.
      </p>
    </template>

    <validation-observer v-slot="{ failed }">
      <validation-provider :rules="{ required: { allowFalse: false } }">
        <hp-checkbox
          v-model="model.architecture"
          label="I have read and approved architecture."
        />
      </validation-provider>
      <validation-provider
        v-if="userAgreements.includes('privilegedAccessTraining')"
        :rules="{ required: { allowFalse: false } }"
      >
        <hp-checkbox
          v-model="model.privilegedAccessTraining"
          label="I have completed the Privilege Access Certification [BUS-TEC-399]."
        />
      </validation-provider>
      <validation-provider
        v-if="userAgreements.includes('cloudTraining')"
        :rules="{ required: { allowFalse: false } }"
      >
        <hp-checkbox
          v-model="model.cloudTraining"
          label="I have completed the Cloud at GSK training [BUS-PAP-CLOUD-001]."
        />
      </validation-provider>
      <validation-provider :rules="{ required: { allowFalse: false } }">
        <hp-checkbox
          v-model="model.tenantResponsibilities"
        >
          <template #label>
            I have read and understood the tenant responsibilities outlined in the
            <a
              href="https://gsk.service-now.com/home?id=kb_article_view&sysparm_article=KB0021607"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop
            >
              Cloud Shared Responsibilities Agreement
            </a>.
          </template>
        </hp-checkbox>
      </validation-provider>
      <validation-provider :rules="{ required: { allowFalse: false } }">
        <hp-checkbox
          v-model="model.validationProcess"
          label="I confirm the Technical Owner will engage GRC to ensure compliance."
        />
      </validation-provider>
      <validation-provider
        v-if="userAgreements.includes('sox')"
        :rules="{ required: { allowFalse: false } }"
      >
        <hp-checkbox
          v-model="model.cloudResourceSoX"
          label="I confirm this Cloud resource will not be used for Sarbanes-Oxley (SOX) applications or data."
        />
      </validation-provider>
      <validation-provider
        v-if="userAgreements.includes('resourceGroupObjectId')"
        :rules="{ required: { allowFalse: false } }"
      >
        <hp-checkbox
          v-model="model.resourceGroupObjectId"
          label="I have the Azure Resource Group Object Id of the Resource Group the resource needs to be deployed in."
        />
      </validation-provider>
      <validation-provider :rules="{ required: { allowFalse: false } }">
        <hp-checkbox
          v-model="model.mandatoryTrainingRequirements"
          label="I have completed all mandatory privileged access training requirements."
        />
      </validation-provider>
      <validation-provider :rules="{ required: { allowFalse: false } }">
        <hp-checkbox
          v-model="model.financialResponsibilities"
          label="I understand the financial responsibilities of being a Cloud resource owner."
        />
      </validation-provider>
      <validation-provider
        v-if="userAgreements.includes('sharedAgreement')"
        :rules="{ required: { allowFalse: false } }"
      >
        <hp-checkbox
          v-model="model.sharedAgreement"
        >
          <template #label>
            I understand the Cloud resources I will provision within this standalone GCP Project, will be self-managed and supported. I have discussed with my GRC representative that I will qualify these Cloud resources and follow the compliance requirements outlined in the
            <a
              href="https://kgcampus.sapjam.com/groups/aefQcMo7jFUMiE7oXoPe4d/overview_page/LjAeMinBfknNbc2HsQs6Ye"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop
            >
              Qualification and Compliance - Cloud at GSK
            </a>
            Module and the tenant responsibilities outlined in the
            <a
              href="https://gsk.service-now.com/home?id=kb_article_view&sysparm_article=KB0021607"
              target="_blank"
              rel="noopener noreferrer"
              @click.stop
            >
              Cloud Shared Responsibilities Agreement.
            </a>
          </template>
        </hp-checkbox>
      </validation-provider>
      <div
        v-if="failed"
        data-test-id="validation-message"
        class="text-negative"
      >
        You must agree with all the above statements.
      </div>
    </validation-observer>
  </div>
</template>
<script>
import HpCheckbox from '@/componentLibrary/checkbox/Checkbox.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@/modules/core/validators/formValidators';

export default {
  name: 'UserAgreements',
  components: {
    HpCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    skipPreamble: {
      type: Boolean,
    },
    overviewLink: {
      type: String,
    },
    userAgreements: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      model: null,
    };
  },
  watch: {
    model: {
      deep: true,
      handler(model) {
        const summary = Object.values(model).every(value => value);
        if (summary) return this.$emit('input', true);
        if (this.value) this.$emit('input', false);
      },
    },
  },
  created() {
    const model = {
      architecture: this.value,
      tenantResponsibilities: this.value,
      validationProcess: this.value,
      cloudResourceSoX: this.value,
      resourceGroupObjectId: this.value,
      mandatoryTrainingRequirements: this.value,
      financialResponsibilities: this.value,
    };
    if (this.userAgreements.includes('privilegedAccessTraining')) model.privilegedAccessTraining = this.value;
    if (this.userAgreements.includes('cloudTraining')) model.cloudTraining = this.value;
    if (this.userAgreements.includes('sharedAgreement')) model.sharedAgreement = this.value;
    if (this.userAgreements.includes('resourceGroupObjectId')) model.sharedAgreement = this.value;
    this.model = model;
  },
};
</script>

<style scoped lang="scss">
:deep(.q-checkbox) {
  .q-checkbox__label {
    color: $secondary;
    margin-left: 8px;
  }
  &[aria-checked="true"] {
    .q-checkbox__label {
      color: $primary;
    }
  }
}

.agreement {
  display: flex;
}
</style>
