<template>
  <q-btn-dropdown
    :label="label"
    :icon="iconToDisplay"
    :color="color"
    rounded
    :flat="!color"
    :fab-mini="!label"
    :class="{
      'hp-menu-button': true,
      'chevron': chevron,
    }"
  >
    <template
      v-if="$slots.label"
      #label
    >
      <slot name="label" />
    </template>
    <q-list>
      <q-item
        v-for="option of options"
        :key="option.name"
        v-close-popup
        :disable="!option.enabled"
        :clickable="option.enabled"
        @click="() => clickedItem(option.name)"
      >
        <q-item-section>
          <q-item-label>{{ option.name }}</q-item-label>
        </q-item-section>
        <q-tooltip
          v-if="option.tooltip"
          anchor="top middle"
          self="bottom middle"
          content-class="tooltip-content-class"
          :offset="[0, 5]"
        >
          {{ option.tooltip }}
        </q-tooltip>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
export default {
  name: 'HpMenuButton',
  props: {
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
    },
    icon: {
      type: String,
    },
    color: {
      type: String,
    },
    chevron: {
      type: Boolean,
    },
  },
  computed: {
    iconToDisplay() {
      return !this.icon && !this.label && !this.$slots.label ? 'more_vert' : this.icon ? this.icon : undefined;
    },
  },
  methods: {
    clickedItem(label) {
      this.$emit('click', { button: label });
      this.$emit(
        label
          .toLocaleLowerCase()
          .split(' ')
          .join('-'),
      );
    },
  },
};
</script>

<style lang="scss">
.hp-menu-button {
  &:not(.chevron) {
    .q-btn-dropdown__arrow {
      display: none;
    }
  }
}
</style>
