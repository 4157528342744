var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.websiteStatus && _vm.websiteStatus.message && !_vm.error)?_c('q-banner',{class:{
      'no-padding': true,
      'banner': true,
      'text-white': _vm.websiteStatus.type && _vm.websiteStatus.type.toLowerCase() === 'error',
      'bg-info': _vm.websiteStatus.type && _vm.websiteStatus.type.toLowerCase() === 'info',
      'bg-negative': _vm.websiteStatus.type && _vm.websiteStatus.type.toLowerCase() === 'error',
    },attrs:{"inline-actions":"","dense":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('q-btn',{staticClass:"q-mr-1",attrs:{"flat":"","label":"×","dense":""},on:{"click":function($event){_vm.websiteStatus = null}}})]},proxy:true}],null,false,330129239)},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.websiteStatus && _vm.websiteStatus.message)+" ")])]):_vm._e(),(_vm.error)?_c('component-failed',{attrs:{"error":_vm.error}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }