/* eslint-disable no-bitwise */

const getAvatarColor = reference => {
  const avatarColors = ['#40488d', '#e49b13', '#bc1077', '#f36633', '#008a00'];
  let hash = 0;
  if (!reference.length) return avatarColors[0];
  for (let i = 0; i < reference.length; i += 1) {
    hash = (hash << 5) - hash + reference.charCodeAt(i);
    hash &= hash;
  }
  return avatarColors[Math.abs(hash % avatarColors.length)];
};

export default class SearchModel {
  constructor({ data }) {
    this.entries = [];

    if (!data) return;

    this.entries = data.map(user => ({
      ...user,
      avatar: getAvatarColor(user.mudId),
    }));
  }
}
