export default {
  routes: [
    {
      path: '/my-projects/overview',
      name: 'myProjectsOverview',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/myProjects/views/myProjectsOverviewPage/MyProjectsOverviewPage'
      ),
    },
    {
      path: '/my-projects/form',
      name: 'myProjectsForm',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/myProjects/views/myProjectsFormPage/MyProjectsFormPage'
      ),
      meta: { requestType: 'HP_PROJECT' },
    },
    {
      path: '/my-projects/:project',
      name: 'myProjects',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/myProjects/views/myProject/MyProjectView.vue'
      ),
      children: [
        {
          path: '/my-projects/:project/dashboard',
          name: 'myProjectDashboard',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/myProjects/tabs/myProjectDashboardTab/MyProjectDashboardTab'
          ),
        },
        {
          path: '/my-projects/:project/details',
          name: 'myProjectDetails',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/myProjects/tabs/myProjectDetailsTab/MyProjectDetailsTab'
          ),
        },
      ],
    },
  ],
};
