export default {
  breadcrumbParent: 'home',
  breadcrumbTitle: 'CaaS',
  desc: 'Request a namespace in the shared internal-facing AKS cluster.',
  graphic: 'container',
  id: 'caas',
  menuTitle: 'CaaS',
  name: 'CaaS',
  path: '/caas',
  routeName: 'caas',
  services: [
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'AKS Namespace',
      desc: 'Request a namespace in the shared internal-facing Azure Kubernetes Service cluster.',
      graphic: 'container',
      id: 'aksNamespaceOverview',
      menuTitle: 'AKS Namespace',
      name: 'Kubernetes Namespace',
      path: '/caas/aks-namespace/overview',
      routeName: 'aksNamespaceOverview',
    },
    {
      breadcrumbParent: 'aksNamespaceOverview',
      breadcrumbTitle: 'Create',
      id: 'aksNamespaceForm',
      path: '/caas/aks-namespace/form',
      routeName: 'aksNamespaceForm',
    },
    {
      breadcrumbParent: 'aksNamespaceOverview',
      breadcrumbTitle: 'FAQ',
      id: 'aksNamespaceFAQ',
      path: '/caas/aks-namespace/faq',
      routeName: 'aksNamespaceFAQ',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'AKS Cluster',
      desc: 'Request a dedicated Azure Kubernetes Service cluster.',
      graphic: 'container',
      id: 'aksClusterOverview',
      menuTitle: 'AKS Cluster',
      name: 'Kubernetes Cluster',
      path: '/caas/aks-cluster/overview',
      routeName: 'aksClusterOverview',
    },
    {
      breadcrumbParent: 'aksClusterOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'aksClusterDashboard',
      path: '/caas/aks-cluster/dashboard',
      routeName: 'aksClusterDashboard',
    },
    {
      breadcrumbParent: 'aksClusterOverview',
      breadcrumbTitle: 'Create',
      id: 'aksClusterForm',
      path: '/caas/aks-cluster/form',
      routeName: 'aksClusterForm',
    },
    {
      breadcrumbParent: 'aksClusterOverview',
      breadcrumbTitle: 'FAQ',
      id: 'aksClusterFAQ',
      path: '/caas/aks-cluster/faq',
      routeName: 'aksClusterFAQ',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'GKE Cluster',
      desc: 'Request a dedicated Google Kubernetes Engine cluster.',
      graphic: 'container',
      id: 'gkeClusterOverview',
      menuTitle: 'GKE Cluster',
      name: 'GKE Cluster',
      path: '/caas/gke-cluster/overview',
      routeName: 'gkeClusterOverview',
    },
    {
      breadcrumbParent: 'gkeClusterOverview',
      breadcrumbTitle: 'FAQ',
      id: 'gkeClusterFaq',
      path: '/caas/gke-cluster/faq',
      routeName: 'gkeClusterFaq',
    },
    {
      breadcrumbParent: 'gkeClusterOverview',
      breadcrumbTitle: 'Create',
      id: 'gkeClusterForm',
      path: '/caas/gke-cluster/form',
      routeName: 'gkeClusterForm',
    },
  ],
};
