<template>
  <div class="org-selector">
    <validation-provider
      v-slot="{ errors }"
      mode="eager"
      name="subnetSize"
      :rules="{ required: true }"
    >
      <hp-select
        :label="constants.subnetSize.label"
        :options="subnetSizeOptions"
        data-test-id="subnet-size"
        :validationmessage="errors[0]"
        :helpertextcontent="constants.subnetSize.helperText"
        required
        :disabled="disabled"
        :value="value.subnetSize"
        @input="subnetSizesUpdated"
        @update-display-value="newVal => $emit('update-display-value', { [constants.subnetSize.label]: newVal})"
      />
    </validation-provider>
    <validation-provider
      v-slot="{ errors }"
      mode="eager"
      name="minClusterNodes"
      :rules="{
        required: true,
        min_value: 2,
        max_value: clusterNodesMaxNumber - 1,
      }"
      :custom-messages="{
        min_value: 'Minimum number of cluster worker nodes is 2.',
        max_value: `Maximum number of cluster worker nodes must be less than ${clusterNodesMaxNumber}`,
      }"
    >
      <hp-input
        :label="constants.minClusterNodes.label"
        :helpertextcontent="getMinClustersHelperText"
        :validationmessage="errors[0]"
        type="number"
        :disabled="disabled || !value.subnetSize"
        required
        :value="value.minClusterNodes"
        data-test-id="min-nodes-input"
        @input="minClusterNodesUpdated"
        @update-display-value="e => $emit('update-display-value', { [constants.minClusterNodes.label]: e})"
      />
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      mode="eager"
      name="maxClusterNodes"
      :rules="{
        required: true,
        min_value: clusterNodesMinNumber,
        max_value: clusterNodesMaxNumber,
      }"
      :custom-messages="{
        min_value: 'Maximum can not be less than or equal to minimum.',
        max_value: `Maximum number of cluster worker nodes is ${clusterNodesMaxNumber} for this subnet size.`,
      }"
    >
      <hp-input
        :label="constants.maxClusterNodes.label"
        :helpertextcontent="getMaxClustersHelperText"
        :validationmessage="errors[0]"
        type="number"
        :disabled="disabled || !value.subnetSize"
        required
        :value="value.maxClusterNodes"
        data-test-id="max-nodes-input"
        @input="maxClusterNodesUpdated"
        @update-display-value="e => $emit('update-display-value', { [constants.maxClusterNodes.label]: e})"
      />
    </validation-provider>
  </div>
</template>

<script>
import HpSelect from '@/componentLibrary/select/Select.vue';
import HpInput from '@/componentLibrary/input/Input.vue';
import { ValidationProvider } from 'vee-validate';
import constants from './constants';

export default {
  name: 'SubnetSizeFields',
  components: {
    HpSelect,
    HpInput,
    ValidationProvider,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    subnetSizeOptions: {
      type: Array,
      required: true,
    },
    value: {
      default: () => ({
        subnetSize: null,
        minClusterNodes: null,
        maxClusterNodes: null,
        environment: null,
      }),
      type: Object,
    },
  },
  computed: {
    constants() {
      return constants;
    },
    isDisabled() {
      return !this.value.subnetSize;
    },
    clusterNodesMaxNumber() {
      if (!this.value.subnetSize) return 8;
      const { maxNodes } = this.subnetSizeOptions.find(size => size.value === this.value.subnetSize);
      return maxNodes;
    },
    clusterNodesMinNumber() {
      return this.value.minClusterNodes ? +this.value.minClusterNodes + 1 : 3;
    },
    getMinClustersHelperText() {
      if (!this.value.subnetSize) return this.constants.minClusterNodes.helperText;
      return `Minimum number of cluster workers is between 2 and ${this.clusterNodesMaxNumber - 1} for this subnet size.`;
    },
    getMaxClustersHelperText() {
      if (!this.value.subnetSize) return this.constants.maxClusterNodes.helperText;
      return `Maximum number of cluster workers is between 3 and ${this.clusterNodesMaxNumber} for this subnet size.`;
    },
  },
  methods: {
    async subnetSizesUpdated(val) {
      const model = {
        subnetSize: val,
        minClusterNodes: null,
        maxClusterNodes: null,
      };
      this.$emit('input', model);
    },
    async minClusterNodesUpdated(val) {
      const model = {
        ...this.value,
        minClusterNodes: val,
      };
      this.$emit('input', model);
    },
    async maxClusterNodesUpdated(val) {
      const model = {
        ...this.value,
        maxClusterNodes: val,
      };
      this.$emit('input', model);
    },
  },
};
</script>
