export default {
  subnetSize: {
    helperText: 'Please specify the VM size for the worker nodes.',
    label: 'Subnet Size',
    options: [
      {
        label: 'Small',
        value: 'small',
        maxNodes: {
          devtest: 8,
          uat: 8,
          prod: 16,
        },
      },
      {
        label: 'Medium',
        value: 'medium',
        maxNodes: {
          devtest: 16,
          uat: 16,
          prod: 32,
        },
      },
      {
        label: 'Large',
        value: 'large',
        maxNodes: {
          devtest: 32,
          uat: 32,
          prod: 64,
        },
      },
    ],
  },
  minClusterNodes: {
    helperText: 'Please specify the minimum number of worker nodes required.',
    label: 'Minimum Number of Cluster Worker Nodes',
  },
  maxClusterNodes: {
    helperText: 'Please specify the maximum number of worker nodes required. Note that the maximum available worker nodes is based on the size of the subnet.',
    label: 'Maximum Number of Cluster Worker Nodes',
  },
};
