import { ValidationErrorModel } from '@/modules/core/models/validationErrorModel';
import { write } from '@/modules/core/services/crudService';

export const autoForm = {
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    async sendForm(payload, submitUrl) {
      this.processing = true;

      try {
        const res = await write(payload, submitUrl);
        const requestId = (typeof res === 'object') ?
          ((res && res.data && res.data.data) ? res.data.data.requestId || res.data.data : null) :
          res;
        this.$emit('submitSuccess', requestId);
      } catch (error) {
        if (error instanceof ValidationErrorModel && error.veeValidErrors) {
          error.setFieldMap(this.schema);

          this.$refs.form.setErrors(error.veeValidErrors);
        }

        this.$emit('submitError', error);
      } finally {
        this.processing = false;
      }
    },
    handleError(error) {
      this.$emit('submitError', error);
    },
  },
};
