export default {
  routes: [
    {
      path: '/connectivity/',
      name: 'connectivity',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/connectivity/views/connectivityView/ConnectivityView.vue'
      ),
      children: [
        {
          path: '/connectivity/overview',
          name: 'connectivityOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/connectivity/tabs/connectivityOverview/ConnectivityOverview.vue'
          ),
        },
      ],
    },
  ],
};
