<template>
  <q-badge
    rounded
    floating
    color="primary"
    class="badge"
  >
    {{ data }}
  </q-badge>
</template>
<script>
export default {
  name: 'HpBadge',
  props: {
    data: {
      type: Number,
    },
  },
};
</script>
<style lang="scss" scoped>
.badge {
  margin: 2.5px;
}
</style>
