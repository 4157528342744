<template>
  <div
    :class="{
      'input-disabled': disabled,
      'input-label': !disabled,
      'input-base': true,
      'hp-input-class': true,
      'hp-checkbox': true,
    }"
  >
    <q-checkbox
      :value="value || falseValue"
      :disable="disabled"
      :true-value="trueValue"
      :false-value="falseValue"
      :label="label"
      @input="updateModel"
    >
      <template #default>
        <slot name="label" />
      </template>
    </q-checkbox>

    <div
      v-if="helpertextcontent"
      class="hint-text"
    >
      {{ helpertextcontent }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HpCheckbox',
  props: {
    value: {
      type: [Boolean, Number, String],
    },
    label: {
      type: String,
    },
    helpertextcontent: {
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    falseValue: {
      default: false,
      type: [String, Number, Boolean],
    },
    trueValue: {
      default: true,
      type: [String, Number, Boolean],
    },
  },
  methods: {
    updateModel(val) {
      this.$emit('change', val);
      this.$emit('input', val);

      let label = val;
      if (label === true) label = 'Yes';
      else if (label === false) label = 'No';

      this.$emit('update-display-value', label);
    },
  },
};
</script>

<style scoped lang="scss">
.hp-checkbox {
  position: relative;
  right: .5rem;
  bottom: .5rem;

  .hint-text{
    padding-left: .7rem;
    padding-top: .3rem;
    visibility: hidden;
  }

  &:hover .hint-text{
    visibility: visible;
  }
}

:deep(.q-checkbox) {
  align-items: flex-start;
  .q-checkbox__label {
    margin-top: 8px;
  }
}
</style>
