<template>
  <div>
    <div class="q-mb-1">
      <hp-option-group
        v-if="isProduction"
        :value="hasSchedule"
        :disabled="isChina"
        inline
        label="Do you require a schedule"
        :options="yesNoQuestionOptions"
        required
        type="radio"
        @input="toggleSchedule($event)"
      />
      <div v-else>
        It is recommended to shut down non-production virtual machines when they are not being used in order to save on cloud costs. You will be able to restart the virtual machine or modify its shutdown schedule at any time via the Hosting Portal.
        <hp-checkbox
          class="q-mt-2"
          required
          :disabled="isChina"
          label="I do not wish to configure a shutdown schedule for my virtual machine. I understand that GSK will incur cloud costs when the virtual machine is running outside of business hours."
          :value="hasSchedule"
          @input="toggleSchedule($event)"
        />
      </div>
    </div>
    <div class="q-mb-1_5 text-subtitle1">
      VM Scheduled shutdown
    </div>
    <div class="q-mb-2">
      <validation-provider
        v-slot="{ errors }"
        :rules="{ required: scheduleRequired }"
      >
        <hp-select
          :disabled="isChina || isProduction ? !hasSchedule: hasSchedule"
          :required="scheduleRequired"
          use-chips
          data-test-id="timezone"
          :value="value.schedule.timezone"
          label="Select Time Zone"
          :options="timezones"
          :validationmessage="errors[0]"
          options-dense
          empty-text="No results"
          :valid="!errors.length"
          @input="updateModel('timezone', $event)"
          @update-display-value="updateScheduleTimezoneDisplay"
        />
      </validation-provider>
    </div>
    <div class="q-mb-0_5 text-subtitle1">
      Times shutdown
    </div>
    <div class="q-mb-1 text-body2">
      Select the time of the day when it is safe for the VM to be shut down.
    </div>
    <div class="row time-select-container">
      <validation-provider
        v-slot="{ errors }"
        class="col"
        :rules="{ required: value.schedule && !!value.schedule.timezone && !value.schedule.allDaysShutDown.length }"
      >
        <hp-select
          data-test-id="stop"
          use-chips
          :disabled="isDisabledStopTime"
          label="Every day - Shut Down time"
          :validationmessage="errors[0]"
          :options="stopOptions"
          :required="value.schedule && !!value.schedule.timezone && !value.schedule.allDaysShutDown.length"
          :valid="!errors.length"
          :value="value.schedule.stopTime"
          @input="updateModel('stopTime', $event)"
          @update-display-value="updateScheduleDisplay({ 'Stop Time': $event})"
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        class="col"
        :rules="{ required: !!value.schedule.stopTime }"
      >
        <hp-select
          use-chips
          data-test-id="start"
          label="Every day - Start Time"
          :validationmessage="errors[0]"
          :options="startOptions"
          :disabled="isDisabledStartTime"
          :required="!!value.schedule.stopTime"
          :valid="!errors.length"
          :value="value.schedule.startTime"
          @input="updateModel('startTime', $event)"
          @update-display-value="updateScheduleDisplay({ 'Start Time': $event})"
        />
      </validation-provider>
    </div>

    <div class="q-mb-0_5 text-subtitle1">
      All day shutdown
    </div>
    <div class="q-mb-0_5 text-body2">
      Additionally, you can specify the days the VM does not need to be running at all (e.g. weekends).
    </div>
    <validation-provider
      v-slot="{ errors }"
      :rules="{ required: value.schedule && !!value.schedule.timezone && !value.schedule.stopTime }"
    >
      <chip-selector
        label="Days Shutdown"
        data-test-id="all-day"
        class="all-day-select"
        :disabled="isDisabledAllDaysShutDown"
        :options="chipSelectorOptions"
        :validationmessage="errors[0]"
        :value="value.schedule.allDaysShutDown"
        :required="value.schedule && !!value.schedule.timezone && !value.schedule.stopTime"
        @input="updateAllDayShutdowns"
      />
    </validation-provider>
    <div
      v-if="getFeatureFlagByKey('VM_AUTOMATIC_SKU_DOWNGRADE')"
      class="downgrade-panel q-mt-2 q-pa-1_5"
    >
      <hp-checkbox
        data-test-id="preserve-storage"
        :disabled="!value.schedule.startTime && !value.schedule.allDaysShutDown.length"
        label="I do not wish to downgrade the VM's attached disk storage type (OS and data) to save on costs. Whilst the VM is stopped, the disks will be downgraded to Standard HDD. When the VM is started again, they will revert to their original storage type (Standard SSD or Premium SSD)."
        :value="!value.vmModifyStorage"
        @input="updateVmModifyStorage(!$event)"
        @update-display-value="$emit('update-display-value', {
          ...displayValues,
          'Downgrade disk on shutdown': $event,
        })"
      />
    </div>
  </div>
</template>

<script>
import HpSelect from '@/componentLibrary/select/Select.vue';
import HpCheckbox from '@/componentLibrary/checkbox/Checkbox.vue';
import { getFeatureFlagsStore } from '@/modules/core/mixins/stores/featureFlagsMixin';
import ChipSelector from '@/modules/core/components/chipSelector/ChipSelector.vue';
import { scheduleVmHours } from '@/modules/core/constants/scheduleVmHours';
import { timezonesList } from '@/modules/core/constants/timezonesList';
import { ValidationProvider } from 'vee-validate';
import { yesNoQuestionOptions } from '@/modules/core/constants/optionGroupOptions';
import HpOptionGroup from '@/componentLibrary/optionGroup/OptionGroup.vue';
import '@/modules/core/validators/formValidators';

export default {
  name: 'Schedule',
  components: {
    ChipSelector,
    HpCheckbox,
    HpOptionGroup,
    HpSelect,
    ValidationProvider,
  },
  mixins: [getFeatureFlagsStore],
  props: {
    displayValues: {
      default: () => ({}),
      type: Object,
    },
    isProduction: {
      type: Boolean,
    },
    value: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      hasSchedule: false,
    };
  },
  computed: {
    isChina() {
      return this.disabled;
    },
    scheduleRequired() {
      if (this.isChina) return !this.disabled;
      return this.isProduction ? this.hasSchedule : !this.hasSchedule;
    },
    chipSelectorOptions() {
      return [
        { name: 'monday', label: 'Mon' },
        { name: 'tuesday', label: 'Tue' },
        { name: 'wednesday', label: 'Wed' },
        { name: 'thursday', label: 'Thu' },
        { name: 'friday', label: 'Fri' },
        { name: 'saturday', label: 'Sat' },
        { name: 'sunday', label: 'Sun' },
      ];
    },
    isDisabledStartTime() {
      return !this.value.schedule.startTime && (!this.value.schedule.stopTime || !this.value.schedule.timezone);
    },
    isDisabledStopTime() {
      return !this.value.schedule.timezone;
    },
    isDisabledAllDaysShutDown() {
      return !this.value.schedule.timezone;
    },
    options() {
      return scheduleVmHours;
    },
    startOptions() {
      return this.value.schedule.stopTime ?
        this.options.filter(el => el.value !== this.value.schedule.stopTime) :
        this.options;
    },
    stopOptions() {
      if (this.value.schedule.startTime) {
        const startTimeIndex = this.options.findIndex(el => el.value === this.value.schedule.startTime);
        return [...this.options.slice(startTimeIndex + 1), ...this.options.slice(0, startTimeIndex)];
      }
      return this.options;
    },
    timezones() {
      return timezonesList;
    },
    yesNoQuestionOptions() {
      return yesNoQuestionOptions;
    },
  },
  watch: {
    'value.schedule.stopTime': function (newValue, oldValue) {
      if (!oldValue || newValue) return;

      this.updateScheduleDisplay({
        'Start Time': null,
        'Stop Time': null,
      });

      const model = { ...this.value };
      model.schedule.startTime = null;
      model.schedule.stopTime = null;

      this.$emit('input', model);
    },
  },
  mounted() {
    if (this.disabled) {
      this.clearSchedule();
    }
  },
  created() {
    if (this.disabled) return;

    if (this.isProduction) {
      this.hasSchedule = !!this.value.schedule && !!this.value.schedule.timezone;
    }

    const updateStorage = this.getFeatureFlagByKey('VM_AUTOMATIC_SKU_DOWNGRADE') ? true : null;
    this.updateVmModifyStorage(updateStorage);
    if (updateStorage) {
      this.$emit('update-display-value', {
        ...this.displayValues,
        'Downgrade disk on shutdown': 'Yes',
      });
    }
  },
  methods: {
    toggleSchedule(hasSchedule) {
      this.hasSchedule = hasSchedule;
      if ((this.isProduction && !hasSchedule) || (!this.isProduction && hasSchedule)) {
        this.clearSchedule();
      }
    },
    updateAllDayShutdowns(val) {
      this.updateModel('allDaysShutDown', val);
      const labels = val.map(day => `${day[0].toUpperCase()}${day.slice(1)}`);
      this.updateScheduleDisplay({ 'Days all Shutdown': labels });
    },
    updateScheduleTimezoneDisplay(val) {
      this.$emit('update-display-value', {
        ...this.displayValues,
        'Downgrade disk on shutdown': val ? (this.value.vmModifyStorage ? 'Yes' : 'No') : null,
        schedule: {
          ...this.displayValues.schedule,
          Timezone: val,
        },
      });
    },
    updateScheduleDisplay(update) {
      this.$emit('update-display-value', {
        ...this.displayValues,
        schedule: {
          ...this.displayValues.schedule,
          ...update,
        },
      });
    },
    clearSchedule() {
      this.$emit('input', {
        schedule: {
          startTime: null,
          stopTime: null,
          timezone: null,
          allDaysShutDown: [],
        },
        vmModifyStorage: this.getFeatureFlagByKey('VM_AUTOMATIC_SKU_DOWNGRADE') ? true : null,
      });

      this.$emit('update-display-value', {
        ...this.displayValues,
        'Downgrade disk on shutdown': null,
        schedule: {
          Timezone: null,
          'Stop Time': null,
          'Start Time': null,
          'Days all Shutdown': '',
        },
      });
    },
    updateVmModifyStorage(val) {
      this.$emit('input', {
        vmModifyStorage: val,
        schedule: this.value.schedule,
      });
    },
    updateModel(field, val) {
      const model = { ...this.value };
      model.schedule[field] = val;

      this.$emit('input', model);
    },
  },
};
</script>

<style lang="scss" scoped>
.clear-schedule-container {
  height: 14px;
}
.clear-schedule-button {
  cursor: pointer;
}
.time-select-container {
  gap: 24px;
}

.all-day-select {
  margin-bottom: -16px;
}

.downgrade-panel {
  margin: {
    left: -24px;
    right: -24px;
  }
  background-color: rgba(84, 79, 64, 0.08);
  max-width: 100%;
  box-sizing: content-box;
}
</style>
