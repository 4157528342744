<template>
  <q-icon
    :name="imagePath"
    :class="color"
    :size="size"
  />
</template>

<script>
export default {
  name: 'HpIcon',
  props: {
    color: {
      type: String,
    },
    image: {
      type: String,
    },
    size: {
      type: String,
    },
  },
  computed: {
    imagePath() {
      return `img:${this.image}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .secondary {
    filter: invert(49%) sepia(22%) saturate(4791%) hue-rotate(341deg) brightness(99%) contrast(92%);
  }
  .info {
    filter: invert(44%) sepia(100%) saturate(508%) hue-rotate(142deg) brightness(100%) contrast(98%);
  }
  .negative {
    filter: invert(8%) sepia(88%) saturate(7004%) hue-rotate(339deg) brightness(99%) contrast(114%);
  }
  .primary {
    filter: invert(33%) sepia(98%) saturate(367%) hue-rotate(139deg) brightness(91%) contrast(94%);
  }
</style>
