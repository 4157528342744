import { apiClient } from '@/modules/core/services/api';
import router from '@/modules/core/router';

export default {
  routes: [{
    path: '/iaas/azure-sql',
    name: 'azureSqlServers',
    component: () => import(
      /* webpackMode: "lazy" */
      '@/modules/iaas/views/azureSqlServer/azureSqlServerView.vue'
    ),
    children: [{
      path: '/iaas/azure-sql/overview',
      name: 'azureSqlServerOverview',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/azureSqlServerOverviewTab/azureSqlServerOverviewTab.vue'
      ),
    },
    {
      path: '/iaas/azure-sql/form',
      name: 'azureSqlServerForm',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/azureSqlServerFormTab/azureSqlServerFormTab.vue'
      ),
      meta: {
        requestType: 'AZURE_IAAS_DATABASE_VM',
        requestName: 'Azure SQL Server',
      },
    },
    {
      path: '/iaas/azure-sql/dashboard',
      name: 'azureSqlServerDashboard',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/azureSqlServerDashboardTab/azureSqlServerDashboardTab.vue'
      ),
    }, {
      path: '/iaas/azure-sql/faq',
      name: 'azureSqlServerFaq',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
      ),
    },
    ],
  },
  {
    path: '/iaas/azure-postgresql',
    name: 'iaasAzurePostgresql',
    component: () => import(
      /* webpackMode: "lazy" */
      '@/modules/iaas/views/iaasAzurePostgreSql/IaasAzurePostgreSql.vue'
    ),
    children: [
      {
        path: '/iaas/azure-postgresql/overview',
        name: 'iaasAzurePostgreSqlOverview',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/iaas/tabs/iaasAzurePostgreSqlOverviewTab/IaasAzurePostgreSqlOverviewTab.vue'
        ),
      },
      {
        path: '/iaas/azure-postgresql/form',
        name: 'iaasAzurePostgreSqlDatabaseForm',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/iaas/tabs/iaasPostgreSqlFormTab/IaasPostgreSqlFormTab.vue'
        ),
        meta: {
          requestType: 'AZURE_IAAS_POSTGRESQL',
          requestName: 'Azure PostgreSQL Server',
        },
      },
      {
        path: '/iaas/azure-postgresql/faq',
        name: 'iaasAzurePostgreSqlFaq',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
        ),
      },
      {
        path: '/iaas/azure-postgresql/dashboard',
        name: 'iaasAzurePostgreSqlDashboard',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/iaas/tabs/iaasAzurePostgreSqlDashboardTab/IaasAzurePostgreSqlDashboardTab.vue'
        ),
      },
    ],
  },
  {
    path: '/iaas/azure-oracle',
    name: 'azureOracleServer',
    component: () => import(
      /* webpackMode: "lazy" */
      '@/modules/iaas/views/azureOracleServer/AzureOracleServerView.vue'
    ),
    async beforeEnter(to, from, next) {
      try {
        if (process.env.NODE_ENV !== 'production') next();
        await apiClient.get('/azure/iaas/databases/oracle/validate-user-access');
        if (from.path === router.currentRoute.path) next();
      } catch (e) {
        if (from.path === router.currentRoute.path) next(new Error(e.response.status));
      }
    },
    children: [
      {
        path: '/iaas/azure-oracle/overview',
        name: 'azureOracleServerOverview',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/iaas/tabs/azureOracleServerOverviewTab/AzureOracleServerOverviewTab.vue'
        ),
      },
      {
        path: '/iaas/azure-oracle/form',
        name: 'azureOracleServerForm',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/iaas/tabs/azureOracleServerFormTab/AzureOracleServerFormTab.vue'
        ),
        meta: {
          requestType: 'AZURE_IAAS_ORACLE',
          requestName: 'Azure Oracle Server',
        },
      },
    ],
  },
  {
    path: '/iaas/linux-virtual-machines',
    name: 'linuxVirtualMachines',
    component: () => import(
      /* webpackMode: "lazy" */
      '@/modules/iaas/views/linuxVirtualMachine/LinuxVirtualMachineView.vue'
    ),
    children: [{
      path: '/iaas/linux-virtual-machines/overview',
      name: 'linuxVirtualMachinesOverview',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/linuxVirtualMachineOverviewTab/LinuxVirtualMachineOverviewTab.vue'
      ),
    },
    {
      path: '/iaas/linux-virtual-machines/form',
      name: 'linuxVirtualMachinesForm',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/linuxVirtualMachinesFormTab/LinuxVirtualMachinesFormTab.vue'
      ),
      meta: {
        requestType: 'AZURE_IAAS_LINUX_VM',
        requestName: 'Linux Virtual Machine',
      },
    },
    {
      path: '/iaas/linux-virtual-machines/dashboard',
      name: 'linuxVirtualMachinesDashboard',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/linuxVirtualMachineDashboardTab/LinuxVirtualMachineDashboardTab.vue'
      ),
    },
    {
      path: '/iaas/linux-virtual-machines/faq',
      name: 'linuxVirtualMachinesFaq',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
      ),
    },
    ],
  },
  {
    path: '/iaas/linux-scale-set',
    name: 'linuxScaleSet',
    component: () => import(
      /* webpackMode: "lazy" */
      '@/modules/iaas/views/linuxScaleSet/LinuxScaleSetView.vue'
    ),
    children: [
      {
        path: '/iaas/linux-scale-set/overview',
        name: 'linuxScaleSetOverview',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/iaas/tabs/linuxScaleSetOverviewTab/LinuxScaleSetOverviewTab.vue'
        ),
      },
      {
        path: '/iaas/linux-scale-set/form',
        name: 'linuxScaleSetForm',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/iaas/tabs/linuxScaleSetFormTab/LinuxScaleSetFormTab.vue'
        ),
        meta: {
          requestType: 'LINUX_SCALE_SET',
          requestName: 'Linux Scale Set',
        },
      },
    ],
  },
  {
    path: '/iaas/windows-virtual-machines',
    name: 'windowsVirtualMachines',
    component: () => import(
      /* webpackMode: "lazy" */
      '@/modules/iaas/views/windowsVirtualMachine/WindowsVirtualMachineView.vue'
    ),
    children: [{
      path: '/iaas/windows-virtual-machines/overview',
      name: 'windowsVirtualMachinesOverview',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/windowsVirtualMachineOverviewTab/WindowsVirtualMachineOverviewTab.vue'
      ),
    },
    {
      path: '/iaas/windows-virtual-machines/form',
      name: 'windowsVirtualMachinesForm',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/windowsVirtualMachinesFormTab/WindowsVirtualMachinesFormTab.vue'
      ),
      meta: {
        requestType: 'AZURE_IAAS_WINDOWS_VM',
        requestName: 'Windows Virtual Machine',
      },
    },
    {
      path: '/iaas/windows-virtual-machines/dashboard',
      name: 'windowsVirtualMachinesDashboard',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/windowsVirtualMachineDashboardTab/WindowsVirtualMachineDashboardTab.vue'
      ),
    },
    {
      path: '/iaas/windows-virtual-machines/faq',
      name: 'windowsVirtualMachinesFaq',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
      ),
    },
    ],
  },
  {
    path: '/iaas/gcp-virtual-machines',
    name: 'gcpVirtualMachines',
    component: () => import(
      /* webpackMode: "lazy" */
      '@/modules/iaas/views/gcpVirtualMachine/GCPVirtualMachineView.vue'
    ),
    children: [{
      path: '/iaas/gcp-virtual-machines/overview',
      name: 'gcpVirtualMachinesOverview',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/gcpVirtualMachineOverviewTab/GCPVirtualMachineOverviewTab.vue'
      ),
    },
    ],
  }, {
    path: '/iaas/on-premise',
    name: 'onPremise',
    component: () => import(
      /* webpackMode: "lazy" */
      '@/modules/iaas/views/onPremise/OnPremiseView.vue'
    ),
    children: [{
      path: '/iaas/on-premise/overview',
      name: 'onPremiseOverview',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/onPremiseOverviewTab/OnPremiseOverviewTab.vue'
      ),
    }, {
      path: '/iaas/on-premise/form',
      name: 'onPremiseForm',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/onPremiseFormTab/OnPremiseFormTab.vue'
      ),
    }, {
      path: '/iaas/on-premise/faq',
      name: 'onPremiseFaq',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/iaas/tabs/onPremiseFaqTab/OnPremiseFaqTab.vue'
      ),
    },
    ],
  },
  ],
};
