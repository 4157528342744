import { ValidationErrorModel } from '@/modules/core/models/validationErrorModel';
import { dirtyFormEmitter } from '../dirtyFormEmiter/dirtyFormEmiter';
import { formSetup } from '../formSetup';
import * as crudService from '../../services/crudService';
import { isEqual } from 'lodash';
import { tooltipBusinessUnitRD } from '@/modules/core/constants/orgStructure';

export const multiForm = {
  mixins: [formSetup, dirtyFormEmitter],
  data() {
    return {
      stepResponses: {},
      processing: false,
      step: 0,
      displayValues: {},
      pressed: false,
    };
  },
  watch: {
    step() {
      // reset the validation box at top of screen.
      this.$emit('submitError', null);
    },
  },
  computed: {
    getModelSnapshot() {
      return {
        model: this.model,
        stepResponses: this.stepResponses,
        step: this.step,
        displayValues: this.displayValues,
      };
    },
    getTooltipBusinessUnitRD() {
      return tooltipBusinessUnitRD;
    },
  },
  methods: {
    nextStep() {
      const stepUrl = this.schema[this.step].submitUrl;
      if (stepUrl) return this.sendForm({ ...this.model }, stepUrl);
      this.step++;
    },
    previousStep() {
      for (let step = this.step - 1; step >= 0; step--) {
        if (!this.schema[step].disable || !this.schema[step].disable(this.model)) {
          this.step = step;
          return;
        }
      }
    },
    openDrawer() {
      this.pressed = true;
    },
    closeDrawer() {
      this.pressed = false;
    },
    isDraftCompatibleWithCurrentForm(draftModel) {
      const modelKeys = Object.keys(this.model).sort();
      const draftKeys = Object.keys(draftModel).sort();

      return modelKeys.length === draftKeys.length && isEqual(modelKeys, draftKeys);
    },
    async applyDraft() {
      const routeParams = this.$route.params;
      const draft = routeParams.selectedRow;
      if (draft) {
        const compatible = this.isDraftCompatibleWithCurrentForm(draft.model);
        if (compatible) {
          this.model = { ...draft.model };

          this.stepResponses = draft.stepResponses;
          this.displayValues = draft.displayValues;
          this.step = draft.step;
        } else {
          this.$router.push({
            name: 'my-drafts',
            params: {
              draftId: draft.id,
            },
          });
        }
      }
    },
    async submitForm() {
      await this.sendForm({ ...this.model }, this.schema[this.schema.length - 1].submitUrl);
    },
    async sendForm(payload, submitUrl) {
      delete payload.userAgreements;
      const transformedPayload = this.transformPayload ? this.transformPayload(payload) : payload;
      this.processing = true;
      try {
        const { data: { data } } = await crudService.write(transformedPayload, submitUrl);

        if (this.step === this.schema.length - 1) {
          this.$emit('submitSuccess', data.requestId || data);
        } else {
          this.$set(this.stepResponses, this.step, data);
          this.step++;
        }
      } catch (error) {
        if (error instanceof ValidationErrorModel && error.veeValidErrors) {
          if (this.schema.length - 1 === this.step) {
            const errorStep = this.schema.findIndex(s => s.fields[Object.keys(error.veeValidErrors)[0].split('.')[0]]);
            this.step = errorStep;
          }
          await this.$nextTick();
          error.setFieldMap(this.schema[this.step].fields);
          this.$refs.step[this.step].setErrors(error.veeValidErrors);
        }
        this.$emit('submitError', error);
      } finally {
        this.processing = false;
      }
    },
    handleError(error) {
      this.$emit('submitError', error);
    },
    updateDisplay(newValue) {
      this.displayValues = {
        ...this.displayValues,
        ...newValue,
      };
    },
  },
};
