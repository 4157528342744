<template>
  <hp-alert
    type="error"
    heading="Oops something went wrong"
  >
    <span v-if="!errorMessage">
      An error occurred whilst submitting your request. Our technical staff have been automatically
      notified and will be looking into this with the utmost urgency. Please try again soon or
      alternatively raise a ticket in ServiceNow quoting <strong>HostingPortal-L3</strong> as the support group.
    </span>
    <span v-else>
      {{ errorMessage }}
    </span>
  </hp-alert>
</template>
<script>
import HpAlert from '@/componentLibrary/alert/Alert.vue';

export default {
  name: 'FormSystemError',
  components: {
    HpAlert,
  },
  props: {
    error: {
      type: [Object, String, Error],
    },
  },
  computed: {
    errorMessage() {
      return this.error && typeof this.error.message === 'string' && this.error.message;
    },
  },
};
</script>
