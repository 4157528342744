/*
  requires use of:
    mixin - dirtyFormEmitter which checks for changes on the base/presentational component
      import { dirtyFormEmitter } from '@/modules/core/mixins';

        mixins: [dirtyFormEmitter],

    mixin - dirtyFormCatcherAndRouteGuard which caches the emit, handles dialog and navigation

      import { dirtyFormCatcherAndRouteGuard } from '@/modules/core/mixins';
      mixins: [dirtyFormCatcherAndRouteGuard],

      requires the base component emit is caught on the template

        @dirty="formDirty" both sides handled by the mixins

    component - dirtyDialog

      <dirty-dialog
        :open="openDirtyDialog"
        @dirtyNavigate="dirtyNavigate"
      />

      imports and all other code handled by mixins
*/

export const dirtyFormEmitter = {
  computed: {
    original() {
      return JSON.stringify(this.$options.data.call(this).model);
    },
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        const dirty = this.original !== JSON.stringify(val);
        this.$emit('dirty', dirty);
      },
    },
  },
};
