import { apiClient } from '@/modules/core/services/api';

const formatQueryString = queries => {
  if (!Object.values(queries).some(query => query || query === 0)) return '';

  const params = {};
  Object.entries(queries).forEach(([query, queryValue]) => {
    if (queryValue || queryValue === 0) params[query] = queryValue;
  });
  return `?${new URLSearchParams(params).toString()}`;
};

const get = url => apiClient.get(url).then(({ data }) => data.data);

const getLinesOfBusiness = (requestType, tenant, techEnvironment) => get(`/lines-of-business${formatQueryString({ requestType, tenant, techEnvironment })}`);

const getBusinessUnits = (lineOfBusiness, requestType, tenant) => get(`/lines-of-business/${lineOfBusiness}/business-units${formatQueryString({ requestType, tenant })}`);

const getBusinessFunctions = (lineOfBusiness, businessUnit, requestType, tenant) => get(`/lines-of-business/${lineOfBusiness}/business-units/${businessUnit}/business-functions${formatQueryString({ requestType, tenant })}`);

const getRequestTypes = () => get('/request-types');

export default {
  getBusinessFunctions,
  getBusinessUnits,
  getLinesOfBusiness,
  getRequestTypes,
};
