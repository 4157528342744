/*
  requires use of:
    mixin - dirtyFormEmitter which checks for changes on the base/presentational component
      import { dirtyFormEmitter } from '@/modules/core/mixins';

        mixins: [dirtyFormEmitter],

    mixin - dirtyFormCatcherAndRouteGuard which caches the emit, handles dialog and navigation

      import { dirtyFormCatcherAndRouteGuard } from '@/modules/core/mixins';
      mixins: [dirtyFormCatcherAndRouteGuard],

      requires the base component emit is caught on the template

        @dirty="formDirty" both sides handled by the mixins

    component - dirtyDialog

      <dirty-dialog
        :open="openDirtyDialog"
        @dirtyNavigate="dirtyNavigate"
      />

      imports and all other code handled by mixins
*/

import DirtyDialog from '@/componentLibrary/dirtyDialog/DirtyDialog.vue';
import { SystemErrorModel } from '../../models/systemErrorModel';

export const dirtyFormCatcherAndRouteGuard = {
  components: {
    DirtyDialog,
  },
  data() {
    return {
      dirty: false,
      navNext: null,
      openDirtyDialog: false,
    };
  },
  methods: {
    formDirty(dirty) {
      this.dirty = dirty;
    },
    dirtyNavigate(canNavigate) {
      this.openDirtyDialog = false;
      if (canNavigate) this.navNext();
    },
    beforeRouteLeaveRequest(next) {
      // allow a naviagate when the form is successfully submitted or irretrievable error
      if (
        this.success ||
        this.successMessage ||
        (this.error && this.error instanceof SystemErrorModel)
      ) {
        next();
      } else if (this.dirty) {
        this.openDirtyDialog = true;
        this.navNext = next;
      } else {
        next();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.beforeRouteLeaveRequest(next);
  },
};
