<template>
  <div class="list-component-container">
    <div class="input-base hp-input-class">
      <hp-input
        v-model="search"
        :placeholder="placeholder"
        btn="search"
      />
    </div>
    <div class="checkbox-container">
      <div class="scroll-bar-fixed q-ml-1 q-my-1">
        <div
          v-if="!filterListItems.length"
          class="loading-container"
        >
          <q-skeleton
            v-for="n in 12"
            :key="n"
            animation="blink"
            class="q-ma-1_5"
          />
        </div>
        <hp-option-group
          v-else
          :value="value"
          :options="filterListItems"
          type="checkbox"
          @input="$emit('input', $event);"
          @update-display-value="$emit('update-display-value', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HpInput from '@/componentLibrary/input/Input';
import HpOptionGroup from '@/componentLibrary/optionGroup/OptionGroup';
import { read } from '@/modules/core/services/crudService';

export default {
  name: 'HpList',
  components: {
    HpInput,
    HpOptionGroup,
  },
  props: {
    optionsUrl: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: Array,
      required: true,
    },
    transformOptions: {
      type: Function,
    },
  },
  data() {
    return {
      listItems: [],
      search: '',
    };
  },
  computed: {
    filterListItems() {
      const search = this.search.toLowerCase();
      return this.listItems.filter(item => item.label.toLowerCase().includes(search));
    },
  },
  async created() {
    try {
      const options = await read(this.optionsUrl);
      this.listItems = this.transformOptions ? this.transformOptions(options) : options;
      const initialValues = this.listItems.filter(({ selected }) => selected).map(({ value }) => value);
      this.$emit('input', initialValues);
      this.$emit('update-display-value', initialValues);
    } catch (e) {
      this.$emit('componentFailed', e);
    }
  },
};

</script>
<style lang="scss" scoped>
.list-component-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 15% 85%;
  height: 580px;
  width: 840px;
}
:deep(.hp-input-class.input-base .q-checkbox) {
    .q-checkbox__label{
      color: $color-typography;
      font-size: 14px;
    }
    &.disabled .q-checkbox__bg {
      color: $color-surface-dark;
    }
  }
.checkbox-container {
  display: flex;
  background-color: $color-white;
  border-radius: 3px;
  > * {
    flex: 1 100%;
    overflow-y: scroll;
  }
}
.scroll-bar-fixed {
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-white;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-surface-medium;
    border-radius: 3px;
  }
}
</style>
