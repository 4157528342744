<template>
  <div>
    <p
      class="cursor-pointer q-mt-1 subtitle1 list-item-weight text-primary q-mr-2"
      @click="openDrawer()"
    >
      {{ context && context.name || 'Classic View' }}
      <q-icon
        :name="`expand_${pressed ? 'less' : 'more'}`"
        size="1rem"
      />
    </p>
    <hp-drawer
      v-if="pressed"
      :cancel-label="showWarning ? 'CANCEL' : 'CLOSE'"
      :accept-label="showWarning ? 'CONTINUE' : null"
      @cancel="closeDrawer"
      @accept="setProject(projectPendingContextSwitch)"
    >
      <p class="text-weight-bold">
        Select context
      </p>
      <p class="text-body2">
        Being in a project context will speed up form submissions as common form field values will then be inherited from the project instead.
      </p>
      <p class="text-body2">
        You can still use the Hosting Portal as you did previously in case you do not want to use the project context, this is now known as the
        <span class="list-item-weight">
          Classic View
        </span>
        .
      </p>
      <div class="row q-mt-2 items-center">
        <div class="col">
          <p class="text-caption q-mb-0">
            MY PROJECTS
          </p>
        </div>
        <hp-button
          leading-icon="add"
          flat
          label="Create project"
          @click="navigateNewProjectForm()"
        />
      </div>
      <ul class="q-px-0">
        <li
          v-for="(project, key) in projects"
          :key="key"
        >
          <div
            class="cursor-pointer row"
            @click="selectContext(project)"
          >
            <div class="q-gutter-md q-ml-none">
              <q-btn
                round
                unelevated
                :outline="context && context.name === project.name"
                :icon="context && context.name === project.name ? 'done' : ''"
                :color="project.color"
                size="0.5rem"
                class="q-mr-1"
              />
            </div>
            <p :class="`list-item-weight ${context && context.name === project.name ? 'text-primary' : 'subtitle1'}`">
              {{ project.name }}
            </p>
          </div>
        </li>
      </ul>
      <q-separator class="q-mb-1" />
      <p
        class="cursor-pointer q-mb-3"
        :class="`list-item-weight ${context ? 'subtitle1' : 'text-primary'}`"
        @click="selectContext(null)"
      >
        <q-btn
          round
          flat
          :icon="context ? '' : 'done'"
          size="0.5rem"
          class="q-mr-1"
        />
        Classic View
      </p>
      <hp-alert
        v-if="showWarning"
        :text="`Switching over to ${projectPendingContextSwitch ? projectPendingContextSwitch.name : 'Classic View'} while completing a Request Form in ${context ? context.name : 'Classic View'}, will automatically reset the form.`"
      />
    </hp-drawer>
  </div>
</template>

<script>
import HpButton from '@/componentLibrary/button/Button.vue';
import HpAlert from '@/componentLibrary/alert/Alert.vue';
import HpDrawer from '@/componentLibrary/drawer/Drawer.vue';
import { withProjectContextStore } from '@/modules/core/mixins';

export default {
  name: 'ProjectContextSwitch',
  components: { HpButton, HpDrawer, HpAlert },
  mixins: [withProjectContextStore],
  data() {
    return {
      pressed: false,
      showWarning: false,
      projectPendingContextSwitch: null,
    };
  },
  computed: {
    isFormPage() {
      const { name } = this.$route;
      return name.toLowerCase().includes('form') &&
        !['myProjectsForm', 'azureAppServiceInternalForm', 'cloudVolumeOntapForm'].includes(name);
    },
  },
  created() {
    this.initProjects();
  },
  methods: {
    openDrawer() {
      this.showWarning = false;
      this.projectPendingContextSwitch = null;
      this.pressed = true;
    },
    closeDrawer() {
      this.pressed = false;
    },
    selectContext(project) {
      if (!this.isFormPage) return this.setProject(project);
      this.showWarning = true;
      this.projectPendingContextSwitch = project;
      this.$nextTick().then(() => {
        this.$el.querySelector('.alert').scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    },
    setProject(project) {
      this.setContext(project);
      this.showWarning = false;
      this.projectPendingContextSwitch = null;
      this.closeDrawer();
    },
    navigateNewProjectForm() {
      this.pressed = false;
      this.$router.push({ name: 'myProjectsForm' });
    },
  },
};
</script>
<style scoped lang="scss">
.list-item-weight {
  font-weight: 500;
}
</style>
