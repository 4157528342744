import { getTimeZones } from '@vvo/tzdb';

const timezoneNames = [
  'Pacific/Pago_Pago',
  'Pacific/Honolulu',
  'Pacific/Tahiti',
  'Pacific/Gambier',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Halifax',
  'America/Argentina/Buenos_Aires',
  'Atlantic/South_Georgia',
  'Atlantic/Cape_Verde',
  'Atlantic/Azores',
  'Europe/London',
  'Europe/Berlin',
  'Europe/Helsinki',
  'Europe/Istanbul',
  'Asia/Dubai',
  'Indian/Maldives',
  'Asia/Dhaka',
  'Asia/Bangkok',
  'Asia/Hong_Kong',
  'Asia/Tokyo',
  'Australia/Sydney',
  'Pacific/Norfolk',
  'Pacific/Nauru',
  'Pacific/Auckland',
  'Pacific/Apia',
  'Pacific/Kiritimati',
];

const timezonesMap = getTimeZones().reduce((map, tz) => {
  map[tz.name] = tz;
  return map;
}, {});

export const timezonesList = timezoneNames.map(name => {
  const tz = timezonesMap[name];
  return {
    label: tz.currentTimeFormat,
    value: tz.name,
  };
});
