<template>
  <validation-observer
    v-if="step"
    ref="formStep1"
    v-slot="{ handleSubmit }"
  >
    <hp-dialog
      close-button
      title="Rate your experience"
      value
      accept-label="Submit"
      cancel-label="Back"
      @cancel="() => step--"
      @close="$emit('cancel')"
      @accept="handleSubmit(submitForm)"
    >
      <div class="feedback-form">
        <grid>
          <grid-cell
            span="12"
            class="form-container"
          >
            <hp-rating
              v-model="model.usabilityRating"
              name="usabilityRating"
              required
              label="Ease of use"
            />

            <hp-rating
              v-model="model.informationRating"
              name="informationRating"
              required
              label="Quality of information"
            />

            <hp-rating
              v-model="model.performanceRating"
              name="performanceRating"
              required
              label="Overall website performance"
            />

            <validation-provider
              v-slot="{ errors }"
              mode="eager"
              name="comments"
              :rules="{ required: model.overallFeedback !== 'positive'}"
            >
              <hp-input
                v-model="model.comments"
                :required="model.overallFeedback !== 'positive'"
                label="Additional comments"
                :validationmessage="errors[0]"
                type="textarea"
                maxlength="200"
                counter
              />
            </validation-provider>
          </grid-cell>
        </grid>
      </div>
    </hp-dialog>
  </validation-observer>

  <hp-dialog
    v-else
    close-button
    hide-actions
    title="How was your experience"
    value
    @cancel="$emit('cancel')"
    @close="$emit('cancel')"
    @accept="handleSubmit(nextStep)"
  >
    <div class="feedback-form q-mb-2 q-mx-1">
      <!-- this is what vee validate is watching -->
      <input
        v-model="model.overallFeedback"
        type="hidden"
      >

      <div class="row justify-around q-mt-1">
        <q-btn
          flat
          dense
          round
          :icon="happyImg"
          :size="$q.screen.lt.sm? '2rem': '3.5rem'"
          @click="select('positive')"
        />
        <q-btn
          flat
          dense
          round
          :icon="neutralImg"
          :size="$q.screen.lt.sm? '2rem': '3.5rem'"
          @click="select('neutral')"
        />
        <q-btn
          flat
          dense
          round
          :icon="sadImg"
          :size="$q.screen.lt.sm? '2rem': '3.5rem'"
          @click="select('negative')"
        />
      </div>
    </div>
  </hp-dialog>
</template>

<script>
import { formSetup } from '@/modules/core/mixins';
import '@/modules/core/validators/formValidators';

export default {
  name: 'HpFeedback',
  mixins: [formSetup],
  data() {
    return {
      model: {
        overallFeedback: null,
        usabilityRating: 0,
        informationRating: 0,
        performanceRating: 0,
        comments: null,
      },
      step: 0,
    };
  },
  computed: {
    sadImg() {
      return this.model.overallFeedback === 'negative' ? 'img:/img/smilies/smiley-sad-1-filled.svg' : 'img:/img/smilies/smiley-sad-1.svg';
    },
    neutralImg() {
      return this.model.overallFeedback === 'neutral' ? 'img:/img/smilies/smiley-indifferent-filled.svg' : 'img:/img/smilies/smiley-indifferent.svg';
    },
    happyImg() {
      return this.model.overallFeedback === 'positive' ? 'img:/img/smilies/smiley-smile-2-filled.svg' : 'img:/img/smilies/smiley-smile-2.svg';
    },
  },
  methods: {
    select(overallFeedback) {
      this.model.overallFeedback = overallFeedback;
      this.step++;
    },
    submitForm() {
      this.$emit('submit', this.model);
    },
  },
};
</script>
<style scoped>
  .feedback-form {
    font-size: 12px;
  }
</style>
