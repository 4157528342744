export default {
  breadcrumbParent: 'home',
  breadcrumbTitle: 'helpSupport',
  graphic: 'question-circle',
  id: 'helpSupport',
  menuTitle: 'Help & Support',
  name: 'HelpSupport',
  path: '/help-support',
  desc: '',
  routeName: 'helpSupport',
  services: [
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'General Help',
      graphic: 'container',
      id: 'generalHelp',
      menuTitle: 'General',
      name: 'General Help',
      path: '/help-support/general-help',
      routeName: 'generalHelp',
    },
  ],
};
