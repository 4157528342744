<template>
  <q-banner
    v-if="showBanner && branchName && tagName"
    inline-actions
    dense
    class="no-padding banner"
  >
    <div class="text-center">
      <span>Tag:</span> {{ tagName }}
      <span>from Branch:</span> {{ branchName }}
    </div>

    <template #action>
      <q-btn
        flat
        label="×"
        dense
        class="q-mr-1"
        @click="showBanner = false"
      />
    </template>
  </q-banner>
</template>
<script>
export default {
  name: 'HpBranchTagBanner',
  data() {
    return {
      showBanner: false,
    };
  },
  computed: {
    branchName() {
      return process.env.VUE_APP_BRANCH_NAME || '';
    },
    tagName() {
      return process.env.VUE_APP_TAG_NAME || '';
    },
  },
  created() {
    this.showBanner = process.env.VUE_APP_API_ENV && process.env.VUE_APP_API_ENV === 'development';
  },
};
</script>
<style lang="scss" scoped>
  .banner {
    background-color: rgb(228, 237, 238);
    font-size: 12px;

    span {
      font-weight: 500;
    }
  }
</style>
