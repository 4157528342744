<template>
  <div v-if="!isHidden">
    <slot :name="`pre-${name}`" />

    <slot :name="name">
      <hp-summary
        v-if="schema.component === 'summary'"
        :fields="schema.fields"
        :display-values="displayValues"
        :step-responses="stepResponses"
      />

      <org-selector
        v-if="schema.component === 'orgSelector'"
        :request-type="schema.requestType"
        :tenant="schema.tenant"
        :value="orgSelectorModel"
        :tech-environment="techEnvironment"
        :disabled="isDisabled"
        @input="emitObject"
        @update-display-value="$emit('update-display-value', $event)"
      >
        <template #businessUnits-helpertextcontent>
          <slot :name="`${name}-helpertextcontent`" />
        </template>
      </org-selector>

      <owner-selector
        v-else-if="schema.component === 'ownerSelector'"
        :disabled="isDisabled"
        :value="owners"
        @input="emitOwners"
        @update-display-value="$emit('update-display-value', $event)"
      />

      <primary-secondary-selector
        v-else-if="schema.component === 'primarySecondarySelector'"
        :disabled="isDisabled"
        :value="primarySecondary"
        :helpertextcontent="schema.helpertextcontent"
        @input="emitPrimarySecondary"
        @update-display-value="$emit('update-display-value', $event)"
      />

      <schedule
        v-else-if="schema.component === 'schedule'"
        :disabled="isDisabled"
        :value="scheduleModel"
        :display-values="displayValues"
        :is-production="getEnvironmentFromStepResponse && ['prod', 'production'].includes(getEnvironmentFromStepResponse.toLowerCase())"
        @input="emitObject"
        @update-display-value="$emit('update-display-value', $event)"
      />

      <subnet-size-fields
        v-if="schema.component === 'subnetSizeFields'"
        :disabled="isDisabled"
        :value="subnetSizeFieldsModel"
        :subnet-size-options="schema.getSubnetSizeOptions(value)"
        @input="emitObject"
        @update-display-value="$emit('update-display-value', $event)"
      />

      <user-agreements
        v-if="schema.component === 'userAgreements'"
        :key="projectContext && projectContext.slug"
        :skip-preamble="schema.skipPreamble"
        :overview-link="schema.overviewLink"
        :user-agreements="schema.userAgreements"
        :value="value[name]"
        @input="emitValue"
      />

      <validation-provider
        v-else
        v-slot="{ errors }"
        mode="eager"
        :vid="name"
        :name="schema.label"
        :rules="rules"
        :custom-messages="customErrorMessages"
      >
        <hp-input
          v-if="isInput"
          :type="schema.type || 'text'"
          :value="value[name]"
          :inner-prefix="schema.innerPrefix"
          :inner-suffix="schema.innerSuffix"
          :label="schema.label"
          :helpertextcontent="!!$slots[`${name}-helpertextcontent`]? null: helpText"
          :validationmessage="errors[0]"
          :disabled="isDisabled"
          :required="required"
          :maxlength="schema.maxlength"
          @input="emitValue"
          @update-display-value="$emit('update-display-value', { [schema.label]: $event })"
        >
          <template #helpertextcontent>
            <slot :name="`${name}-helpertextcontent`" />
          </template>
        </hp-input>

        <hp-vm-size-select
          v-if="schema.component === 'vmSizeSelect'"
          :disabled="isDisabled"
          :options="fieldOptions"
          :options-url="fieldOptionsUrl"
          :value="value[name]"
          :validationmessage="errors[0]"
          @input="emitValue"
          @error="$emit('error', $event)"
          @update-display-value="$emit('update-display-value', { [schema.label]: $event })"
        />

        <hp-checkboxes
          v-if="schema.component === 'checkboxes'"
          :disabled="isDisabled"
          :label="schema.label"
          :options="fieldOptions"
          :options-url="fieldOptionsUrl"
          :value="value[name]"
          :validationmessage="errors[0]"
          :required="required"
          @input="emitValue"
          @error="$emit('error', $event)"
          @update-display-value="$emit('update-display-value', { [schema.label]: $event })"
        >
          <template #helpertextcontent>
            <slot :name="`${name}-helpertextcontent`" />
          </template>

          <template #checkboxhelpertextcontent-0>
            <slot :name="`${name}-checkboxhelpertextcontent-0`" />
          </template>

          <template #checkboxhelpertextcontent-1>
            <slot :name="`${name}-checkboxhelpertextcontent-1`" />
          </template>

          <template #checkboxhelpertextcontent-2>
            <slot :name="`${name}-checkboxhelpertextcontent-2`" />
          </template>
        </hp-checkboxes>

        <hp-select
          v-if="isSelect"
          :disabled="isDisabled"
          :options="fieldOptions"
          :options-url="fieldOptionsUrl"
          :required="required"
          :helpertextcontent="helpText"
          :value="value[name]"
          :label="schema.label"
          :useinput="schema.useInput"
          :validationmessage="errors[0]"
          @input="emitValue"
          @error="$emit('error', $event)"
          @update-display-value="$emit('update-display-value', { [schema.label]: $event })"
        >
          <template #helpertextcontent>
            <slot :name="`${name}-helpertextcontent`" />
          </template>
        </hp-select>

        <hp-cascade
          v-if="isCascade"
          :disabled="isDisabled"
          :name="name"
          :required="required"
          :schema="schema"
          :cascade-value="value[schema.cascadeFrom]"
          :value="value[name]"
          :validationmessage="errors[0]"
          @input="emitValue"
          @error="$emit('error', $event)"
          @update-display-value="$emit('update-display-value', { [schema.label]: $event })"
        />

        <hp-option-group
          v-if="['radio', 'checkbox'].includes(schema.component)"
          :disabled="isDisabled"
          :inline="!schema.block"
          :label="schema.label"
          :options="fieldOptions"
          :required="required"
          :type="schema.component"
          :validationmessage="errors[0]"
          :value="value[name]"
          :helpertextcontent="helpText"
          :data-test-id="name"
          @input="emitValue"
          @update-display-value="$emit('update-display-value', { [schema.label]: $event })"
        >
          <template #helpertextcontent>
            <slot :name="`${name}-helpertextcontent`" />
          </template>
        </hp-option-group>

        <hp-file-upload
          v-if="schema.component === 'fileUpload'"
          :disabled="isDisabled"
          :required="required"
          :accept="schema.accept"
          :helpertextcontent="helpText"
          :value="value[name]"
          :name="name"
          :data-test-id="name"
          :label="schema.label"
          :validationmessage="errors[0]"
          @input="emitValue"
        />

        <hp-list
          v-if="schema.component === 'list'"
          :value="value[name]"
          :options-url="schema.optionsUrl"
          :placeholder="schema.placeholder"
          :label="schema.label"
          :transform-options="schema.transformOptions"
          @input="emitValue"
          @update-display-value="$emit('update-display-value', { [schema.label]: $event })"
        />

        <hp-people-picker
          v-if="schema.component === 'peoplePicker'"
          :disabled="isDisabled"
          :label="schema.label"
          :helpertextcontent="helpText"
          :required="required"
          :validationmessage="errors[0]"
          :value="person"
          :valid="!errors.length"
          @input="emitPerson"
          @error="$emit('error', $event)"
          @update-display-value="$emit('update-display-value', { [schema.label]: $event })"
        >
          <template #helpertextcontent>
            <slot :name="`${name}-helpertextcontent`" />
          </template>
        </hp-people-picker>
      </validation-provider>
    </slot>

    <slot :name="`post-${name}`" />
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import HpCascade from '@/modules/core/components/cascade/Cascade.vue';
import HpList from '@/modules/core/components/list/List.vue';
import HpPeoplePicker from '@/modules/core/components/peoplePicker/PeoplePicker';
import OwnerSelector from '@/modules/core/components/ownerSelector/OwnerSelector.vue';
import OrgSelector from '@/modules/core/components/orgSelector/OrgSelector.vue';
import PrimarySecondarySelector from '@/modules/core/components/primarySecondarySelector/PrimarySecondarySelector.vue';
import UserAgreements from '@/modules/core/components/userAgreements/UserAgreements.vue';
import Schedule from '@/modules/core/components/schedule/Schedule.vue';
import { getFeatureFlagsStore } from '@/modules/core/mixins/stores/featureFlagsMixin';
import '@/modules/core/validators/formValidators';
import HpFileUpload from '@/componentLibrary/fileUpload/FileUpload.vue';
import HpInput from '@/componentLibrary/input/Input.vue';
import HpSelect from '@/componentLibrary/select/Select.vue';
import HpOptionGroup from '@/componentLibrary/optionGroup/OptionGroup.vue';
import SubnetSizeFields from '@/componentLibrary/SubnetSizeFields/SubnetSizeFields';
import HpSummary from '@/componentLibrary/summary/Summary.vue';
import HpVmSizeSelect from '@/modules/core/components/vmSizeSelect/VmSizeSelect.vue';
import HpCheckboxes from '@/componentLibrary/checkboxes/Checkboxes.vue';

export default {
  name: 'Field',
  components: {
    HpSummary,
    HpCascade,
    HpFileUpload,
    HpInput,
    HpList,
    HpOptionGroup,
    HpPeoplePicker,
    HpSelect,
    OrgSelector,
    OwnerSelector,
    PrimarySecondarySelector,
    Schedule,
    SubnetSizeFields,
    UserAgreements,
    ValidationProvider,
    HpVmSizeSelect,
    HpCheckboxes,
  },
  mixins: [getFeatureFlagsStore],
  props: {
    displayValues: {
      type: Object,
    },
    disabled: {
      type: Boolean,
    },
    schema: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
    },
    projectContext: {
      type: Object,
    },
    stepResponses: {
      type: Object,
    },
  },
  data() {
    return {
      owners: [],
      primarySecondary: [],
      person: null,
    };
  },
  computed: {
    rules() {
      if (!this.schema.rules) return {};
      return typeof this.schema.rules === 'function' ? this.schema.rules(this.value) : this.schema.rules;
    },
    customErrorMessages() {
      if (!this.schema.customErrorMessages) return {};

      return typeof this.schema.customErrorMessages === 'function' ? this.schema.customErrorMessages(this.value) : this.schema.customErrorMessages;
    },

    helpText() {
      if (!this.schema || !this.schema.helperText) return null;
      if (typeof this.schema.helperText === 'string') return this.schema.helperText;
      return this.schema.helperText({
        model: this.value,
        context: this.projectContext,
        responses: this.stepResponses,
        displayValues: this.displayValues,
      });
    },
    isSelect() {
      const s = this.schema;

      return s.component === 'select' || (!s.component && (s.options || s.optionsUrl));
    },
    isInput() {
      const s = this.schema;
      return s.component === 'input' ||
        (!s.component && !s.options && !s.optionsUrl);
    },
    fieldOptionsUrl() {
      if (!this.schema || !this.schema.optionsUrl) return null;
      if (typeof this.schema.optionsUrl === 'function') return this.schema.optionsUrl(this.value, this.projectContext, this.stepResponses);
      return this.schema.optionsUrl;
    },
    isCascade() {
      const s = this.schema;
      return s.component === 'cascade' || s.cascadeFrom;
    },
    required() {
      return this.rules && this.rules.required;
    },
    orgSelectorModel() {
      const {
        businessUnit,
        businessFunction,
        lineOfBusiness,
      } = this.value;

      return {
        businessUnit,
        businessFunction,
        lineOfBusiness,
      };
    },
    scheduleModel() {
      const {
        schedule,
        vmModifyStorage,
      } = this.value;

      return {
        schedule,
        vmModifyStorage,
      };
    },
    fieldOptions() {
      if (!this.schema || !this.schema.options) return null;
      if (typeof this.schema.options === 'function') return this.schema.options(this.value, this.projectContext, this.stepResponses, this.displayValues);
      return this.schema.options;
    },
    subnetSizeFieldsModel() {
      const {
        subnetSize,
        minClusterNodes,
        maxClusterNodes,
        environment,
      } = this.value;
      return {
        subnetSize,
        minClusterNodes,
        maxClusterNodes,
        environment,
      };
    },
    isDisabled() {
      return this.disabled || this.schema.disabled && this.schema.disabled(this.value, this.projectContext);
    },
    isHidden() {
      return (this.schema.hideFeatureFlag && !this.getFeatureFlagByKey(this.schema.hideFeatureFlag)) ||
        (this.schema.hide && this.schema.hide(this.value, this.projectContext));
    },
    techEnvironment() {
      return this.schema.techEnvironment && this.schema.techEnvironment(this.value);
    },
    getEnvironmentFromStepResponse() {
      const values = Object.values(this.stepResponses);
      const envObject = values.find(value => value && value.environment);
      return envObject ? envObject.environment : null;
    },
  },
  created() {
    if (this.name === 'primarySecondarySelector') this.primarySecondary = [this.value.primaryUser, this.value.secondaryUser];
    if (this.name === 'ownerSelector') this.owners = [this.value.businessOwner, this.value.technicalOwner];
    if (this.schema.component === 'peoplePicker') this.person = this.value[this.name];
    if (this.schema.default !== undefined) {
      const displayValue = typeof this.schema.default === 'boolean' ?
        (this.schema.default ? 'Yes' : 'No') :
        this.schema.default;
      this.$emit('update-display-value', { [this.schema.label]: displayValue });
    }
  },
  methods: {
    emitValue(val) {
      if (this.schema && this.schema.onUpdate) {
        const { model, displayValues } = this.schema.onUpdate(val, { ...this.value }, { ...this.displayValues });
        this.$emit('input', {
          ...model,
          [this.name]: val,
        });

        // two updates going through for update-display-value this one is stale for this property only so remove this value to use the other
        delete displayValues[this.schema.label];

        if (displayValues) this.$emit('update-display-value', displayValues);
      } else {
        this.$emit('input', {
          ...this.value,
          [this.name]: val,
        });
      }
    },
    emitOwners(val) {
      this.owners = val;
      const businessOwner = this.mapOwner(val[0]);
      const technicalOwner = this.mapOwner(val[1]);
      this.$emit('input', {
        ...this.value,
        businessOwner,
        technicalOwner,
      });

      this.$emit('display-value', {
        'Business Owner': (businessOwner ? `${businessOwner.firstName} ${businessOwner.lastName}` : null),
        'Technical Owner': (technicalOwner ? `${technicalOwner.firstName} ${technicalOwner.lastName}` : null),
      });
    },
    emitPrimarySecondary(val) {
      this.primarySecondary = val;
      const primaryUser = this.mapOwner(val[0]);
      const secondaryUser = this.mapOwner(val[1]);
      this.$emit('input', {
        ...this.value,
        primaryUser,
        secondaryUser,
      });

      this.$emit('display-value', {
        'Primary User': (primaryUser ? `${primaryUser.firstName} ${primaryUser.lastName}` : null),
        'Secondary User': (secondaryUser ? `${secondaryUser.firstName} ${secondaryUser.lastName}` : null),
      });
    },
    // composite components that have more than a single return;
    emitObject(val) {
      this.$emit('input', {
        ...this.value,
        ...val,
      });
    },
    emitPerson(val) {
      this.person = val;
      this.$emit('input', {
        ...this.value,
        [this.name]: this.mapOwner(val),
      });
    },
    mapOwner(owner) {
      if (!owner) return null;
      const {
        firstName, lastName, mudId, email,
      } = owner;
      return {
        firstName, lastName, mudId, email,
      };
    },
  },
};
</script>
