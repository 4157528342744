import Vue from 'vue';
import App from './App.vue';
import VueAppInsights from 'vue-application-insights';
import VueModular from 'vue-modular';
import router from '@/modules/core/router';
import * as modules from '@/modules';
import '@/quasarSetup';
import eventBus from './eventBus';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { PublicClientApplication } from '@azure/msal-browser';

const tenant = process.env.VUE_APP_API_SECURITY_TENANT_ID;
const clientId = process.env.VUE_APP_API_SECURITY_CLIENT_ID;
const redirectUri = process.env.VUE_APP_API_SECURITY_REDIRECT_URI;

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

Vue.config.productionTip = false;
Vue.prototype.$eventBus = eventBus;
Vue.use(router);
Vue.use(PiniaVuePlugin);
Vue.use(VueModular, { modules, router });

Vue.use(VueAppInsights, {
  appInsightsConfig: {
    enableCorsCorrelation: true,
    enableAutoRouteTracking: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableAjaxErrorStatusText: true,
    correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
    disableTelemetry: process.env.VUE_APP_AZURE_APPLICATION_INSIGHTS_DISABLED === 'true',
  },
  id: process.env.VUE_APP_AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
  router,
});

Vue.prototype.$msalInstance = new PublicClientApplication({
  auth: {
    authority: `https://login.microsoftonline.com/${tenant}`,
    clientId,
    redirectUri,
    tenant,
    scope: [`${clientId}/.default`],
  },
  cache: {
    cacheLocation: 'localStorage',
  },
});

new Vue({
  router,
  pinia,
  render: h => h(App),
}).$mount('#app');

Vue.config.errorHandler = err => {
  // server error, let developers still get the error
  if (
    err &&
    err.response &&
    err.response.status === 500 &&
    process.env.NODE_ENV === 'production'
  ) {
    router.push({ name: 'serverError' });
  } else {
    throw err;
  }
};
