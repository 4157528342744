<template>
  <hp-alert
    v-if="showError"
    type="error"
    heading="Oops something went wrong"
  >
    <div>
      Form submit failed please check the form and resubmit.

      <span
        v-if="fieldErrors && fieldErrors.length"
        class="field-errors"
      >
        <ul>
          <li
            v-for="(item, index) in fieldErrors"
            :key="index"
            @click="scollTo(item.label)"
          >
            {{ item.label }}
          </li>
        </ul>
      </span>
    </div>
  </hp-alert>
</template>

<script>
import HpAlert from '@/componentLibrary/alert/Alert.vue';

export default {
  name: 'FormValidationError',
  components: {
    HpAlert,
  },
  props: {
    error: {
      type: [Object, String],
    },
  },
  computed: {
    erroredFieldAndLabelArray() {
      return this.veeValidErrorsAsArray.map(key => ({
        field: key,
        label: this.error.map[key],
      }));
    },
    fieldErrors() {
      return (!this.error || !this.error.veeValidErrors || !this.error.map) ? [] : this.erroredFieldAndLabelArray;
    },
    showError() {
      return this.error && this.veeValidErrorsAsArray.length;
    },
    veeValidErrorsAsArray() {
      return Object.keys(this.error.veeValidErrors);
    },
  },
  watch: {
    error(newVal) {
      if (!newVal) return;
      const el = document.querySelector('.form-top');
      if (el) el.scrollIntoView({ behavior: 'smooth' });
    },
  },
  created() {
    if (this.$eventBus) this.$eventBus.$on('controlUpdated', this.clearError);
  },
  mounted() {
    // mounted as we need to query dom.
    if (!this.error) return;

    const el = document.querySelector('.form-top');
    if (el) el.scrollIntoView({ behavior: 'smooth' }); // can only be null in unit testing
  },
  beforeDestroy() {
    this.$eventBus.$off('controlUpdated', this.clearError);
  },
  methods: {
    clearError({ label }) {
      if (this.error) this.error.clearError(label);
    },
    scollTo(fieldLabel) {
      const el = Array
        .from(document.querySelectorAll('label, legend'))
        .find(ele => ele.textContent.trim().startsWith(fieldLabel));

      if (el) el.scrollIntoView({ behavior: 'smooth' });
    },
  },
};
</script>
<style lang="scss" scoped>
.field-errors{
  li {
    color: $color-negative !important;
    cursor: pointer;
  }
}

</style>
