import FormPageLayout from '@/modules/core/layouts/FormPageLayout.vue';
import { SystemErrorModel } from '../models/systemErrorModel';

export const formPage = {
  components: {
    FormPageLayout,
  },
  data() {
    return {
      error: null,
      success: null,
      successMessage: null,
    };
  },
  created() {
    this.success = false;
  },
  methods: {
    submitErrorHandler(error) {
      if (error instanceof SystemErrorModel) {
        this.dirty = false;
      }
      this.error = error;
    },
    componentFailedHandler(error) {
      this.error = { ...error }; // loose the instance type this could be a system or validaiton error from an api call but we want a component failed error.
    },
    submitSuccessMessageHandler(successMessage) {
      this.success = true;
      this.successMessage = successMessage;
    },
    submitSuccessHandler() {
      this.success = true;
    },
  },
};
