import { write } from '@/modules/core/services/crudService';
import { formSetup } from '../formSetup';
import { actionWithDrawer } from '../actionWithDrawer/actionWithDrawer';
import { ValidationErrorModel } from '@/modules/core/models/validationErrorModel';

export const drawerWithForm = {
  mixins: [formSetup, actionWithDrawer],
  methods: {
    async sendForm(payload, submitUrl) {
      this.processing = true;
      const transformedPayload = this.transformPayload ? this.transformPayload(payload) : payload;

      try {
        const res = await write(transformedPayload, submitUrl);

        this.drawerResult = this.buildDrawerResult(res && res.data && res.data.data);
      } catch (error) {
        if (error instanceof ValidationErrorModel && error.veeValidErrors) {
          error.setFieldMap(this.schema);
          this.$refs.form.setErrors(error.veeValidErrors);
        }
        this.drawerResult = { error };
      }
      this.processing = false;
    },
    buildDrawerResult(data) {
      return {
        success: true,
        message: data && +data.requestId ?
          `Your request ${data.requestId} has successfully been submitted` :
          (data && data.incidentNumber ? `Your incident ${data.incidentNumber} has successfully been created.` : 'Submit successful!'),
      };
    },
    handleError(error) {
      this.drawerResult = { error };
    },
  },
};
