<template>
  <div :class="`${type} alert q-mt-1 q-mx-0 q-mb-2 q-pa-1`">
    <div class="heading q-pb-1 text-capitalize">
      {{ heading || type }}
    </div>
    <slot>
      {{ text }}
    </slot>
  </div>
</template>

<script>
export default {
  name: 'HpAlert',
  props: {
    heading: {
      type: String,
    },
    type: {
      type: String,
      default: 'warning',
      validator: value => ['error', 'warning', 'success', 'info'].includes(value),
    },
    text: {
      type: [String, Object],
    },
  },
};
</script>

<style lang="scss" scoped>
  .error {
    border-color: $color-negative;
    background-color: rgba($color-negative, .1);
  }

  .warning {
    border-color: $color-warning;
    background-color: rgba($color-warning, .1);
  }

  .success {
    border-color: $color-positive;
    background-color: rgba($color-positive, .1);
  }

  .info {
    border-color: darken($color-info, 45%);
    background-color: $color-info;
  }

  .alert {
    border-style: solid;
    border-width: 0 5px;
    border-radius: 4px;
    color: $color-typography;

    .heading {
      font-weight: 600;
    }

    a {
      color: $color-typography;
      text-decoration: underline;
      text-underline-offset: .1rem;
      font-weight: 500;
    }
  }
</style>
