<template>
  <div>
    <component-failed
      v-if="isProd"
      class="q-ma-5"
      :error="error"
    />
    <div
      v-if="(!isProd || (!error && flags)) && account"
      id="app"
    >
      <q-layout view="hHh lpR fFf">
        <top-bar @toggleMenu="menu = !menu" />
        <div class="content">
          <main-menu v-model="menu" />
          <q-page-container>
            <q-page
              id="scrollView"
              class="layout main--container"
            >
              <hp-branch-tag-banner />
              <website-status-banner />

              <router-view />
            </q-page>
          </q-page-container>
        </div>
      </q-layout>
    </div>
  </div>
</template>

<script>
import TopBar from '@/modules/core/components/topBar/TopBar.vue';
import MainMenu from '@/modules/core/components/mainMenu/MainMenu.vue';
import HpBranchTagBanner from '@/componentLibrary/branchTagBanner/BranchTagBanner.vue';
import WebsiteStatusBanner from '@/modules/core/components/websiteStatusBanner/WebsiteStatusBanner';
import * as msalService from '@/modules/core/services/msalService';
import { getFeatureFlagsStore } from '@/modules/core/mixins';
import ComponentFailed from '@/componentLibrary/errors/componentFailed/ComponentFailed.vue';

export default {
  name: 'App',
  components: {
    ComponentFailed,
    HpBranchTagBanner,
    MainMenu,
    TopBar,
    WebsiteStatusBanner,
  },
  mixins: [getFeatureFlagsStore],
  data() {
    return {
      account: null,
      error: null,
      loading: false,
      menu: false,
    };
  },
  computed: {
    isProd() {
      return process.env.VUE_APP_API_ENV === 'production';
    },
  },
  async created() {
    await this.$msalInstance.handleRedirectPromise();
    const account = msalService.getAccount(this.$msalInstance);

    if (!account) {
      await this.$msalInstance.loginRedirect();
      return;
    }

    this.account = account; // done last in method auth to guarantee msal is done and ready for other requests

    try {
      await this.initFeatureFlags();
    } catch (err) {
      this.error = err;
    }
  },
};
</script>

<style lang="scss">
$header-height: var(--header-height);

#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
}

.layout {
  height: calc(100vh - var(--header-height));
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  display: grid;
  grid: 'nav module-host' 1fr / min-content 1fr;
}

.main--container {
  background-color: #fafafa;
  a {
    text-decoration: none;
  }
  .container {
    max-width: var(--content-max-width);
    margin: 0 auto;
  }
}
</style>
