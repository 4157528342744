<template>
  <div
    :class="{
      'row': true,
      [`q-col-gutter-${gutter}`]: gutter !== 'none',
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    gutter: {
      type: String,
      default: 'lg',
      validator: value => ['xs', 'sm', 'md', 'lg', 'xl', 'none'].includes(value),
    },
  },
};
</script>
