var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"org-selector"},[_c('validation-provider',{attrs:{"mode":"eager","name":"subnetSize","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('hp-select',{attrs:{"label":_vm.constants.subnetSize.label,"options":_vm.subnetSizeOptions,"data-test-id":"subnet-size","validationmessage":errors[0],"helpertextcontent":_vm.constants.subnetSize.helperText,"required":"","disabled":_vm.disabled,"value":_vm.value.subnetSize},on:{"input":_vm.subnetSizesUpdated,"update-display-value":newVal => _vm.$emit('update-display-value', { [_vm.constants.subnetSize.label]: newVal})}})]}}])}),_c('validation-provider',{attrs:{"mode":"eager","name":"minClusterNodes","rules":{
      required: true,
      min_value: 2,
      max_value: _vm.clusterNodesMaxNumber - 1,
    },"custom-messages":{
      min_value: 'Minimum number of cluster worker nodes is 2.',
      max_value: `Maximum number of cluster worker nodes must be less than ${_vm.clusterNodesMaxNumber}`,
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('hp-input',{attrs:{"label":_vm.constants.minClusterNodes.label,"helpertextcontent":_vm.getMinClustersHelperText,"validationmessage":errors[0],"type":"number","disabled":_vm.disabled || !_vm.value.subnetSize,"required":"","value":_vm.value.minClusterNodes,"data-test-id":"min-nodes-input"},on:{"input":_vm.minClusterNodesUpdated,"update-display-value":e => _vm.$emit('update-display-value', { [_vm.constants.minClusterNodes.label]: e})}})]}}])}),_c('validation-provider',{attrs:{"mode":"eager","name":"maxClusterNodes","rules":{
      required: true,
      min_value: _vm.clusterNodesMinNumber,
      max_value: _vm.clusterNodesMaxNumber,
    },"custom-messages":{
      min_value: 'Maximum can not be less than or equal to minimum.',
      max_value: `Maximum number of cluster worker nodes is ${_vm.clusterNodesMaxNumber} for this subnet size.`,
    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('hp-input',{attrs:{"label":_vm.constants.maxClusterNodes.label,"helpertextcontent":_vm.getMaxClustersHelperText,"validationmessage":errors[0],"type":"number","disabled":_vm.disabled || !_vm.value.subnetSize,"required":"","value":_vm.value.maxClusterNodes,"data-test-id":"max-nodes-input"},on:{"input":_vm.maxClusterNodesUpdated,"update-display-value":e => _vm.$emit('update-display-value', { [_vm.constants.maxClusterNodes.label]: e})}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }