export function ApmErrorModel(e) {
  if (!e) return;
  const { config, response, message } = e || {};

  if (message) this.message = message;
  if (config) {
    this.baseURL = config.baseURL;
    this.payload = config.data;
    this.method = config.method;
    this.url = config.url;
    this.timeout = config.timeout;
  }
  if (response) {
    this.response = { data: response.data };
    this.status = response.status;
    this.statusText = response.statusText;
  }
}
