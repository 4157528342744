export const RequestStatus = {
  DRAFT: 'Draft',
  'IN-APPROVAL': 'In Approval',
  APPROVED: 'Approved',
  PROCESSING: 'Provisioning',
  COMPLETED: 'Completed',
  'PENDING WITH SUPPORT': 'Pending with Support',
  FAILED: 'Failed',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
  PENDING_REVIEW: 'Awaiting Review',
  PENDING_EHS_REVIEW: 'Awaiting EHS Architecture Review',
  UNAVAILABLE: 'Unavailable',
  SUBMITTED: 'Request Submitted',
  CANCELLED: 'Request Cancelled',
  CANCELLED_REVIEW_EXPIRED: 'Cancelled (Review Expired)',
};

export const GlobalMessages = {
  SERVER_ERROR_HEADING_MSG: 'Oops...',
  SERVER_ERROR_CONTENT_MSG: 'It looks like there is a technical issue accessing servers right now.  Please try again later whilst we investigate the fault.If you continue to see this error, please contact \'hostingportal-support@gsk.com\' .',
};

export const RequestType = {
  'AZ-RG': 'Azure Global RG Request',
  'AZ-CH': 'Azure China RG Request',
  'AZ-AKS': 'Azure K8S Namespace Request',
  GCP_PROJECT: 'GCP Project Request',
  GCP_EDIT_PROJECT_SERVICES: 'GCP Project Edit Services Request',
};
