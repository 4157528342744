export const getAccount = msalInstance => {
  if (!msalInstance) return {}; // unit testing only;
  const [account] = msalInstance.getAllAccounts();
  return account;
};

export const getToken = async (msalInstance, account) => {
  if (!account) account = getAccount(msalInstance);

  const tokenRequest = {
    scopes: ['user.read'],
    account,
  };

  return msalInstance
    .acquireTokenSilent(tokenRequest)
    .catch(async err => {
      // can be very hard to debug so output for developer.
      console.log('authErr', err);
      if ((err.errorMessage && err.errorMessage.includes('interactive')) ||
        (err.name === 'InteractionRequiredAuthError')) {
        await msalInstance.handleRedirectPromise();
        await msalInstance.acquireTokenRedirect(tokenRequest);
      }
      throw err;
    });
};

export const logout = msalInstance => {
  const account = getAccount(msalInstance);
  msalInstance.logoutRedirect({ account });
};
