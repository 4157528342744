import { defineStore } from 'pinia';
import { read } from '../services/crudService';

export const useMyProjectsStore = defineStore('myProjects', {
  state: () => ({
    context: null,
    projects: null,
    errorInitializingProjects: null,
  }),
  persist: true,
  actions: {
    setContext(project) {
      this.context = project;
    },
    async initProjects(currency = 'USD') {
      this.projects = null;
      try {
        this.projects = await read(`/my-projects/${currency}`);
      } catch (error) {
        this.errorInitializingProjects = error;
      }
    },
  },
});
