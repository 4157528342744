<template>
  <hp-alert
    v-if="error"
    type="error"
    heading="Oops something went wrong"
    :text="errorText"
  />
</template>

<script>
import HpAlert from '@/componentLibrary/alert/Alert.vue';

export default {
  name: 'ComponentFailed',
  components: {
    HpAlert,
  },
  props: {
    error: {
      type: [String, Error, Object],
    },
  },
  computed: {
    errorText() {
      if (!this.error) return null;
      if (typeof this.error === 'string') {
        return this.error;
      }
      if (
        this.error.response &&
        this.error.response.data &&
        typeof this.error.response.data === 'string'
      ) {
        // api error with error message in data.
        return this.error.response.data;
      }
      if (this.error.response && [503, 500, 404].includes(this.error.response.status)) {
        return 'The application has encountered a server error.  Our technical staff have been automatically notified and will be looking into this with the utmost urgency.  Please try again soon.';
      }
      if (typeof this.error.message === 'string') {
        // standard error thrown
        return this.error.message;
      }
      return 'The application has encountered a server error.  Our technical staff have been automatically notified and will be looking into this with the utmost urgency.  Please try again soon.';
    },
  },
};
</script>
