export default {
  routes: [
    {
      path: '/changelog',
      name: 'Changelog',
      component: () => import(
        '@/modules/changelog/views/changelog/Changelog.vue'
      ),
    },
  ],
};
