/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import {
  alpha_num,
  between,
  excluded,
  email,
  is_not,
  length,
  max,
  max_value,
  min,
  min_value,
  numeric,
  oneOf,
  required,
  size,
  is,
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('max', {
  ...max,
});

extend('min', {
  ...min,
  message(_, args) {
    return `Must have a minimum of ${args.length} characters`;
  },
});

extend('alpha_num', {
  ...alpha_num,
  message: 'This field accepts alpha numeric characters only',
});

extend('email', {
  ...email,
  message: 'Must be a valid email id',
});

extend('excluded', {
  ...excluded,
  message: 'The "{_value_}" value is not permitted.',
});

extend('oneOf', {
  ...oneOf,
});

extend('between', {
  ...between,
});

extend('length', {
  ...length,
});

extend('min_value', {
  ...min_value,
  message(_, args) {
    return `Must be equal or greater than ${args.min}`;
  },
});
extend('max_value', {
  ...max_value,
  message(_, args) {
    return `Must be equal or less than ${args.max}`;
  },
});
extend('numeric', {
  ...numeric,
});

extend('pattern', {
  // eslint-disable-next-line
  validate: (value, { regex }) => {
    const rg = RegExp(`${regex}`);
    return rg.test(value);
  },
  params: ['regex'],
  message: '{_field_} does not allow special characters and spaces',
});

extend('chips', {
  // eslint-disable-next-line no-shadow
  validate: (value, { min, max }) => {
    const v = value.split(',');
    return v.length >= min && v.length <= max;
  },
  params: ['min', 'max'],
  message: '{_field_} requires minimum {min} item(s), up to {max}',
});

extend('checkId', {
  // eslint-disable-next-line
  validate: (value, { userMudId }) => {
    return value[0].mudId !== userMudId;
  },
  params: ['userMudId'],
  message: 'User MudId in {_field_} cannot be same.',
});

extend('positive', {
  validate: value => isNaN(value) || Math.sign(value) === 1,
  message: 'Enter a positive value',
});

extend('techOwner', {
  message:
    'The Technical Owner cannot be the same person as the Business Owner.  Please choose a different person',
  validate: (techOwner, userMudId) => {
    if (!Array.isArray(techOwner)) {
      return techOwner.mudId !== userMudId[0];
    }
    return techOwner[0].mudId !== userMudId[0];
  },
});

extend('size', {
  ...size,
  message(_, args) {
    return `File size should not exceed ${args.size / 1024} MB`;
  },
});

extend('max_length', {
  validate: (data, { maxLength }) => data.length <= maxLength,
  params: ['maxLength'],
});

extend('min_length', {
  validate: (value, { minLength }) => value.length >= minLength,
  params: ['minLength'],
  message: 'At least {minLength} items need to be selected',
});

extend('noDups', {
  validate: (val, possibleDups) => !possibleDups.includes(val),
  message: 'Duplicate Entry',
});

extend('is_not', {
  ...is_not,
});

extend('is', {
  ...is,
});

extend('multipleOf', {
  validate: (value, { base }) => (value % base) === 0,
  params: ['base'],
  message: `The value must be a multiple of {base}.`,
});
