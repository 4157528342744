export default {
  id: 'iaas',
  name: 'IaaS',
  path: '/iaas',
  routeName: 'iaas',
  desc: 'This self-service enables you to request a Virtual Machine on the Azure infrastructure (IaaS). The virtual machines are managed and supported by Core Tech Enterprise Hosting Services.',
  graphic: 'cloud_server',
  breadcrumbParent: 'home', // name of parent bread crumb
  breadcrumbTitle: 'IaaS',
  menuTitle: 'IaaS',
  services: [
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Azure SQL Server',
      desc: 'This self-service enables you to request a database on the Azure infrastructure (IaaS). The virtual machines on which the database servers run are managed and supported by Core Tech Enterprise Hosting Services',
      graphic: 'cloud_server',
      id: 'azureSqlServerOverview',
      menuTitle: 'Azure SQL Server',
      name: 'Azure SQL',
      path: '/iaas/azure-sql/overview',
      routeName: 'azureSqlServerOverview',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Azure PostgreSQL Server',
      desc: 'This self-service enables you to request a database on the Azure infrastructure (IaaS). The virtual machines on which the database servers run are managed and supported by Core Tech Enterprise Hosting Services',
      graphic: 'cloud_server',
      id: 'iaasAzurePostgreSqlOverview',
      menuTitle: 'Azure PostgreSQL Server',
      name: 'Azure PostgreSQL Server',
      path: '/iaas/azure-postgresql/overview',
      routeName: 'iaasAzurePostgreSqlOverview',
    },
    {
      breadcrumbParent: 'iaasAzurePostgreSqlOverview',
      breadcrumbTitle: 'Create',
      id: 'azurePostgreSqlForm',
      path: '/iaas/azure-postgresql/form',
      routeName: 'iaasAzurePostgreSqlDatabaseForm',
    },
    {
      breadcrumbParent: 'iaasAzurePostgreSqlOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'azurePostgreSqlDashboard',
      path: '/iaas/azure-postgresql/dashboard',
      routeName: 'iaasAzurePostgreSqlDashboard',
    },
    {
      breadcrumbParent: 'iaasAzurePostgreSqlOverview',
      breadcrumbTitle: 'FAQ',
      id: 'azurePostgreSqlFaq',
      path: '/iaas/azure-postgresql/faq',
      routeName: 'iaasAzurePostgreSqlFaq',
    },
    {
      breadcrumbParent: 'azureSqlServerOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'azureSqlServerDashboard',
      path: '/iaas/azure-sql/dashboard',
      routeName: 'azureSqlServerDashboard',
    },
    {
      breadcrumbParent: 'azureSqlServerOverview',
      breadcrumbTitle: 'Create',
      id: 'azureSqlServerForm',
      path: '/iaas/azure-sql/form',
      routeName: 'azureSqlServerForm',
    },
    {
      breadcrumbParent: 'azureSqlServerOverview',
      breadcrumbTitle: 'FAQ',
      id: 'azureSqlServerFaq',
      path: '/iaas/azure-sql/faq',
      routeName: 'azureSqlServerFaq',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Azure Oracle Server',
      desc: 'This self-service enables you to request a database on the Azure infrastructure (IaaS). The virtual machines on which the database servers run are managed and supported by Core Tech Enterprise Hosting Services.',
      graphic: 'cloud_server',
      id: 'azureOracleServerOverview',
      menuTitle: 'Azure Oracle Server',
      name: 'Azure Oracle Server',
      path: '/iaas/azure-oracle/overview',
      routeName: 'azureOracleServerOverview',
    },
    {
      breadcrumbParent: 'azureOracleServerOverview',
      breadcrumbTitle: 'Create',
      id: 'azureOracleServerForm',
      path: '/iaas/azure-oracle/form',
      routeName: 'azureOracleServerForm',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Linux VM Servers',
      desc: 'This self-service enables you to request a Linux Virtual Machine on the Azure infrastructure (IaaS). The virtual machines are managed and supported by Core Tech Enterprise Hosting Services.',
      graphic: 'cloud_server',
      id: 'linuxVirtualMachinesOverview',
      menuTitle: 'Azure Linux VM',
      name: 'Linux VM',
      path: '/iaas/linux-virtual-machines/overview',
      routeName: 'linuxVirtualMachinesOverview',
    },
    {
      breadcrumbParent: 'linuxVirtualMachinesOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'linuxVirtualMachinesDashboard',
      path: '/iaas/linux-virtual-machines/dashboard',
      routeName: 'linuxVirtualMachinesDashboard',
    },
    {
      breadcrumbParent: 'linuxVirtualMachinesDashboard',
      breadcrumbTitle: 'Create',
      id: 'linuxVirtualMachinesForm',
      path: '/iaas/linux-virtual-machines/form',
      routeName: 'linuxVirtualMachinesForm',
    },
    {
      breadcrumbParent: 'linuxVirtualMachinesOverview',
      breadcrumbTitle: 'FAQ',
      id: 'linuxVirtualMachinesFaq',
      path: '/iaas/linux-virtual-machines/faq',
      routeName: 'linuxVirtualMachinesFaq',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Linux Scale Set',
      desc:
        'This self-service enables you to request a Linux Scale Set on the Azure infrastructure (IaaS). The virtual machines are managed and supported by Core Tech Enterprise Hosting Services.',
      graphic: 'cloud_server',
      id: 'linuxScaleSetOverview',
      menuTitle: 'Linux Scale Set',
      name: 'Linux Scale Set',
      path: '/iaas/linux-scale-set/overview',
      routeName: 'linuxScaleSetOverview',
    },
    {
      breadcrumbParent: 'linuxScaleSetOverview',
      breadcrumbTitle: 'Create',
      id: 'linuxScaleSetForm',
      path: '/iaas/linux-scale-set/form',
      routeName: 'linuxScaleSetForm',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Windows VM Servers',
      desc: 'This self-service enables you to request a Windows Virtual Machine on the Azure infrastructure (IaaS). The virtual machines are managed and supported by Core Tech Enterprise Hosting Services.',
      graphic: 'cloud_server',
      id: 'windowsVirtualMachinesOverview',
      menuTitle: 'Azure Windows VM',
      name: 'Windows VM',
      path: '/iaas/windows-virtual-machines/overview',
      routeName: 'windowsVirtualMachinesOverview',
    },
    {
      breadcrumbParent: 'windowsVirtualMachinesOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'windowsVirtualMachinesDashboard',
      path: '/iaas/windows-virtual-machines/dashboard',
      routeName: 'windowsVirtualMachinesDashboard',
    },
    {
      breadcrumbParent: 'windowsVirtualMachinesDashboard',
      breadcrumbTitle: 'Create',
      id: 'windowsVirtualMachinesForm',
      path: '/iaas/windows-virtual-machines/form',
      routeName: 'windowsVirtualMachinesForm',
    },
    {
      breadcrumbParent: 'windowsVirtualMachinesOverview',
      breadcrumbTitle: 'FAQ',
      id: 'windowsVirtualMachinesFaq',
      path: '/iaas/windows-virtual-machines/faq',
      routeName: 'windowsVirtualMachinesFaq',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'GCP VM Servers',
      desc: 'This self-service enables you to request a GCP Virtual Machine',
      graphic: 'cloud_server',
      id: 'gcpVirtualMachinesOverview',
      menuTitle: 'GCP VM',
      name: 'GCP VM',
      path: '/iaas/gcp-virtual-machines/overview',
      routeName: 'gcpVirtualMachinesOverview',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'On Premise',
      graphic: 'cloud_server',
      id: 'onPremiseOverview',
      menuTitle: 'On Premise',
      name: 'On Premise',
      path: '/iaas/on-premise/overview',
      routeName: 'onPremiseOverview',
    },
    {
      breadcrumbParent: 'onPremiseOverview',
      breadcrumbTitle: 'Create',
      id: 'onPremiseForm',
      path: '/iaas/on-premise/form',
      routeName: 'onPremiseForm',
    },
    {
      breadcrumbParent: 'onPremiseOverview',
      breadcrumbTitle: 'FAQ',
      id: 'onPremiseFaq',
      path: '/iaas/on-premise/faq',
      routeName: 'onPremiseFaq',
    },
  ],
};
