<template>
  <div
    :class="{
      'input-disabled': disabled,
      'input-error': !!validationmessage,
      'input-base': true,
      'hp-input-class': true,
    }"
  >
    <label class="label-container">
      {{ fieldlabel }}
      <hp-tooltip
        :label="label"
        :helpertextcontent="helpertextcontent"
      >
        <template #helpertextcontent>
          <slot name="helpertextcontent" />
        </template>
      </hp-tooltip>
    </label>

    <q-select
      no-error-icon
      input-debounce="0"
      :class="classes"
      :disable="disabled"
      :value="selectValue"
      :error-message="validationmessage"
      :error="!!validationmessage"
      :use-input="useinput"
      :fill-input="fillInput"
      :hide-selected="hideSelected"
      outlined
      :options="filterOptions"
      :options-dense="optionsDense"
      popup-content-class="ddl-max-items text-no-wrap"
      input-class="ellipsis"
      :use-chips="useChips"
      @filter="filter"
      @input="modelUpdated"
      @focus="focusEl"
      @blur="blurEl"
    >
      <template
        v-if="emptyText"
        #no-option
      >
        <q-item>
          <q-item-section class="text-grey">
            No results
          </q-item-section>
        </q-item>
      </template>
      <template
        v-if="$scopedSlots['selected-item']"
        #selected-item="scope"
      >
        <slot
          v-bind="scope"
          name="selected-item"
        />
      </template>
      <template
        v-if="$scopedSlots.option"
        #option="scope"
      >
        <slot
          v-bind="scope"
          name="option"
        />
      </template>
    </q-select>
  </div>
</template>

<script>
import HpTooltip from '../tooltip/Tooltip.vue';
import { read } from '@/modules/core/services/crudService';

export default {
  name: 'HpSelect',
  components: {
    HpTooltip,
  },
  props: {
    autofocus: {
      type: Boolean,
    },
    classes: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    helpertextcontent: {
      type: String,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
    },
    optionsDense: {
      type: Boolean,
    },
    optionsUrl: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    validationmessage: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
    useinput: {
      type: Boolean,
    },
    fillInput: {
      type: Boolean,
    },
    hideSelected: {
      type: Boolean,
    },
    emptyText: {
      type: String,
    },
    useChips: {
      type: Boolean,
    },
  },
  data() {
    return {
      filterOptions: this.options || [],
      smartOptions: [],
    };
  },
  computed: {
    fieldlabel() {
      return this.required ? `${this.label} *` : this.label;
    },
    selectValue() {
      const options = [...(this.options || this.smartOptions)];
      return !options || !options.length ?
        null :
        options.find(f => f.value === this.value);
    },
  },
  watch: {
    options: {
      deep: true,
      handler(newVal) {
        if (newVal && newVal.length === 1 && this.value !== newVal[0].value) {
          this.modelUpdated(newVal[0]);

          // this is needed as vee-valdiator depends on the blur event to validate
          this.$emit('blur', newVal[0].value);
        }
      },
    },
    value() {
      // if clause will only not happen in unit testing
      if (this.$eventBus) this.$eventBus.$emit('controlUpdated', { label: this.label });
    },
    optionsUrl() {
      this.loadOptions();
    },
  },
  created() {
    if (this.options && this.options.length === 1) {
      this.modelUpdated(this.options[0]);
      if (this.$eventBus) this.$eventBus.$emit('controlUpdated', { label: this.label });
    }

    if (this.value && this.options) {
      const selected = this.options.find(r => r.value === this.value);
      if (selected) {
        this.$emit('update-display-value', selected.label);
        this.$emit('update-display-object', selected);
      }
    }
    this.loadOptions();
  },
  methods: {
    focusEl(event) {
      this.$emit('focus', event);
      if (this.$eventBus) this.$eventBus.$emit('controlFocus', { label: this.label });
    },
    blurEl() {
      this.$emit('blur', this.value);
    },
    filter(val, update) {
      const opts = [...(this.options || this.smartOptions)];

      if (val === '') {
        update(() => {
          this.filterOptions = opts;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filterOptions = opts.filter(v => v.label.toLowerCase().indexOf(needle) > -1);
      });
    },
    modelUpdated(event) {
      if (event) {
        this.$emit('update-display-value', event.label);
        this.$emit('update-display-object', event);
      }
      this.$emit('input', event && event.value);
    },
    async loadOptions() {
      if (this.options || !this.optionsUrl) return;

      try {
        this.smartOptions = await read(this.optionsUrl);
        this.filterOptions = [...this.smartOptions];
        const defaultItem = this.smartOptions.find(f => f.default);
        if (this.smartOptions.length === 1) {
          this.modelUpdated(this.smartOptions[0]);
          this.$emit('blur', this.smartOptions[0].value);
        } else if (defaultItem && !this.value) {
          this.modelUpdated(defaultItem);
          this.$emit('blur', defaultItem.value);
        }
      } catch (error) {
        this.$emit('error', error);
      }
    },
  },
};
</script>
<style lang="scss">
  .ddl-max-items {
    max-height: 14.5rem !important;
    min-width: 100%;
  }
</style>
