<template>
  <q-drawer
    :value="true"
    side="right"
    :width="drawerWidth"
    content-class="hp-drawer__content"
    behavior="desktop"
    overlay
    elevated
    no-swipe-backdrop
    no-swipe-close
    no-swipe-open
  >
    <div class="hp-drawer-scroll">
      <div class="q-pt-3 q-px-1_5">
        <p
          v-if="title"
          class="text-weight-bold form-top"
        >
          {{ title }}
        </p>

        <form-validation-error
          v-if="isValidationError"
          :error="drawerResult.error"
        />

        <component-failed :error="componentFailedError" />
        <slot v-if="!componentFailedError" />
      </div>
    </div>
    <div
      v-if="scroll"
      class="hp-drawer-container-separator q-mx-1_5"
    />
    <div class="q-py-2 q-px-1_5">
      <slot name="buttons">
        <hp-button
          :label="cancelLabel"
          outlined
          class="dialog-button q-mr-1_5"
          data-test-id="cancel-btn"
          :disabled="processing"
          @click="$emit('cancel')"
        />
        <hp-button
          v-if="acceptLabel"
          :label="acceptLabel"
          :loading="processing"
          :disabled="disabled || acceptDisabled"
          data-test-id="accept-btn"
          @click="() => $emit('accept')"
        />
      </slot>
    </div>
  </q-drawer>
</template>

<script>
import HpButton from '@/componentLibrary/button/Button.vue';
import formValidationError from '@/componentLibrary/errors/formValidationError/formValidationError.vue';
import { ValidationErrorModel } from '@/modules/core/models/validationErrorModel';
import { notification } from '@/modules/core/mixins/notification/notification';
import ComponentFailed from '@/componentLibrary/errors/componentFailed/ComponentFailed.vue';

export default {
  name: 'HpDrawer',
  components: {
    HpButton,
    formValidationError,
    ComponentFailed,
  },
  mixins: [notification],
  props: {
    acceptDisabled: {
      type: Boolean,
    },
    acceptLabel: {
      type: String,
    },
    cancelLabel: {
      default: 'Cancel',
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    processing: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    width: {
      type: Number,
    },
    drawerResult: {
      type: Object,
    },
  },
  data() {
    return {
      scroll: false,
    };
  },
  computed: {
    isValidationError() {
      return this.drawerResult && this.drawerResult.error instanceof ValidationErrorModel;
    },
    drawerWidth() {
      if (this.$q.platform.is.mobile) return 350;
      return this.width || 415;
    },
    componentFailedError() {
      return this.drawerResult && this.drawerResult.failed && this.drawerResult.error;
    },
  },
  watch: {
    drawerResult(val) {
      if (!val || this.isValidationError || this.componentFailedError) return;
      this.notify(val.message || val.error.message, val.success);
      this.$emit('cancel');
    },
  },
  created() {
    window.addEventListener('resize', this.setDrawerScrollProperty);
  },
  destroyed() {
    window.removeEventListener('resize', this.setDrawerScrollProperty);
  },
  mounted() {
    this.setDrawerScrollProperty();
  },
  methods: {
    setDrawerScrollProperty() {
      const el = document.querySelector('.hp-drawer-scroll');
      if (el) this.scroll = el.scrollHeight > el.clientHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
.q-drawer-container::before {
  content: '';
  position: fixed;
  inset: 0;
  background-color: rgba($color-white,0.4);
  z-index: 200;
}
.hp-drawer-scroll {
  max-height: calc(100vh - 180px);
  overflow: auto;
  white-space: normal;
  /* scrollbar */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: $color-surface;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-surface-dark;
  }
}
.hp-drawer-container-separator {
  height: 1px;
  border-top: 1px solid $color-surface-medium;
}

</style>
