export default {
  id: 'cloud-onboarding',
  name: 'Cloud Onboarding',
  path: '/cloud-onboarding',
  routeName: 'cloud-onboarding',
  desc: 'Self-service requests for Cloud resources and infrastructure.',
  graphic: 'cloud',
  breadcrumbParent: 'home', // name of parent bread crumb
  breadcrumbTitle: 'Cloud Onboarding',
  menuTitle: 'Cloud Onboarding',
  services: [
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Azure Resource Groups',
      desc: 'Request a resource group in Azure Global or China.',
      graphic: 'cloud',
      id: 'azureResourceGroupsOverview',
      menuTitle: 'Azure Resource Groups',
      name: 'Azure Resource Groups',
      path: '/cloud-onboarding/azure-resource-groups/overview',
      routeName: 'azureResourceGroupsOverview',
    },
    {
      breadcrumbParent: 'azureResourceGroupsOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'azureResourceGroupsDashboard',
      path: '/cloud-onboarding/azure-resource-groups/dashboard',
      routeName: 'azureResourceGroupsDashboard',
    },
    {
      breadcrumbParent: 'azureResourceGroupsDashboard',
      breadcrumbTitle: 'Create',
      id: 'azureResourceGroupsForm',
      path: '/cloud-onboarding/azure-resource-groups/form',
      routeName: 'azureResourceGroupsForm',
    },
    {
      breadcrumbParent: 'azureResourceGroupsOverview',
      breadcrumbTitle: 'FAQ',
      id: 'azureResourceGroupsFaq',
      path: '/cloud-onboarding/azure-resource-groups/faq',
      routeName: 'azureResourceGroupsFaq',
    },

    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'GCP Projects',
      desc: 'Start deploying, organising and managing your PaaS resources in GCP.',
      graphic: 'cloud',
      id: 'gcpProjectsOverview',
      menuTitle: 'GCP Projects',
      name: 'GCP Projects',
      path: '/cloud-onboarding/gcp-projects/overview',
      routeName: 'gcpProjectsOverview',
    },
    {
      breadcrumbParent: 'gcpProjectsOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'gcpProjectsDashboard',
      path: '/cloud-onboarding/gcp-projects/dashboard',
      routeName: 'gcpProjectsDashboard',
    },
    {
      breadcrumbParent: 'gcpProjectsDashboard',
      breadcrumbTitle: 'Create',
      id: 'gcpProjectsForm',
      path: '/cloud-onboarding/gcp-projects/form',
      routeName: 'gcpProjectsForm',
    },
    {
      breadcrumbParent: 'gcpProjectsOverview',
      breadcrumbTitle: 'FAQ',
      id: 'gcpProjectsFaq',
      path: '/cloud-onboarding/gcp-projects/faq',
      routeName: 'gcpProjectsFaq',
    },
  ],
};
