export default {
  routes: [
    {
      path: '/caas/aks-namespace',
      name: 'aksNamespace',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/caas/views/kubernetesNamespace/KubernetesNamespaceView.vue'
      ),
      children: [
        {
          path: '/caas/aks-namespace/overview',
          name: 'aksNamespaceOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/caas/tabs/aksNamespaceOverview/AksNamespaceOverview.vue'
          ),
        },
        {
          path: '/caas/aks-namespace/form',
          name: 'aksNamespaceForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/caas/tabs/aksNamespaceForm/AksNamespaceForm.vue'
          ),
          meta: {
            requestType: 'AKS_NAMESPACE',
            requestName: 'Azure Kubernetes Namespace',
          },
        },
        {
          path: '/caas/aks-namespace/faq',
          name: 'aksNamespaceFAQ',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
          ),
        },
      ],
    },
    {
      path: '/caas/aks-cluster/',
      name: 'aks-cluster',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/caas/views/aksCluster/AksClusterView.vue'
      ),
      children: [{
        path: '/caas/aks-cluster/overview',
        name: 'aksClusterOverview',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/caas/tabs/aksClusterOverview/AksClusterOverview.vue'
        ),
      },
      {
        path: '/caas/aks-cluster/dashboard',
        name: 'aksClusterDashboard',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/caas/tabs/aksClusterDashboardTab/AksClusterDashboardTab.vue'
        ),
      },
      {
        path: '/caas/aks-cluster/form',
        name: 'aksClusterForm',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/caas/tabs/aksClusterForm/AksClusterForm.vue'
        ),
        meta: {
          requestType: 'AKS_CLUSTER',
          requestName: 'Azure Kubernetes Cluster',
        },
      }, {
        path: '/caas/aks-cluster/faq',
        name: 'aksClusterFAQ',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
        ),
      }],
    },
    {
      path: '/caas/gke-cluster',
      name: 'gkeCluster',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/caas/views/gkeCluster/GkeClusterView.vue'
      ),
      children: [
        {
          path: '/caas/gke-cluster/overview',
          name: 'gkeClusterOverview',
          component: () => import(
          /* webpackMode: "lazy" */
            '@/modules/caas/tabs/gkeClusterOverview/GkeClusterOverview.vue'
          ),
        },
        {
          path: '/caas/gke-cluster/faq',
          name: 'gkeClusterFaq',
          component: () => import(
          /* webpackMode: "lazy" */
            '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
          ),
        },
        {
          path: '/caas/gke-cluster/form',
          name: 'gkeClusterForm',
          component: () => import(
          /* webpackMode: "lazy" */
            '@/modules/caas/tabs/gkeClusterForm/GkeClusterForm.vue'
          ),
          meta: {
            requestType: 'GKE_CLUSTER',
            requestName: 'Google Kubernetes Cluster',
          },
        },
      ],
    },
  ],
};
