<template>
  <div
    :class="{
      'input-disabled': disabled,
      'input-error': !valid,
      'input-label': valid && !disabled,
      'input-base': true,
      'hp-input-class': true,
    }"
  >
    <label class="label-container">
      {{ fieldlabel }}

      <hp-tooltip
        :label="label"
        :helpertextcontent="helpertextcontent"
      >
        <template #helpertextcontent>
          <slot name="helpertextcontent" />
        </template>
      </hp-tooltip>
    </label>
    <q-select
      ref="peoplePicker"
      no-error-icon
      outlined
      use-input
      use-chips
      hide-dropdown-icon
      hide-hint
      bottom-slots
      :error-message="validationmessage"
      :disable="disabled"
      :options="options"
      :value="value"
      :error="!valid"
      @filter="filterFn"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
      @keyup="onKeyup"
    >
      <template
        v-if="!loading"
        #append
      >
        <img
          src="/img/product-catalog/multiple-actions-view.svg"
          class="searchIcon"
          alt="Search user"
        >
      </template>
      <template #selected-item="scope">
        <span>
          <q-chip
            v-if="value && value.mudId"
            class="q-mx-none"
            color="gsk-color-surface"
            removable
            @remove="scope.removeAtIndex(scope.index)"
          >
            <q-avatar text-color="white">
              <div
                class="flex-center hp-avatar"
                :style="{ 'background-color': scope.opt.avatar }"
              >
                {{ scope.opt.initials }}
              </div>
            </q-avatar>
            {{ scope.opt.fullName }}
          </q-chip>
          <q-tooltip
            anchor="bottom start"
            self="top start"
          >
            {{ scope.opt.firstName }}
            {{ scope.opt.lastName }}
            <br>
            {{ scope.opt.email }}
            <br>
            {{ scope.opt.description }}
          </q-tooltip>
        </span>
      </template>
      <template #option="scope">
        <q-item
          v-bind="scope.itemProps"
          v-on="scope.itemEvents"
        >
          <q-item-section avatar>
            <q-avatar text-color="white">
              <div
                class="flex-center hp-avatar"
                :style="{ 'background-color': scope.opt.avatar }"
              >
                {{ scope.opt.initials }}
              </div>
            </q-avatar>
          </q-item-section>
          <q-item-section>
            <q-item-label>
              {{ scope.opt.fullName }}
            </q-item-label>
            <q-item-label caption>
              {{ scope.opt.email }} | {{ scope.opt.description }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
      <template #no-option>
        <q-item>
          <q-item-section class="text-grey">
            No results
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import { getPeople } from '../../services/peopleSearch';
import HpTooltip from '@/componentLibrary/tooltip/Tooltip.vue';

export default {
  name: 'HpPeoplePicker',
  components: {
    HpTooltip,
  },
  props: {
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    helpertextcontent: {
      type: String,
    },
    valid: {
      type: Boolean,
      default: true,
    },
    validationmessage: {
      type: String,
    },
    value: {
      type: Object,
      default() {
        return null;
      },
    },
    disabled: {
      type: Boolean,
    },
    minLength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      options: [],
      showHint: false,
      loading: false,
    };
  },
  computed: {
    fieldlabel() {
      return this.required ? `${this.label} *` : this.label;
    },
  },
  created() {
    if (!this.value) return;

    this.getOptions(this.value.mudId);
  },
  methods: {
    getOptions(val) {
      this.loading = true;
      getPeople(val.toLowerCase())
        .then(data => {
          this.options = data;

          const [user] = this.options;
          this.$emit('input', user);
          this.$emit('update-display-value', (`${user.firstName} ${user.lastName}`));
        })
        .catch(() => {
          this.options = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // eslint-disable-next-line func-names
    fetchPeople: debounce(function (val, update) {
      this.options = [];
      getPeople(val.toLowerCase())
        .then(data => {
          update(() => {
            this.options = data;
          });
        })
        .catch(() => {
          update(() => {
            this.options = [];
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }, 200),
    filterFn(val, update, abort) {
      if (val.length < this.minLength || this.value && this.value.mudId) {
        abort();
        return;
      }
      this.loading = true;
      this.fetchPeople(val, update, abort);
    },
    onFocus(event) {
      this.$emit('focus', event);
      if (this.$eventBus) this.$eventBus.$emit('controlFocus', { label: this.label });
    },
    onBlur(event) {
      this.$emit('blur', event);
    },
    onInput(val) {
      this.$emit('input', val);
      this.$emit('change', val);
      this.$emit('update-display-value', (val ? `${val.firstName} ${val.lastName}` : null));
      if (this.$eventBus) this.$eventBus.$emit('controlUpdated', { label: this.label });
    },
    onKeyup() {
      if (this.value && this.value.mudId) {
        this.$refs.peoplePicker.updateInputValue('');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.hp-avatar{
  width: 100%;
  font-size: 0.8em;
  height: 100%;
}
.searchIcon {
  width: 1.2em;
  z-index: 1;
}
</style>
