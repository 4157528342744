<template>
  <div>
    <q-banner
      v-if="websiteStatus && websiteStatus.message && !error"
      :class="{
        'no-padding': true,
        'banner': true,
        'text-white': websiteStatus.type && websiteStatus.type.toLowerCase() === 'error',
        'bg-info': websiteStatus.type && websiteStatus.type.toLowerCase() === 'info',
        'bg-negative': websiteStatus.type && websiteStatus.type.toLowerCase() === 'error',
      }"
      inline-actions
      dense
    >
      <div class="text-center">
        {{ websiteStatus && websiteStatus.message }}
      </div>

      <template #action>
        <q-btn
          flat
          label="×"
          dense
          class="q-mr-1"
          @click="websiteStatus = null"
        />
      </template>
    </q-banner>

    <component-failed
      v-if="error"
      :error="error"
    />
  </div>
</template>

<script>
import ComponentFailed from '@/componentLibrary/errors/componentFailed/ComponentFailed.vue';
import { getWebsiteStatus } from '../../services/websiteStatusService';

export default {
  name: 'WebsiteStatusBanner',
  components: {
    ComponentFailed,
  },
  data() {
    return {
      websiteStatus: null,
      error: null,
    };
  },
  async created() {
    try {
      this.websiteStatus = await getWebsiteStatus();
    } catch (e) {
      this.error = e;
    }
  },
};
</script>
