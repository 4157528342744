export const owners = {
  data() {
    return {
      owners: [],
    };
  },
  methods: {
    ownersSelected([bo, to]) {
      this.owners = [bo, to];
      this.model.businessOwner = this.mapOwner(bo);
      this.model.technicalOwner = this.mapOwner(to);
    },
    mapOwner(owner) {
      if (!owner) return null;
      const {
        firstName, lastName, mudId, email,
      } = owner;
      return {
        firstName, lastName, mudId, email,
      };
    },
  },
};
