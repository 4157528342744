import Quasar from 'quasar/dist/quasar.esm';
import * as c from 'quasar/dist/quasar.esm';
import Vue from 'vue';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';

Vue.use(Quasar, {
  config: {
    extras: ['material-icons'],
    notify: {
      position: 'top-right',
    },
  },
  components: [
    c.QAvatar,
    c.QBanner,
    c.QBadge,
    c.QBtn,
    c.QBtnDropdown,
    c.QBtnToggle,
    c.QCard,
    c.QCardActions,
    c.QCardSection,
    c.QCheckbox,
    c.QChip,
    c.QDate,
    c.QDialog,
    c.QDrawer,
    c.QExpansionItem,
    c.QField,
    c.QFile,
    c.QHeader,
    c.QIcon,
    c.QImg,
    c.QInput,
    c.QItem,
    c.QItemLabel,
    c.QItemSection,
    c.QLayout,
    c.QLinearProgress,
    c.QList,
    c.QMarkupTable,
    c.QMenu,
    c.QOptionGroup,
    c.QPage,
    c.QPageContainer,
    c.QPopupProxy,
    c.QRadio,
    c.QRating,
    c.QRouteTab,
    c.QSelect,
    c.QSeparator,
    c.QSkeleton,
    c.QSpinner,
    c.QStep,
    c.QStepper,
    c.QStepperNavigation,
    c.QTab,
    c.QTable,
    c.QTabs,
    c.QTd,
    c.QTimeline,
    c.QTimelineEntry,
    c.QToolbar,
    c.QToolbarTitle,
    c.QTooltip,
    c.QTr,
  ],
});
