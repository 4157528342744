<template>
  <Grid>
    <grid-cell span="12">
      <validation-provider
        v-slot="{ errors }"
        mode="eager"
        name="businessOwner"
        :rules="{ required: true }"
      >
        <hp-people-picker
          :value="businessOwnerModel"
          label="Business Owner"
          :disabled="disabledBusinessOwner"
          required
          helpertextcontent="The business owner is the application or service owner as per the records in the CMDB. The business owner is responsible for the application, overall compliance, service escalation and has oversight of the ongoing costs of any cloud resources"
          :valid="!errors.length"
          :validationmessage="errors[0]"
          @input="$emit('input', [$event, technicalOwnerModel])"
          @update-display-value="$emit('update-display-value', { 'Business Owner': $event})"
        />
      </validation-provider>
    </grid-cell>

    <grid-cell span="12">
      <validation-provider
        v-slot="{ errors }"
        mode="eager"
        name="technicalOwner"
        :rules="{ required: true, techOwner: businessOwnerMudId }"
      >
        <hp-people-picker
          :value="technicalOwnerModel"
          label="Technical Owner"
          required
          :disabled="disabledTechnicalOwner"
          helpertextcontent="The technical owner will be the co-owner of the resource and be responsible for the solution design, build or deployment of the project and cloud resources. The technical owner will receive technical communications and be expected to understand and take actions on health, security and outage alerts sent from the cloud provider."
          :valid="!errors.length"
          :validationmessage="errors[0]"
          @input="$emit('input', [businessOwnerModel, $event])"
          @update-display-value="$emit('update-display-value', { 'Technical Owner': $event})"
        />
      </validation-provider>
    </grid-cell>
  </Grid>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import HpPeoplePicker from '@/modules/core/components/peoplePicker/PeoplePicker.vue';
import GridCell from '@/componentLibrary/grid/GridCell.vue';
import Grid from '@/componentLibrary/grid/Grid.vue';

export default {
  name: 'OwnerSelector',
  components: {
    ValidationProvider,
    HpPeoplePicker,
    Grid,
    GridCell,
  },
  props: {
    disabled: {
      type: [Boolean, Object],
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      businessOwner: null,
      technicalOwner: null,
    };
  },
  computed: {
    businessOwnerModel() {
      return this.value && this.value.length && this.value.length === 2 ? this.value[0] : this.businessOwner;
    },
    technicalOwnerModel() {
      return this.value && this.value.length && this.value.length === 2 ? this.value[1] : this.technicalOwner;
    },
    businessOwnerMudId() {
      return this.businessOwnerModel && this.businessOwnerModel.mudId;
    },
    disabledBusinessOwner() {
      return typeof this.disabled === 'boolean' ? this.disabled : this.disabled.businessOwner;
    },
    disabledTechnicalOwner() {
      return typeof this.disabled === 'boolean' ? this.disabled : this.disabled.technicalOwner;
    },
  },
};
</script>
