import { apiClient } from '@/modules/core/services/api';
import MyActivitiesModel from '@/modules/core/models/myActivitiesModel';
import { SystemErrorModel } from '@/modules/core/models/systemErrorModel';
import { RequestStatus } from '@/modules/core/constants';

export const getTasks = async () => {
  try {
    const {
      data: { data },
    } = await apiClient.get('/mytasks');
    return new MyActivitiesModel(data);
  } catch (error) {
    throw new SystemErrorModel(error);
  }
};

export const reviewMyTask = async payload => {
  try {
    const { data } = await apiClient.put('/mytasks', payload);
    return {
      message: data.success ? 'Successful' : 'Unsuccessful',
    };
  } catch (error) {
    throw new SystemErrorModel(error);
  }
};

export const getStatusIcon = status => {
  const lookup = {
    [RequestStatus.UNAVAILABLE]: 'help',
    [RequestStatus.REJECTED]: 'cancel',
    [RequestStatus.COMPLETED]: 'check_circle',
    [RequestStatus.APPROVED]: 'verified',
    [RequestStatus.CANCELLED]: 'cancel_black_24dp',
    [RequestStatus.CANCELLED_REVIEW_EXPIRED]: 'cancel_black_24dp',
  };

  return `img:/img/product-catalog/${lookup[status] || 'watch_later'}.svg`;
};

export const getTaskCount = () => apiClient.get('/mytasks/count');

export const getActivitiesCount = () => apiClient.get('/myactivities/count/active').then(data => data.data.data);
