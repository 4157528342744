<template>
  <q-markup-table
    flat
    class="table-background"
  >
    <tbody>
      <template v-for="(field, fieldKey) in allFields">
        <tr
          v-if="field !== null"
          :key="fieldKey"
        >
          <td
            scope="col"
            class="text-left key-field"
          >
            {{ fieldKey }}
          </td>
          <td
            scope="col"
            class="text-right"
          >
            <div v-if="Array.isArray(field) && typeof field[0] === 'string'">
              <div
                v-for="(item, index) in field"
                :key="index"
                class="text-bold"
              >
                {{ item }}
              </div>
            </div>
            <span
              v-else
              class="text-bold"
            >
              {{ field }}
            </span>
          </td>
        </tr>
      </template>
    </tbody>
  </q-markup-table>
</template>

<script>
const doNotShowFields = ['Resource Group Object Id'];

export default {
  name: 'HpSummary',
  props: {
    fields: {
      type: Object,
    },
    stepResponses: {
      type: Object,
      default: () => ({}),
    },
    displayValues: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    allFields() {
      const display = Object.entries(this.displayValues).reduce((acc, [k, v]) => {
        if ([null, ''].includes(v) || (Array.isArray(v) && !v.length) || doNotShowFields.includes(k)) return acc;
        if (k !== 'schedule') return { ...acc, [k]: v };
        return {
          ...acc,
          ...Object.entries(v).reduce((acc2, [k2, v2]) => {
            if ([null, ''].includes(v2) || (Array.isArray(v2) && !v2.length) || doNotShowFields.includes(k2)) return acc2;
            return { ...acc2, [`Schedule - ${k2}`]: v2 };
          }, acc),
        };
      }, {});

      let server = {};

      if (this.fields) {
        server = Object
          .keys(this.stepResponses)
          .reduce(
            (acc, step) => {
            // remove null values and ones that are not selected for display
              const filteredData = Object
                .entries(this.stepResponses[step])
                .reduce((data, [k, v]) => {
                  if (v === null || !this.fields[k]) return data;
                  return { ...data, [this.fields[k]]: v };
                }, {});

              return ({ ...acc, ...filteredData });
            },
            {},
          );
      }

      return Object.entries(server).reduce((acc, [k, v]) => {
        if (v === null) return acc;
        return { ...acc, [k]: v };
      }, display);
    },
  },
};
</script>
<style lang="scss">
  .confirmation {
    display: grid;
    grid-template-columns: auto auto;
  }

  .table-background {
    background: #fafafa !important;
    background-color: #fafafa !important;
  }

  .key-field {
    vertical-align: top;
    padding-top: 0.8rem !important;
  }
</style>
