import { apiClient } from '@/modules/core/services/api';
import formSubmitErrorFactory from '@/modules/core/services/formSubmitErrorFactory';
import { SystemErrorModel } from '@/modules/core/models/systemErrorModel';

export const getDraftCount = async () => {
  try {
    const {
      data: { data },
    } = await apiClient.get(`/mydrafts/count`);
    return data;
  } catch (error) {
    throw new SystemErrorModel(error);
  }
};

export const getDrafts = async () => {
  try {
    const {
      data: { data },
    } = await apiClient.get(`/mydrafts`);
    return data;
  } catch (error) {
    throw new SystemErrorModel(error);
  }
};

export const createDraft = async payload => {
  try {
    return await apiClient
      .put(`/mydrafts`, payload)
      .then(response => response.data);
  } catch (error) {
    throw formSubmitErrorFactory.getError(error);
  }
};

export const removeDraft = async id => {
  try {
    return await apiClient
      .delete(`/mydrafts/${id}`)
      .then(response => response.data);
  } catch (error) {
    throw new SystemErrorModel(error);
  }
};

export default {
  getDraftCount,
  getDrafts,
  createDraft,
  removeDraft,
};
