import * as msalService from '../../services/msalService';

export const userMixin = {
  computed: {
    userAccount() {
      return msalService.getAccount(this.$msalInstance);
    },
    userMudId() {
      return this.userAccount && this.userAccount.idTokenClaims.UID;
    },
    userFirstName() {
      if (!this.userAccount) return null;
      return this.userAccount.idTokenClaims.given_name || this.userAccount.name.split(' ')[0];
    },
    userLastName() {
      if (!this.userAccount) return null;
      return this.userAccount.idTokenClaims.family_name || this.userAccount.name.split(' ')[1];
    },
  },
};
