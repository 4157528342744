import { defineStore } from 'pinia';
import { read } from '@/modules/core/services/crudService';

export const useFeatureFlagsStore = defineStore('featureFlags', {
  state: () => ({
    featureFlags: null,
  }),
  getters: {
    flags: state => state.featureFlags,
    getFeatureFlagByKey: state => key => (!state.featureFlags && process.env.VUE_APP_API_ENV !== 'production') ||
              (state.featureFlags && state.featureFlags[`FF_ENABLE_${key}`] !== 'false'),

    getFeatureFlagByRouteName: state => routeName => {
      const key = `FF_ENABLE_${routeName.replace(/([A-Z])/g, '_$1')}`.toUpperCase();
      return state.featureFlags && state.featureFlags[key] !== 'false';
    },
  },
  actions: {
    async initFeatureFlags() {
      let interval = Date.now() + 1800000;
      const featureFlags = await read(`/system/feature-flags`);

      if (featureFlags) {
        const { expiry, timestamp, ...flags } = featureFlags;
        this.featureFlags = flags;
        if (featureFlags.expiry) interval = featureFlags.expiry - Date.now();
      }
      setTimeout(this.initFeatureFlags, interval);
    },
  },
});
