<template>
  <ul class="submenu q-pa-0 q-ma-0">
    <li
      v-for="service in activeItems"
      :key="service.id"
      class="text-body2"
    >
      <router-link
        :to="{ name: service.routeName, params: { prodId: service.id } }"
        class="q-pl-2 q-ml-0_25 q-py-0_5 q-py-0_5"
        :class="{ 'submenu-selected': isActive(service) }"
      >
        {{ isNonProdVisible(service.routeName) ? '✎' : '' }}  {{ service.menuTitle }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import { getFeatureFlagsStore } from '@/modules/core/mixins';

export default {
  name: 'SubMenu',
  mixins: [getFeatureFlagsStore],
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    activeItems() {
      return this.menuItems.filter(m => m.menuTitle && (this.isNonProd || this.getFeatureFlagByRouteName(m.routeName)));
    },
    isNonProd() {
      return process.env.VUE_APP_API_ENV !== 'production';
    },
  },
  methods: {
    isActive({ routeName }) {
      return routeName === this.$route.name;
    },
    isNonProdVisible(routeName) {
      return this.isNonProd && !this.getFeatureFlagByRouteName(routeName);
    },
  },
};
</script>
<style lang="scss" scoped>
.submenu {
  list-style: none;
  background-color: $color-white;
}
a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: $color-secondary;
}
.submenu-selected,
.submenu li:hover a {
  color: $color-primary;
}
.submenu-selected {
  font-weight: 500;
}
</style>
