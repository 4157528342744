<template>
  <grid-cell span="12">
    <section class="container__button">
      <hp-button
        class="q-mb-1"
        :label="label"
        :disabled="disabled || isSending"
        @click="handleClick"
      />
    </section>
    <q-linear-progress
      v-if="isSending"
      query
    />
  </grid-cell>
</template>

<script>
import HpButton from '@/componentLibrary/button/Button.vue';
import GridCell from '../grid/GridCell.vue';

export default {
  name: 'SubmitForm',
  components: {
    HpButton,
    GridCell,
  },
  props: {
    isSending: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    handleSubmit: {
      type: Function,
      required: true,
    },
    label: {
      type: String,
      default: 'Submit Request',
    },
  },
  methods: {
    handleClick() {
      this.handleSubmit(() => Promise.resolve(this.$emit('submit')));
    },
  },
};
</script>
