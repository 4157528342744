export default {
  businessFunction: {
    label: 'Business Function',
  },
  businessUnit: {
    label: 'Business Unit',
  },
  lineOfBusiness: {
    label: 'Line of Business',
  },
};
