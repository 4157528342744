<template>
  <hp-dialog
    :value="open"
    name="cancel"
    title="Cancel Request"
    accept-label="Yes, Leave the page"
    @accept="$emit('dirtyNavigate', true)"
    @cancel="$emit('dirtyNavigate', false)"
  >
    You are about to leave the form. If you leave you will lose any data entered so far. Do you
    wish to proceed?
  </hp-dialog>
</template>

<script>
import HpDialog from '@/componentLibrary/dialog/Dialog.vue';

export default {
  name: 'DirtyDialog',
  components: {
    HpDialog,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
