import { mapWritableState, mapActions, mapState } from 'pinia';
import { useMyProjectsStore } from '@/modules/core/stores/myProjects';

export const withProjectContextStore = {
  computed: {
    ...mapState(useMyProjectsStore, ['projects', 'errorInitializingProjects']),
    ...mapWritableState(useMyProjectsStore, {
      context: 'context',
    }),
  },
  methods: {
    ...mapActions(useMyProjectsStore, ['setContext', 'initProjects']),
  },
};
