var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'input-disabled': _vm.disabled,
    'input-error': !!_vm.validationmessage,
    'input-label': !_vm.validationmessage && !_vm.disabled,
    'input-base': true,
    'hp-input-class': true,
  }},[_c('label',{staticClass:"label-container"},[_vm._v(" "+_vm._s(_vm.fieldlabel)+" "),(_vm.helpertextcontent || _vm.$slots.helpertextcontent)?_c('hp-tooltip',{attrs:{"label":_vm.label,"helpertextcontent":_vm.helpertextcontent},scopedSlots:_vm._u([{key:"helpertextcontent",fn:function(){return [_vm._t("helpertextcontent")]},proxy:true}],null,true)}):_vm._e()],1),_c('q-input',{attrs:{"outlined":"","no-error-icon":"","autofocus":_vm.autofocus,"value":_vm.value,"counter":_vm.counter,"disable":_vm.disabled,"mask":_vm.mask,"maxlength":+_vm.maxlength,"error-message":_vm.validationmessage,"error":!!_vm.validationmessage,"type":_vm.type,"prefix":_vm.innerPrefix,"suffix":_vm.innerSuffix,"dense":_vm.dense,"readonly":_vm.readonly,"placeholder":_vm.placeholder},on:{"input":_vm.updateModel,"focus":_vm.focusEl,"blur":_vm.blurEl,"change":_vm.change,"keydown":function($event){return _vm.$emit('keydown', $event)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('click', _vm.value)}},scopedSlots:_vm._u([(_vm.$slots.prepend || _vm.$scopedSlots.prepend)?{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true}:null,{key:"append",fn:function(){return [(_vm.btn)?_c('q-btn',{attrs:{"round":"","dense":"","flat":"","icon":_vm.btn},on:{"click":function($event){return _vm.$emit('click', _vm.value)}}}):_vm._e(),(_vm.$slots.append || _vm.$scopedSlots.append)?_vm._t("append"):_vm._e()]},proxy:true},(_vm.prefix)?{key:"before",fn:function(){return [_c('strong',[_vm._v(_vm._s(_vm.prefix))])]},proxy:true}:null,(_vm.suffix)?{key:"after",fn:function(){return [_c('strong',[_vm._v(_vm._s(_vm.suffix))])]},proxy:true}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }