<template>
  <div
    :class="{
      'input-disabled': disabled,
      'input-error': validationmessage,
      'input-base hp-input-class': true,
    }"
  >
    <label class="label-container">
      {{ fieldlabel }}
      <hp-tooltip
        :label="label"
        :helpertextcontent="helpertextcontent"
      >
        <template #helpertextcontent>
          <slot name="helpertextcontent" />
        </template>
      </hp-tooltip>
    </label>
    <q-file
      clearable
      counter
      hide-hint
      no-error-icon
      outlined
      :name="name"
      :accept="accept"
      :disable="disabled"
      :error="!!validationmessage"
      :value="value"
      :counter-label="getCounterLabel"
      @blur="onBlur"
      @focus="onFocus"
      @input="onInput"
    >
      <template #error>
        <div class="error">
          {{ validationmessage }}
        </div>
      </template>
      <template #append>
        <q-icon
          name="img:/img/product-catalog/attachment-1.svg"
          right
        />
      </template>
    </q-file>
  </div>
</template>
<script>
import HpTooltip from '../tooltip/Tooltip.vue';

export default {
  name: 'HpFileUpload',
  components: {
    HpTooltip,
  },
  props: {
    name: {
      type: String,
    },
    accept: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    helpertextcontent: {
      type: String,
    },
    label: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    validationmessage: {
      type: String,
    },
    value: {
      type: [FileList, File],
    },
  },
  computed: {
    fieldlabel() {
      return this.required ? `${this.label} *` : this.label;
    },
  },
  methods: {
    onBlur(event) {
      this.$emit('blur', event);
    },
    onFocus(event) {
      this.$emit('focus', event);
    },
    onInput(value) {
      this.$emit('input', value);
    },
    getCounterLabel({ totalSize }) {
      return totalSize;
    },
  },
};
</script>
