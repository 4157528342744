export default {
  routes: [
    {
      path: '/observability/odfe-deployment',
      name: 'odfeDeployment',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/observability/views/odfeDeployment/OdfeDeployment.vue'
      ),
      children: [
        {
          path: '/observability/odfe-deployment/overview',
          name: 'odfeDeploymentOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/observability/tabs/odfeDeploymentOverviewTab/OdfeDeploymentOverviewTab.vue'
          ),
        }, {
          path: '/observability/odfe-deployment/form',
          name: 'odfeDeploymentForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/observability/tabs/odfeDeploymentFormTab/OdfeDeploymentFormTab.vue'
          ),
        },
      ],
    },
    {
      path: '/observability/basic-elastic-deployment',
      name: 'basicElasticDeployment',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/observability/views/basicElasticDeployment/BasicElasticDeployment.vue'
      ),
      children: [{
        path: '/observability/basic-elastic-deployment/overview',
        name: 'basicElasticDeploymentOverview',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/observability/tabs/basicElasticDeploymentOverviewTab/BasicElasticDeploymentOverviewTab.vue'
        ),
      }, {
        path: '/observability/basic-elastic-deployment/form',
        name: 'basicElasticDeploymentForm',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/observability/tabs/basicElasticDeploymentFormTab/BasicElasticDeploymentFormTab.vue'
        ),
      }],
    },
    {
      path: '/observability/enterprise-elastic-deployment',
      name: 'enterpriseElasticDeployment',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/observability/views/enterpriseElasticDeployment/EnterpriseElasticDeployment.vue'
      ),
      children: [{
        path: '/observability/enterprise-elastic-deployment/overview',
        name: 'enterpriseElasticDeploymentOverview',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/observability/tabs/enterpriseElasticDeploymentOverviewTab/EnterpriseElasticDeploymentOverviewTab.vue'
        ),
      }, {
        path: '/observability/enterprise-elastic-deployment/form',
        name: 'enterpriseElasticDeploymentForm',
        component: () => import(
          /* webpackMode: "lazy" */
          '@/modules/observability/tabs/enterpriseElasticDeploymentFormTab/EnterpriseElasticDeploymentFormTab.vue'
        ),
      }],
    },
  ],
};
