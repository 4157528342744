import { apiClient } from '@/modules/core/services/api';
import formSubmitErrorFactory from '@/modules/core/services/formSubmitErrorFactory';

export const read = async url => {
  try {
    if (!url.startsWith('/')) url = `/${url}`;
    return await apiClient.get(url).then(
      data => (Object.prototype.hasOwnProperty.call(data.data, 'data') ? data.data.data : data.data),
    );
  } catch (error) {
    throw error && error.response ? error.response.data : error;
  }
};

export const write = async (payload, url) => {
  try {
    return await apiClient.post(url, payload);
  } catch (error) {
    throw formSubmitErrorFactory.getError(error);
  }
};
