<template>
  <div class="container">
    <h2 class="heading__primary q-pb-3">
      Page not found
    </h2>

    <hp-button
      outlined
      label="Get started"
      leading-icon="home"
      :route="{path: '/'}"
    />
  </div>
</template>

<script>
import HpButton from '@/componentLibrary/button/Button.vue';

export default {
  name: 'PageNotFound',
  components: {
    HpButton,
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80vw;
  height: 70vh;
}

.heading__primary {
  color: $color-accent;
}
</style>
