<template>
  <q-drawer
    v-model="drawer"
    behavior="desktop"
    :width="260"
    :mini-width="37"
    :mini="isMiniDrawer"
    :mini-to-overlay="miniMode"
    @mouseover="miniState = false"
    @mouseleave="onMouseLeave"
  >
    <div class="menu-container">
      <div class="main-menu">
        <div
          v-if="$q.platform.is.mobile"
          class="q-mt-0_5"
        >
          <q-item
            :to="{ name: 'my-tasks' }"
            :class="{'activity-selected': 'my-tasks' === $route.name,
                     'text-secondary' : 'my-tasks' !== $route.name,
            }"
          >
            <hp-avatar :data="tasks" />
            My Tasks
          </q-item>
          <q-item
            :to="{ name: 'my-requests' }"
            :class="{'activity-selected': 'my-requests' === $route.name,
                     'text-secondary' : 'my-requests' !== $route.name,
            }"
          >
            <hp-avatar :data="requests" />
            My Requests
          </q-item>
          <q-item
            :to="{ name: 'my-drafts' }"
            :class="{'activity-selected': 'my-drafts' === $route.name,
                     'text-secondary' : 'my-drafts' !== $route.name,
            }"
          >
            <hp-avatar :data="drafts" />
            My Drafts
          </q-item>
          <div class="q-mx-0_5">
            <q-separator />
          </div>
        </div>
        <template v-for="product in enabledMenuItems">
          <q-item
            v-if="product.services && product.services.length === 1"
            :key="product.id"
            clickable
            :class="{'menu-selected': isActivePath(product.services[0]) }"
            :to="{ name: product.services[0].routeName }"
          >
            <img
              :src="getGraphicSrc(product.graphic)"
              :alt="product.name"
              class="product-image"
            >
            <q-item-section>
              <h4 class="text-subtitle1 menu-header q-pl-0_5">
                {{ product.menuTitle || product.name }}
              </h4>
            </q-item-section>
          </q-item>

          <q-item
            v-else-if="!product.services || product.directLink"
            :key="product.id"
            clickable
            :class="{'menu-selected': isActiveProduct(product)}"
            :to="{ name: product.routeName, params: { prodId: product.id } }"
          >
            <img
              :src="getGraphicSrc(product.graphic)"
              :alt="product.name"
              class="product-image"
            >
            <q-item-section>
              <h4 class="text-subtitle1 menu-header q-pl-0_5">
                {{ product.menuTitle || product.name }}
              </h4>
            </q-item-section>
          </q-item>
          <q-expansion-item
            v-else
            :key="product.id"
            :expand-icon-class="{'hide-expand-icon': isMiniDrawer}"
            :class="{'menu-selected': isActiveProduct(product)}"
          >
            <template #header>
              <img
                :src="getGraphicSrc(product.graphic)"
                :alt="product.name"
                class="product-image"
              >
              <q-item-section>
                <h4 class="text-subtitle1 menu-header q-pl-0_5">
                  {{ product.menuTitle || product.name }}
                </h4>
              </q-item-section>
            </template>
            <q-item-section>
              <sub-menu :menu-items="product.services" />
            </q-item-section>
          </q-expansion-item>
        </template>
        <div class="q-mx-0_5">
          <q-separator class="q-my-0_5" />
        </div>
        <q-item
          clickable
          @click="showFeedback = true"
        >
          <img
            src="/img/product-catalog/feedback.svg"
            alt="Feedback"
            class="product-image"
          >
          <q-item-section class="q-ml-0_5 q-mb-0_25">
            <h4 class="text-subtitle1 menu-header">
              Feedback
            </h4>
          </q-item-section>
          <feedback v-model="showFeedback" />
        </q-item>
        <q-item
          clickable
          :class="{'menu-selected': isActivePath({ routeName : 'Changelog' })}"
          :to="{ name: 'Changelog' }"
        >
          <img
            src="/img/product-catalog/changelog.svg"
            alt="Changelog"
            class="product-image"
          >
          <q-item-section>
            <h4 class="text-subtitle1 menu-header q-pl-0_5">
              Changelog
            </h4>
          </q-item-section>
        </q-item>
        <q-item
          clickable
          :class="{'menu-selected': isActivePath({ routeName : 'ApiDocumentation' })}"
          :to="{ name: 'ApiDocumentation' }"
        >
          <img
            src="/img/product-catalog/api-documentation.svg"
            alt="ApiDocumentation"
            class="product-image"
          >
          <q-item-section>
            <h4 class="text-subtitle1 menu-header q-pl-0_5">
              Hosting Portal API
            </h4>
          </q-item-section>
        </q-item>
      </div>
      <a
        v-if="!$q.platform.is.mobile"
        :class="[
          'nav-toggle rounded-borders q-mr-0_25 q-px-0_5',
          { transform180: miniMode }
        ]"
        @click="miniMode = !miniMode"
      >
        <img
          src="/img/icons/arrow_left.svg"
          alt="Toggle menu"
        >
      </a>
    </div>
  </q-drawer>
</template>

<script>
import menu from '../../constants/catalog';
import SubMenu from '@/modules/core/components/subMenu/SubMenu.vue';
import { getFeatureFlagsStore, notification } from '@/modules/core/mixins';
import { getActivitiesCount } from '@/modules/core/services/taskService';
import { getDraftCount } from '@/modules/core/services/draftService';
import Feedback from '../feedback/Feedback.vue';
import HpAvatar from '@/componentLibrary/avatar/Avatar.vue';

export default {
  name: 'MainMenu',
  components: {
    SubMenu,
    Feedback,
    HpAvatar,
  },
  mixins: [notification, getFeatureFlagsStore],
  props: {
    value: {
      type: Boolean,
    },
  },
  data() {
    return {
      menuItems: [],
      miniMode: false,
      miniState: false,
      drawer: !this.$q.platform.is.mobile,
      tasks: 0,
      requests: 0,
      drafts: 0,
      showFeedback: false,
    };
  },
  computed: {
    enabledMenuItems() {
      return this.menuItems.filter(menuItem => this.getFeatureFlagByRouteName(menuItem.routeName));
    },
    isMiniDrawer() {
      return this.miniState && this.miniMode;
    },
  },
  watch: {
    $route() {
      if (!this.$q.platform.is.mobile) return;
      this.drawer = false;
      this.$emit('input', false);
    },
    value(newVal) {
      if (!this.$q.platform.is.mobile) return;
      this.drawer = newVal;
    },
  },
  async created() {
    this.menuItems = menu;
    if (this.$q.platform.is.mobile) {
      try {
        const [activities, draftCount] = await Promise.allSettled([getActivitiesCount(), getDraftCount()]);
        this.tasks = activities.status === 'fulfilled' ? activities.value.tasks : 0;
        this.requests = activities.status === 'fulfilled' ? activities.value.requests : 0;
        this.drafts = draftCount.status === 'fulfilled' ? draftCount.value : 0;
      } catch (e) {
        // Kept empty to silently suppress it.
      }
    }
  },
  methods: {
    isActiveProduct(product) {
      return product.services ? product.services.some(this.isActivePath) : this.isActivePath(product);
    },
    isActivePath({ routeName }) {
      return routeName === this.$route.name;
    },
    getGraphicSrc(imageName) {
      return `/img/product-catalog/small/${imageName}.svg`;
    },
    onMouseLeave() {
      if (this.miniMode) this.miniState = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-container {
  height: var(--full-height-minus-header);
  border-right: 1px solid $color-surface;
}
.main-menu {
  height: calc(100% - 30px);
  overflow-x: hidden;
  /* scrollbar */
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background: $color-surface;
  }
  &::-webkit-scrollbar-thumb {
    background: $color-surface-dark;
  }
  .menu-header {
    color: $color-secondary;
    font-weight: 500;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .menu-selected {
    background-color: $color-surface;
  }

  :deep(.hide-expand-icon) {
    display: none;
  }

  :deep(.q-item) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.product-image {
    width: 1.25rem;
    margin-top: -#{$hp-spacing-0_25};
    filter: invert(30%) sepia(10%) saturate(856%) hue-rotate(7deg) brightness(89%) contrast(84%);
  }
.nav-toggle {
  cursor: pointer;
  float: right;
  &:hover {
    background-color: $color-surface;
  }
  img {
    width: 0.8rem;
  }
}
.transform180 {
  transform: rotate(180deg);
}

.hide-expand-icon {
  display: none;
}

.activity-selected {
  color: $color-primary;
  font-weight: 500;
}

</style>
