<template>
  <div class="container">
    <h2 class="heading__primary q-pb-3">
      Server Error
    </h2>

    <hp-button
      :outlined="true"
      label="Navigate Home"
      leading-icon="home"
      :route="{ path: '/' }"
    />
  </div>
</template>
<script>
import HpButton from '@/componentLibrary/button/Button.vue';

export default {
  name: 'ServerError',
  components: {
    HpButton,
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80vw;
  height: 70vh;
}

.heading__primary {
  color: $color-accent;
}

</style>
