import { withProjectContextStore } from '../stores/projectContextMixin';

export const contextualizedForm = {
  mixins: [withProjectContextStore],
  computed: {
    original() {
      const original = this.$options.data.call(this).model;
      if (!this.context) return JSON.stringify(original);

      original.businessUnit = this.context.businessUnit;
      original.businessFunction = this.context.businessFunction;
      original.lineOfBusiness = this.context.lineOfBusiness;
      return JSON.stringify(original);
    },
  },
  created() {
    this.model = JSON.parse(this.original);
  },
  methods: {
    addProjectContextToPayload(payload) {
      const withContext = { ...payload };
      if (this.context) withContext.projectSlug = this.context.slug;
      return withContext;
    },
    transformPayload(model) {
      return this.addProjectContextToPayload(model);
    },
  },
};
