<template>
  <div class="flex-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="35"
      height="35"
      viewBox="0 0 26 26"
      class="user-circle"
    >
      <defs>
        <circle
          id="a"
          cx="12"
          cy="12"
          r="12"
        />
      </defs>
      <g
        fill="none"
        fill-rule="evenodd"
        transform="translate(1 1)"
      >
        <mask
          id="b"
          fill="#fff"
        >
          <use xlink:href="#a" />
        </mask>
        <use
          :fill="color"
          stroke="#FFF"
          xlink:href="#a"
        />
        <text
          fill="#FFF"
          font-family="Arial, sans-serif"
          font-size="10"
          letter-spacing="1"
          text-anchor="middle"
          mask="url(#b)"
        >
          <tspan
            x="12"
            y="16"
          >{{ initials }}</tspan>
        </text>
      </g>
    </svg>
  </div>
</template>

<script>
import { userMixin } from '../../mixins';

export default {
  name: 'UserInitials',
  mixins: [userMixin],
  computed: {
    colors() {
      return ['#40488d', '#e49b13', '#bc1077', '#f36633', '#008a00'];
    },
    color() {
      const mudId = this.userMudId;
      if (!mudId) return '#bc1077';

      let hash = 0;
      for (let i = 0; i < mudId.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        hash = (hash << 5) - hash + mudId.charCodeAt(i);
        // eslint-disable-next-line no-bitwise
        hash &= hash;
      }

      return this.colors[Math.abs(hash % this.colors.length)];
    },
    initials() {
      const { userFirstName: first, userLastName: last } = this;
      if (first || last) return (`${first[0]}${last[0]}`).toUpperCase();
      return '?';
    },
  },
  async created() {
    this.$appInsights.setAuthenticatedUserContext(this.userMudId);

    if (process.env.VUE_APP_MICROSOFT_CLARITY_PROJECT_ID) {
      window.clarity('identify', this.userMudId);
    }
  },
};
</script>

<style lang="scss" scoped>
.user-circle {
  cursor: pointer;
  user-select: none;
}
</style>
