export default {
  breadcrumbParent: 'home',
  breadcrumbTitle: 'My Projects',
  desc: '',
  graphic: 'my-projects',
  id: 'myProjectsOverview',
  name: 'My Projects',
  path: '/my-projects/overview',
  routeName: 'myProjectsOverview',
  directLink: true,
  services: [
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'My Projects',
      id: 'myProjectsOverview',
      path: '/my-projects/overview',
      routeName: 'myProjectsOverview',
    },
    {
      breadcrumbParent: 'myProjectsOverview',
      breadcrumbTitle: 'Create',
      id: 'myProjectsForm',
      path: '/my-projects/form',
      routeName: 'myProjectsForm',
    },
  ],
};
