var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'input-disabled': _vm.disabled,
    'input-label': !_vm.disabled,
    'input-base': true,
    'hp-input-class': true,
    'hp-checkbox': true,
  }},[_c('q-checkbox',{attrs:{"value":_vm.value || _vm.falseValue,"disable":_vm.disabled,"true-value":_vm.trueValue,"false-value":_vm.falseValue,"label":_vm.label},on:{"input":_vm.updateModel},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._t("label")]},proxy:true}],null,true)}),(_vm.helpertextcontent)?_c('div',{staticClass:"hint-text"},[_vm._v(" "+_vm._s(_vm.helpertextcontent)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }