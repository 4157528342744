export default {
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/home/views/homePage/HomePage.vue'
      ),
    },
    {
      path: '/all-products',
      name: 'allProducts',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/home/views/allProducts/AllProducts.vue'
      ),
    },
    {
      path: '/dashboard',
      name: 'homeDashboard',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/home/views/dashboard/Dashboard.vue'
      ),
    },
  ],
};
