<template>
  <div>
    <hp-feedback
      v-if="value"
      @submit="submit"
      @cancel="() => $emit('input', false)"
    />
  </div>
</template>

<script>
import HpFeedback from '@/componentLibrary/feedback/Feedback.vue';
import { write } from '../../services/crudService';
import { notification } from '../../mixins';

export default {
  name: 'Feedback',
  components: {
    HpFeedback,
  },
  mixins: [notification],
  props: {
    value: {
      type: Boolean,
    },
  },
  methods: {
    async submit(model) {
      const payload = {
        ...model,
        url: window ? window.location.href : 'unit test',
      };

      try {
        await write(payload, 'feedback');
        this.notify('Thank you for your feedback', true);
      } catch (e) {
        this.notify('An error occurred submitting your feedback');
      } finally {
        this.$emit('input', false);
      }
    },
  },
};
</script>
