<template>
  <div class="container">
    <h2 class="text-accent q-pb-3">
      Forbidden
    </h2>

    <hp-button
      outlined
      label="Navigate Home"
      leading-icon="home"
      :route="{ name: 'home' }"
    />
  </div>
</template>

<script>
import HpButton from '@/componentLibrary/button/Button.vue';

export default {
  name: 'Forbidden',
  components: {
    HpButton,
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 80vw;
  height: 70vh;
}
</style>
