<template>
  <q-avatar
    color="primary"
    size="1.25rem"
    text-color="white"
    class="q-mr-0_5"
  >
    {{ data }}
  </q-avatar>
</template>
<script>
export default {
  name: 'HpAvatar',
  props: {
    data: {
      type: Number,
    },
  },
};
</script>
