<template>
  <hp-select
    required
    label="Virtual Machine Specification"
    :disabled="disabled"
    :options="options"
    :options-url="optionsUrl"
    :value="value"
    :validationmessage="validationmessage"
    :fill-input="fillInput"
    @input="$emit('input', $event)"
    @error="$emit('error', $event)"
    @update-display-object="emitLabel($event)"
  >
    <template #option="scope">
      <slot
        v-bind="scope"
        :name="`option`"
      >
        <q-item
          v-bind="scope.itemProps"
          v-on="scope.itemEvents"
        >
          <q-item-section>
            <q-item-label class="text-weight-bold">
              {{ scope.opt.label }}
            </q-item-label>
            <q-item-label
              v-if="scope.opt.cpu && scope.opt.memory && scope.opt.cost"
              class="q-pt-0_25 configuration"
              caption
            >
              <div>
                <hp-icon
                  image="/img/product-catalog/computer-chip.svg"
                  class="q-pb-0_25"
                />
                {{ scope.opt.cpu }}
              </div>
              <div>
                <hp-icon
                  image="/img/product-catalog/computer-ram.svg"
                  class="q-pb-0_25"
                />
                {{ scope.opt.memory }}
              </div>
              <div>
                <hp-icon
                  image="/img/product-catalog/discount-dollar-dash.svg"
                  class="q-pb-0_25"
                />
                {{ scope.opt.cost }}
              </div>
            </q-item-label>
            <q-item-label
              v-if="scope.opt.value === recommendedSize"
              caption
            >
              Recommended
            </q-item-label>
          </q-item-section>
        </q-item>
      </slot>
    </template>
  </hp-select>
</template>

<script>
import HpSelect from '@/componentLibrary/select/Select.vue';
import HpIcon from '@/componentLibrary/icon/Icon.vue';

export default {
  name: 'HpVmSelect',
  components: {
    HpSelect,
    HpIcon,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    options: {
      type: Array,
    },
    optionsUrl: {
      type: String,
    },
    helpertextcontent: {
      type: String,
    },
    value: {
      type: [Number, String],
    },
    validationmessage: {
      type: String,
    },
    recommendedSize: {
      type: String,
    },
    fillInput: {
      type: Boolean,
    },
  },
  methods: {
    emitLabel(selected) {
      return this.$emit('update-display-value', `${selected.label} (${selected.cpu}, ${selected.memory}, ${selected.cost})`);
    },
  },
};

</script>
<style lang="scss" scoped>
.configuration {
    display: grid;
    grid-template-columns: 80px 80px auto;
}
</style>
