import homeCatalog from '@/modules/core/constants/catalog/home';
import cloudOnboardingCatalog from '@/modules/core/constants/catalog/cloudOnboarding';
import iaasCatalog from '@/modules/core/constants/catalog/iaas';
import caasCatalog from '@/modules/core/constants/catalog/caas';
// import networkPerimeter from '@/modules/core/constants/catalog/networkPerimeter';
import observabilityCatalog from '@/modules/core/constants/catalog/observability';
import paasCatalog from '@/modules/core/constants/catalog/paas';
import terraformModules from './terraformModules';
import consultancy from './consultancy';
import myProjects from './myProjects';
import helpSupport from './helpSupport';
import connectivity from './connectivity';

export default [
  homeCatalog,
  myProjects,
  consultancy,
  cloudOnboardingCatalog,
  iaasCatalog,
  caasCatalog,
  // networkPerimeter,
  paasCatalog,
  connectivity,
  terraformModules,
  observabilityCatalog,
  helpSupport,
];
