import { useFeatureFlagsStore } from '@/modules/core/stores/featureFlags';
import { mapState, mapActions } from 'pinia';

export const getFeatureFlagsStore = {
  computed: {
    ...mapState(useFeatureFlagsStore, ['flags', 'getFeatureFlagByRouteName', 'getFeatureFlagByKey']),
  },
  methods: {
    ...mapActions(useFeatureFlagsStore, ['initFeatureFlags']),
  },
};
