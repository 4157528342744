<template>
  <q-btn
    :class="['hp-button', { 'hp-button--pressed': showPressed }, classes]"
    :dense="dense"
    :disabled="disabled"
    :flat="flat"
    :size="size"
    :color="disabled ? '#fafafa' : color"
    :icon="leadingIcon"
    :icon-right="trailingIcon"
    :label="label"
    :text-color="textColor"
    :outline="disabled ? true : outlined"
    :unelevated="true"
    :ripple="ripple"
    :style="btnStyle"
    :loading="loading"
    :round="round"
    :no-caps="noCaps"
    rounded
    @click="clicked"
  >
    <template #default>
      <slot />
      <q-tooltip
        v-if="showTooltip"
        anchor="top middle"
        self="bottom middle"
        content-class="tooltip-content-class"
        :offset="[0, 5]"
      >
        {{ tooltipText }}
      </q-tooltip>
    </template>
  </q-btn>
</template>
<script>
export default {
  name: 'HpButton',
  props: {
    btnStyle: {
      type: Object,
    },
    classes: {
      type: String,
    },
    color: {
      default: 'primary',
      type: String,
    },
    dense: {
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    flat: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    leadingIcon: {
      required: false,
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    outlined: {
      default: false,
      type: Boolean,
    },
    ripple: {
      default: true,
      type: Boolean,
    },
    route: {
      type: [Object, String],
    },
    round: {
      type: Boolean,
      default: false,
    },
    showPressed: {
      default: false,
      type: Boolean,
    },
    size: {
      type: String,
    },
    textColor: {
      type: String,
    },
    trailingIcon: {
      required: false,
      type: String,
    },
    noCaps: {
      default: false,
      type: Boolean,
    },
    showTooltip: {
      type: Boolean,
    },
    tooltipText: {
      required: false,
      type: String,
    },
  },
  methods: {
    clicked() {
      if (!this.route) {
        this.$emit('click');
        return;
      }
      if (typeof this.route === 'object') {
        if (this.route.url) {
          window.open(this.route.url, '_blank');
          return;
        }

        if (this.$route.name === this.route.name) return;

        this.$router.push(this.route);
        return;
      }
      if (this.route.includes('.')) {
        window.open(this.route, '_blank');
      } else {
        this.$router.push({ name: this.route });
      }
    },
  },
};
</script>
<style lang="scss" scoped>

.hp-button {
  &.hp-button--pressed :deep(.q-focus-helper) {
    background-color: currentColor;
    opacity: 0.15;
  }

  letter-spacing: 0.1rem;
  &.q-btn--outline .q-btn__wrapper:before {
    border-width: 2px;
  }
  .on-left {
    margin-right: 6px;
  }
  .on-right {
    margin-left: 6px;
  }
  &.disabled span {
    color: $color-typography !important;
  }
}
</style>
