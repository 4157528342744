<template>
  <hp-alert
    v-if="success || successMessage"
    type="success"
    :text="successMessage"
  >
    <template v-if="typeof successMessage === 'string' && +successMessage">
      Your request {{ successMessage }} has successfully been submitted. The Business Owner and Technical Owner will be informed of the progress via email notifications. The Business Owner can also track the request through
      <router-link :to="{name: 'my-requests'}">
        My Requests
      </router-link>.
    </template>
    <template v-else-if="typeof successMessage === 'object' && successMessage.incidentNumber">
      Your incident
      {{ successMessage.incidentNumber }}
      has successfully been created. You can check the status using
      <a
        href="https://servicenow.gsk.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        ServiceNow
      </a>
      portal.
    </template>
  </hp-alert>
</template>

<script>
import HpAlert from '@/componentLibrary/alert/Alert.vue';

export default {
  name: 'FormSuccess',
  components: {
    HpAlert,
  },
  props: {
    success: {
      type: Boolean,
    },
    successMessage: {
      type: [String, Object],
    },
  },
};
</script>
