<template>
  <div class="grid-container q-mx-auto q-mb-4">
    <div>
      <h2 class="text-h4 form-top">
        {{ title }}
      </h2>
      <slot name="description">
        <p v-if="description">
          {{ description }}
        </p>
      </slot>
    </div>
    <request-type-context-error
      v-if="!validResourceTypeContext"
      :project-context-name="context.name"
      @reset-context="resetContext"
    />
    <div
      v-else-if="loading"
      class="text-center"
    >
      <q-spinner size="3rem" />
    </div>
    <hp-alert
      v-else-if="showContextualizedAlert"
      heading="Notification"
      type="info"
    >
      Some form fields have been prefilled in context with
      <span class="text-bold">
        {{ context.name }}
      </span>
      .
    </hp-alert>
    <form-success
      :success="success"
      :success-message="successMessage"
    />
    <form-system-error
      v-if="isSystemError"
      :error="error"
    />

    <form-validation-error
      v-if="isValidationError && !success"
      :error="error"
    />

    <component-failed
      v-if="isComponentFailedError"
      :error="error || referenceDataError"
    />

    <slot v-if="showForm" />
  </div>
</template>

<script>
import { SystemErrorModel } from '../models/systemErrorModel';
import { ValidationErrorModel } from '../models/validationErrorModel';
import formSystemError from '@/componentLibrary/errors/formSystemError/formSystemError.vue';
import formValidationError from '@/componentLibrary/errors/formValidationError/formValidationError.vue';
import FormSuccess from '@/componentLibrary/errors/formSuccess/FormSuccess.vue';
import RequestTypeContextError from '@/componentLibrary/errors/requestTypeContextError/RequestTypeContextError.vue';
import { ApmErrorModel } from '@/modules/core/models/apmErrorModel';
import { withProjectContextStore } from '@/modules/core/mixins/stores/projectContextMixin';
import ComponentFailed from '@/componentLibrary/errors/componentFailed/ComponentFailed.vue';
import HpAlert from '@/componentLibrary/alert/Alert.vue';
import referenceDataService from '@/modules/core/services/referenceDataService';

export default {
  name: 'FormPageLayout',
  components: {
    ComponentFailed,
    formValidationError,
    formSystemError,
    FormSuccess,
    HpAlert,
    RequestTypeContextError,
  },
  mixins: [withProjectContextStore],
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    success: {
      type: Boolean,
    },
    contextualized: {
      type: Boolean,
    },
    successMessage: {
      type: [String, Object],
    },
    error: {
      type: [Error, Object],
    },
  },
  data() {
    return {
      attemptsMade: 1,
      validResourceTypeContext: true,
      loading: false,
      referenceDataError: null,
    };
  },
  computed: {
    isSystemError() {
      return this.error && this.error instanceof SystemErrorModel;
    },
    isValidationError() {
      return this.error && this.error instanceof ValidationErrorModel;
    },
    isComponentFailedError() {
      return this.referenceDataError || (this.error && !this.isSystemError && !this.isValidationError);
    },
    showContextualizedAlert() {
      return this.contextualized && this.context && !this.success;
    },
    showForm() {
      return !this.success && !this.isSystemError && !this.isComponentFailedError && this.validResourceTypeContext && !this.loading;
    },
  },
  watch: {
    error() {
      if (!this.error) return;
      this.$appInsights.trackException({
        exception: this.error.originalError,
        properties: new ApmErrorModel(this.error.originalError),
      });
    },
    context() {
      if (this.contextualized) this.$router.go();
    },
    contextualized: {
      async handler() {
        if (!this.context || !this.contextualized) return;
        this.loading = true;
        const { lineOfBusiness, businessUnit, businessFunction } = this.context;
        const { requestType } = this.$route.meta || {};
        try {
          const businessUnits = await referenceDataService.getBusinessUnits(lineOfBusiness, requestType);
          this.validResourceTypeContext = businessUnits.some(el => el.value === businessUnit);
          if (!this.validResourceTypeContext || !businessFunction) {
            this.loading = false;
            return;
          }
          const businessFunctions = await referenceDataService.getBusinessFunctions(lineOfBusiness, businessUnit, requestType);
          this.validResourceTypeContext = businessFunctions.some(el => el.value === businessFunction);
          this.loading = false;
        } catch (e) {
          this.referenceDataError = e;
        }
      },
      immediate: true,
    },
  },
  methods: {
    resetContext() {
      this.context = null;
    },
  },
};
</script>
<style lang="scss">
.grid-container {
  width: 55rem;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 24px;
}
</style>
