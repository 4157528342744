<template>
  <div
    :class="{
      'input-disabled': disabled,
      'input-error': !!validationmessage,
      'input-base': true,
      'hp-input-class': true,
    }"
  >
    <label class="label-container">
      {{ fieldlabel }}
      <hp-tooltip
        :label="schema.label"
        :helpertextcontent="schema.helperText"
      >
        <template #helpertextcontent>
          <slot name="helpertextcontent" />
        </template>
      </hp-tooltip>
    </label>
    <q-select
      no-error-icon
      input-debounce="0"
      :class="classes"
      :disable="disabled || !cascadeValue"
      :value="selectValue"
      :error-message="validationmessage"
      :error="!!validationmessage"
      outlined
      :options="filterOptions"
      popup-content-class="ddl-max-items"
      @filter="filter"
      @input="modelUpdated"
      @focus="focusEl"
      @blur="blurEl"
    >
      <template
        v-if="$scopedSlots['selected-item']"
        #selected-item="scope"
      >
        <slot
          v-bind="scope"
          name="selected-item"
        />
      </template>
      <template
        v-if="$scopedSlots.option"
        #option="scope"
      >
        <slot
          v-if="$scopedSlots.option"
          v-bind="scope"
          name="option"
        />
      </template>
      <template
        v-else-if="showOptionsWithCaptions"
        #option="scope"
      >
        <q-item
          v-bind="scope.itemProps"
          v-on="scope.itemEvents"
        >
          <q-item-section>
            <q-item-label>{{ scope.opt.label }}</q-item-label>
            <q-item-label caption>
              {{ scope.opt.caption }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script>
import HpTooltip from '@/componentLibrary/tooltip/Tooltip.vue';
import { read } from '@/modules/core/services/crudService';

export default {
  name: 'Cascade',
  components: {
    HpTooltip,
  },
  props: {
    autofocus: {
      type: Boolean,
    },
    cascadeValue: {
      type: [String, Number],
    },
    classes: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    validationmessage: {
      type: String,
    },
    schema: {
      type: Object,
      required: true,
    },
    value: {
      type: [Number, String],
    },
  },
  data() {
    return {
      filterOptions: [],
      smartOptions: [],
    };
  },
  computed: {
    fieldlabel() {
      return this.required ? `${this.schema.label} *` : this.schema.label;
    },
    selectValue() {
      if (this.cascadeValue === null) return ''; // allow for 0
      const options = [...((this.schema.options && this.schema.options[this.cascadeValue]) || this.smartOptions)];
      return !options || !options.length ?
        null :
        options.find(f => f.value === this.value);
    },
    showOptionsWithCaptions() {
      return this.filterOptions.some(o => o.caption);
    },
  },
  watch: {
    value() {
      // if clause will only not happen in unit testing
      if (this.$eventBus) this.$eventBus.$emit('controlUpdated', { label: this.label });
    },
    cascadeValue(val) {
      if (this.schema.options) {
        this.filterOptions = this.schema.options[val];
        if (this.filterOptions && this.filterOptions.length === 1) {
          this.modelUpdated(this.filterOptions[0]);
          this.$emit('blur', this.filterOptions[0].value);
        }
      } else this.loadOptions();
    },
  },
  created() {
    if (this.schema.options && this.cascadeValue && this.schema.options[this.cascadeValue].length === 1) {
      this.modelUpdated(this.schema.options[this.cascadeValue][0]);
      if (this.$eventBus) this.$eventBus.$emit('controlUpdated', { label: this.label });
    }

    this.loadOptions();
  },
  methods: {
    focusEl(event) {
      this.$emit('focus', event);
      this.$eventBus.$emit('controlFocus', { label: this.label });
    },
    blurEl() {
      this.$emit('blur', this.value);
    },
    filter(val, update) {
      const opts = [...((this.schema.options && this.schema.options[this.cascadeValue]) || this.smartOptions)];

      if (val === '') {
        update(() => {
          this.filterOptions = opts;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.filterOptions = opts.filter(v => v.label.toLowerCase().indexOf(needle) > -1);
      });
    },
    modelUpdated(event) {
      this.$emit('update-display-value', event && event.label);
      this.$emit('input', event && event.value);
    },
    async loadOptions() {
      const oUrl = this.schema.optionsUrl;
      if (this.schema.options || !oUrl || !this.cascadeValue) return null;
      const baseUrl = oUrl.endsWith('/') ? oUrl : `${oUrl}/`;

      try {
        this.smartOptions = await read(`${baseUrl}${this.cascadeValue}`);
        if (this.smartOptions.length !== 1) return;
        this.modelUpdated(this.smartOptions[0]);
        this.$emit('blur', this.smartOptions[0].value);
      } catch (error) {
        this.$emit('error', error);
      }
    },
  },
};
</script>
<style lang="scss">
  .ddl-max-items {
    max-height: 14.5rem !important;
  }
</style>
