const tooltipBusinessUnitRD = `
<div>
  Please select the Business Unit that corresponds to the Reporting Line of the Business Owner of the Product/Application that will be hosted in the Resource Group/Project/Resource that you are requesting. As an example, if the Business Owner is reporting indirectly to Christopher Corsico you should select the Development Business Unit.
</div>
<div class="q-mt-0_5">
  Business Unit / Business Owner Reporting Line Mapping:
  <ul>
    <li>
      Development - Christopher Corsico
    </li>
    <li>
      Research Tech - Christopher Austin
    </li>
    <li>
      Portfolio and Strategy - Gregory Reinaud
    </li>
    <li>
      Respiratory and Immunology - Kaivan Khavandi
    </li>
    <li>
      AIML - Kim Branson
    </li>
    <li>
      Medicine Development and Supply - Narendra Bam
    </li>
    <li>
      Vaccines and Infectious Diseases - Philip Dormitzer
    </li>
    <li>
      Medical, Regulatory and Quality - Sabine Luik
    </li>
    <li>
      Oncology - Hesham Abdullah
    </li>
    <li>
      Finance - Demet Russ
    </li>
    <li>
      R&D Digital & Tech - Shubha Chaudhari
    </li>
  </ul>
</div>
<div>
  Please note that the "R&D Digital & Tech" Business Unit should only be selected when there is a specific business justification for that. The Business Owner should not be in the R&D Tech Reporting Line
</div>
`;

export {
  tooltipBusinessUnitRD,
};
