export default {
  breadcrumbParent: 'home',
  breadcrumbTitle: 'Terraform Modules',
  desc: 'Terraform Modules for PaaS Solutions',
  graphic: 'terraform',
  id: 'terraformModules',
  menuTitle: 'Terraform Modules',
  path: '/terraform-modules/catalog',
  routeName: 'terraformModulesCatalog',
  directLink: true,
};
