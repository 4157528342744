export default {
  breadcrumbParent: 'home',
  breadcrumbTitle: 'Connectivity',
  graphic: 'connectivity',
  id: 'connectivity',
  menuTitle: 'Connectivity',
  name: 'Connectivity',
  path: '/connectivity',
  routeName: 'connectivity',
  services: [{
    breadcrumbParent: 'home',
    breadcrumbTitle: 'Connectivity',
    graphic: 'connectivity',
    id: 'connectivityOverview',
    menuTitle: 'Connectivity',
    name: 'Connectivity',
    path: '/connectivity/overview',
    routeName: 'connectivityOverview',
  }],
};
