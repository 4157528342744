export default {
  routes: [
    {
      path: '/cloud-onboarding/azure-resource-groups',
      name: 'azure-resource-groups',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/cloudOnboarding/views/resourceGroup/ResourceGroupPage.vue'
      ),
      children: [
        {
          path: '/cloud-onboarding/azure-resource-groups/overview',
          name: 'azureResourceGroupsOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/cloudOnboarding/tabs/resourceGroupOverviewTab/ResourceGroupOverviewTab.vue'
          ),
        },
        {
          path: '/cloud-onboarding/azure-resource-groups/form',
          name: 'azureResourceGroupsForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/cloudOnboarding/tabs/resourceGroupFormTab/ResourceGroupFormTab.vue'
          ),
          meta: {
            requestType: 'AZURE_RESOURCE_GROUP',
            requestName: 'Azure Resource Group',
          },
        },
        {
          path: '/cloud-onboarding/azure-resource-groups/dashboard',
          name: 'azureResourceGroupsDashboard',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/cloudOnboarding/tabs/resourceGroupDashboardTab/ResourceGroupDashboardTab.vue'
          ),
        },

        {
          path: '/cloud-onboarding/azure-resource-groups/faq',
          name: 'azureResourceGroupsFaq',
          component: () => import(
            '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
          ),
        },
      ],
    },
    {
      path: '/cloud-onboarding/gcp-projects',
      name: 'gcp-projects',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/cloudOnboarding/views/gcpProject/GCPProjectView.vue'
      ),
      children: [
        {
          path: '/cloud-onboarding/gcp-projects/overview',
          name: 'gcpProjectsOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/cloudOnboarding/tabs/gcpOverviewTab/GcpOverviewTab.vue'
          ),
        },
        {
          path: '/cloud-onboarding/gcp-projects/form',
          name: 'gcpProjectsForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/cloudOnboarding/tabs/gcpFormTab/GcpFormTab.vue'
          ),
          meta: {
            requestType: 'GCP_PROJECT',
            requestName: 'Google Cloud Platform',
          },
        },
        {
          path: '/cloud-onboarding/gcp-projects/dashboard',
          name: 'gcpProjectsDashboard',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/cloudOnboarding/tabs/gcpDashboardTab/GcpDashboardTab.vue'
          ),
        },
        {
          path: '/cloud-onboarding/gcp-projects/faq',
          name: 'gcpProjectsFaq',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
          ),
        },
      ],
    },
  ],
};
