<template>
  <div>
    <validation-provider
      v-slot="{ errors }"
      mode="eager"
      :name="name"
      :rules="{ min_value: 1 }"
    >
      <div :class="{'hp-input-class': true, 'column': true, 'input-error': !!errors[0] }">
        <label class="label-container">
          {{ displayLabel }}
        </label>

        <input
          :value="value"
          type="hidden"
        >

        <q-rating
          :value="value"
          max="5"
          size="3.5em"
          color="primary"
          @input="$emit('input', $event)"
        />

        <div
          v-if=" errors[0]"
          :class="{'hp-input-class': true, 'input-error': !!errors[0] }"
        >
          <label class="label-container">
            This field is required
          </label>
        </div>
      </div>

      <!-- this is what vee validate is watching -->
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'HpRating',
  components: {
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    value: {
      type: Number,
      required: false,
    },
  },
  computed: {
    displayLabel() {
      return this.required ? `${this.label} *` : this.label;
    },
  },
};
</script>
