import { ValidationErrorModel } from '@/modules/core/models/validationErrorModel';
import { SystemErrorModel } from '@/modules/core/models/systemErrorModel';

const getError = e => {
  if (e.response && [400, 422].includes(e.response.status)) {
    return new ValidationErrorModel(e);
  }
  return new SystemErrorModel(e);
};

export default {
  getError,
};
