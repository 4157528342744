export default {
  routes: [
    {
      path: '/help-support/general-help',
      name: 'generalHelp',
      component: () => import(
        '@/modules/helpSupport/views/generalHelp/GeneralHelp.vue'
      ),
    },
  ],
};
