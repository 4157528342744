export default {
  breadcrumbParent: 'home',
  breadcrumbTitle: 'PaaS Solutions',
  desc: 'Provision hosting resources quickly and securely through infrastructure as code.',
  graphic: 'cloud_flash',
  id: 'paas',
  menuTitle: 'PaaS Solutions',
  name: 'PaaS Solutions',
  path: '/paas',
  routeName: 'paas',
  services: [
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Azure SQL',
      desc: 'Request an Azure SQL instance and database.',
      graphic: 'cloud_flash',
      id: 'azureSqlOverview',
      menuTitle: 'Azure SQL',
      name: 'Azure SQL',
      path: '/paas/azure-sql/overview',
      routeName: 'azureSqlOverview',
    },
    {
      breadcrumbParent: 'azureSqlOverview',
      breadcrumbTitle: 'Create',
      id: 'azureSqlForm',
      path: '/paas/azure-sql/form',
      routeName: 'azureSqlForm',
    },
    {
      breadcrumbParent: 'azureSqlOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'paasAzureSqlDashboard',
      path: '/paas/azure-sql/dashboard',
      routeName: 'paasAzureSqlDashboard',
    },
    {
      breadcrumbParent: 'azureSqlOverview',
      breadcrumbTitle: 'FAQ',
      id: 'azureSqlFAQ',
      path: '/paas/azure-sql/faq',
      routeName: 'azureSqlFAQ',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Azure SQL Managed Instance',
      desc: 'Request an Azure SQL Managed instance and database.',
      graphic: 'cloud_flash',
      id: 'azureSqlManagedInstanceOverview',
      menuTitle: 'Azure SQL Managed Instance',
      name: 'Azure SQL Managed Instance',
      path: '/paas/azure-sql-managed-instance/overview',
      routeName: 'azureSqlManagedInstanceOverview',
    },
    {
      breadcrumbParent: 'azureSqlManagedInstanceOverview',
      breadcrumbTitle: 'Create',
      id: 'azureSqlManagedInstanceForm',
      path: '/paas/azure-sql-managed-instance/form',
      routeName: 'azureSqlManagedInstanceForm',
    },
    {
      breadcrumbParent: 'azureSqlManagedInstanceOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'azureSqlManagedInstanceDashboard',
      path: '/paas/azure-sql-managed-instance/dashboard',
      routeName: 'azureSqlManagedInstanceDashboard',
    },
    {
      breadcrumbParent: 'azureSqlManagedInstanceOverview',
      breadcrumbTitle: 'FAQ',
      id: 'azureSqlManagedInstanceFaq',
      path: '/paas/azure-sql-managed-instance/faq',
      routeName: 'azureSqlManagedInstanceFaq',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Azure PostgreSQL',
      desc: 'Request an Azure PostgreSQL instance and database',
      graphic: 'cloud_flash',
      id: 'paasAzurePostgreSqlOverview',
      menuTitle: 'Azure PostgreSQL',
      name: 'Azure PostgreSQL',
      path: '/paas/azure-postgresql/overview',
      routeName: 'paasAzurePostgreSqlOverview',
    },
    {
      breadcrumbParent: 'paasAzurePostgreSqlOverview',
      breadcrumbTitle: 'Create',
      id: 'paasAzurePostgreSqlForm',
      path: '/paas/azure-postgresql/form',
      routeName: 'paasAzurePostgreSqlForm',
    },
    {
      breadcrumbParent: 'paasAzurePostgreSqlOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'paasAzurePostgreSqlDashboard',
      path: '/paas/azure-postgresql/dashboard',
      routeName: 'paasAzurePostgreSqlDashboard',
    },
    {
      breadcrumbParent: 'paasAzurePostgreSqlOverview',
      breadcrumbTitle: 'FAQ',
      id: 'paasAzurePostgreSqlFaq',
      path: '/paas/azure-postgresql/faq',
      routeName: 'paasAzurePostgreSqlFaq',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Azure Internal Load Balancer',
      desc:
        'This self-service enables you to request an Azure Internal Load Balancer on the Azure infrastructure (IaaS). The virtual machines are managed and supported by Core Tech Enterprise Hosting Services.',
      graphic: 'cloud_server',
      id: 'azureLoadBalancerOverview',
      menuTitle: 'Azure Internal Load Balancer',
      name: 'Azure Internal Load Balancer',
      path: '/paas/azure-load-balancer/overview',
      routeName: 'azureLoadBalancerOverview',
    },
    {
      breadcrumbParent: 'azureLoadBalancerOverview',
      breadcrumbTitle: 'Create',
      id: 'azureLoadBalancerForm',
      path: '/paas/azure-load-balancer/form',
      routeName: 'azureLoadBalancerForm',
    },
    {
      breadcrumbParent: 'azureLoadBalancerOverview',
      breadcrumbTitle: 'Dashboard',
      id: 'azureLoadBalancerDashboard',
      path: '/paas/azure-load-balancer/dashboard',
      routeName: 'azureLoadBalancerDashboard',
    },
    {
      breadcrumbParent: 'azureLoadBalancerOverview',
      breadcrumbTitle: 'FAQ',
      id: 'azureLoadBalancerFaq',
      path: '/paas/azure-load-balancer/faq',
      routeName: 'azureLoadBalancerFaq',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Azure App Service',
      desc: 'Request an azure app service.',
      graphic: 'cloud_flash',
      id: 'azureAppServiceOverview',
      menuTitle: 'Azure App Service',
      name: 'Azure App Service',
      path: '/paas/azure-app-service/overview',
      routeName: 'azureAppServiceOverview',
    },
    {
      breadcrumbParent: 'azureAppServiceOverview',
      breadcrumbTitle: 'FAQ',
      id: 'azureAppServiceFaq',
      path: '/paas/azure-app-service/faq',
      routeName: 'azureAppServiceFaq',
    },
  ],
};
