export default {
  id: 'observability',
  name: 'Observability',
  path: '/observability',
  routeName: 'observability',
  desc: `The Observability Platform is built on an ElasticSearch foundation.
  The platform enables you to collect log and metric data from your infrastructure,
  application or service and to visualize this data through a web-based front end which can be shared with others.`,
  graphic: 'monitor_graph',
  breadcrumbParent: 'home', // name of parent bread crumb
  breadcrumbTitle: 'Observability',
  menuTitle: 'Observability',
  services: [
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Basic Elastic Deployment',
      desc: 'Request a new open source Basic Elasticsearch deployment.  Ideal for small, medium, and large data size requirements where Enterprise Elasticsearch features are not required.',
      graphic: 'monitor_graph',
      id: 'basicElasticDeploymentOverview',
      menuTitle: 'Basic Elastic Deployment',
      name: 'Basic Elastic Deployment',
      path: '/observability/basic-elastic-deployment/overview',
      routeName: 'basicElasticDeploymentOverview',
    },
    {
      breadcrumbParent: 'basicElasticDeploymentOverview',
      breadcrumbTitle: 'Create',
      id: 'basicElasticDeploymentForm',
      path: '/observability/basic-elastic-deployment/form',
      routeName: 'basicElasticDeploymentForm',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'Enterprise Elastic Deployment',
      desc: 'Provision a new full service Enterprise Elasticsearch deployment with all the features and capabilities of Enterprise Elasticsearch such as Machine Learning and Single Sign-On.  Ideal for small to medium data size requirements.',
      graphic: 'monitor_graph',
      id: 'enterpriseElasticDeploymentOverview',
      menuTitle: 'Enterprise Elastic Deployment',
      name: 'Enterprise Elastic Deployment',
      path: '/observability/enterprise-elastic-deployment/overview',
      routeName: 'enterpriseElasticDeploymentOverview',
    },
    {
      breadcrumbParent: 'enterpriseElasticDeploymentOverview',
      breadcrumbTitle: 'Create',
      id: 'enterpriseElasticDeploymentForm',
      path: '/observability/enterprise-elastic-deployment/form',
      routeName: 'enterpriseElasticDeploymentForm',
    },
    {
      breadcrumbParent: 'home',
      breadcrumbTitle: 'OpenSearch Deployment',
      desc: 'Provision a new open source OpenSearch for Elasticsearch cluster spun up in AKS. Ideal for small and medium size deployments where enterprise Elasticsearch features are not required.  Note OpenSearch is currently not supported, therefore this option may not be suitable for production environments.',
      graphic: 'monitor_graph',
      id: 'odfeDeploymentOverview',
      menuTitle: 'OpenSearch Deployment',
      name: 'OpenSearch Deployment',
      path: '/observability/odfe-deployment/overview',
      routeName: 'odfeDeploymentOverview',
    },
    {
      breadcrumbParent: 'odfeDeploymentOverview',
      breadcrumbTitle: 'Create',
      id: 'odfeElasticDeploymentForm',
      path: '/observability/odfe-deployment/form',
      routeName: 'odfeElasticDeploymentForm',
    },
  ],
};
