export default {
  routes: [
    {
      path: '/my-activities',
      name: 'myActivities',
      redirect: '/my-activities/my-tasks',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/myActivities/views/myActivitiesView/MyActivitiesView.vue'
      ),
      children: [
        {
          path: '/my-activities/my-tasks',
          name: 'my-tasks',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/myActivities/views/Activities/myTasks/MyTasks.vue'
          ),
        },
        {
          path: '/my-activities/my-requests',
          name: 'my-requests',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/myActivities/views/Activities/myRequests/MyRequests.vue'
          ),
        },
        {
          path: '/my-activities/my-drafts',
          name: 'my-drafts',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/myActivities/views/Activities/myDrafts/MyDrafts.vue'
          ),
        },
      ],
    },
    {
      path: '/search',
      name: 'search',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/myActivities/views/requestSearch/RequestSearch.vue'
      ),
    },
  ],
};
