<template>
  <q-dialog
    :value="value"
    @hide="$emit('cancel')"
  >
    <q-card
      :class="{
        'hp-dialog': true,
        'medium': !large,
        'large': large,
        'full-height': large}"
    >
      <q-card-section class="row justify-between">
        <div class="text-h6">
          {{ title }}
        </div>
        <q-btn
          v-if="closeButton"
          icon="close"
          flat
          round
          dense
          @click="$emit('close')"
        />
      </q-card-section>
      <q-separator />
      <q-card-section>
        <form-system-error
          v-if="error"
          :error="error"
        />
        <slot />
      </q-card-section>
      <q-card-actions
        v-if="!hideActions"
        align="right"
      >
        <hp-button
          v-if="!hideCancel"
          v-close-popup
          data-test-id="cancel"
          outlined
          :label="cancelLabel"
        />
        <hp-button
          :disabled="okDisabled"
          :loading="processing"
          :label="acceptLabel"
          data-test-id="accept"
          @click="$emit('accept')"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import HpButton from '@/componentLibrary/button/Button.vue';
import FormSystemError from '@/componentLibrary/errors/formSystemError/formSystemError.vue';

export default {
  name: 'HpDialog',
  components: {
    HpButton,
    FormSystemError,
  },
  props: {
    acceptLabel: {
      default: 'Confirm',
      type: String,
    },
    cancelLabel: {
      default: 'Cancel',
      type: String,
    },
    closeButton: {
      type: Boolean,
    },
    hideActions: {
      type: Boolean,
    },
    hideCancel: {
      type: Boolean,
    },
    okDisabled: {
      type: Boolean,
    },
    title: {
      default: 'Confirm',
      type: String,
    },
    value: {
      type: Boolean,
      default: true,
    },
    large: {
      type: Boolean,
    },
    error: {
      type: [Object, String, Error],
    },
    processing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alert: true,
    };
  },
};
</script>
<style lang="scss">
.hp-dialog {
  .text-h6 {
    font-weight: 500;
  }
  .q-card__actions .q-btn__wrapper{
    padding: 0.5rem 1.25rem;
  }
  .q-card__actions {
    padding: 1rem 1rem 1.5rem;
  }
}

.medium {
  width: 30rem;
}
.large {
    min-width: 80vw;
}

</style>
