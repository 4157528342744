export const orgSelection = {
  computed: {
    orgSelectionModel: {
      get() {
        const { businessUnit, businessFunction, lineOfBusiness } = this.model;

        return {
          businessUnit,
          businessFunction,
          lineOfBusiness,
        };
      },
      set(model) {
        this.model = { ...this.model, ...model };
      },
    },
  },
};
