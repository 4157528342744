import { ValidationObserver, ValidationProvider } from 'vee-validate';
import HpButton from '@/componentLibrary/button/Button.vue';
import HpCheckbox from '@/componentLibrary/checkbox/Checkbox.vue';
import HpDialog from '@/componentLibrary/dialog/Dialog.vue';
import HpAlert from '@/componentLibrary/alert/Alert.vue';
import GridCell from '@/componentLibrary/grid/GridCell.vue';
import Grid from '@/componentLibrary/grid/Grid.vue';
import '@/modules/core/validators/formValidators';
import HpInput from '@/componentLibrary/input/Input.vue';
import HpSelect from '@/componentLibrary/select/Select.vue';
import HpPeoplePicker from '@/modules/core/components/peoplePicker/PeoplePicker.vue';
import HpOptionGroup from '@/componentLibrary/optionGroup/OptionGroup.vue';
import OwnerSelector from '@/modules/core/components/ownerSelector/OwnerSelector.vue';
import HpSubmitForm from '@/componentLibrary/submitForm/SubmitForm.vue';
import OrgSelector from '@/modules/core/components/orgSelector/OrgSelector.vue';
import EnvironmentSelector from '@/componentLibrary/environmentSelector/EnvironmentSelector.vue';
import HpStep from '@/componentLibrary/step/Step.vue';
import Field from '@/modules/core/components/field/Field.vue';
import HpRating from '@/componentLibrary/rating/Rating.vue';
import HpIcon from '@/componentLibrary/icon/Icon.vue';

export const formSetup = {
  components: {
    EnvironmentSelector,
    Field,
    Grid,
    GridCell,
    HpAlert,
    HpButton,
    HpCheckbox,
    HpDialog,
    HpInput,
    HpOptionGroup,
    HpPeoplePicker,
    HpRating,
    HpSelect,
    HpStep,
    HpSubmitForm,
    OrgSelector,
    OwnerSelector,
    ValidationObserver,
    ValidationProvider,
    HpIcon,
  },
};
