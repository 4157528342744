<template>
  <Grid>
    <grid-cell span="12">
      <validation-provider
        v-slot="{ errors }"
        mode="eager"
        name="primaryUser"
        :rules="{ required: true }"
      >
        <hp-people-picker
          :value="primaryUserModel"
          label="Primary User"
          :disabled="disabledPrimaryUser"
          required
          :helpertextcontent="helpertextcontent"
          :valid="!errors.length"
          :validationmessage="errors[0]"
          @input="$emit('input', [$event, secondaryUserModel])"
          @update-display-value="$emit('update-display-value', { 'Primary User': $event})"
        />
      </validation-provider>
    </grid-cell>

    <grid-cell span="12">
      <validation-provider
        v-slot="{ errors }"
        mode="eager"
        name="secondaryUser"
        :rules="{ required: true, techOwner: primaryUserMudId }"
      >
        <hp-people-picker
          :value="secondaryUserModel"
          label="Secondary User"
          required
          :disabled="disabledSecondaryUser"
          :helpertextcontent="helpertextcontent"
          :valid="!errors.length"
          :validationmessage="errors[0]"
          @input="$emit('input', [primaryUserModel, $event])"
          @update-display-value="$emit('update-display-value', { 'Secondary User': $event})"
        />
      </validation-provider>
    </grid-cell>
  </Grid>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import HpPeoplePicker from '@/modules/core/components/peoplePicker/PeoplePicker.vue';
import GridCell from '@/componentLibrary/grid/GridCell.vue';
import Grid from '@/componentLibrary/grid/Grid.vue';

export default {
  name: 'OwnerSelector',
  components: {
    ValidationProvider,
    HpPeoplePicker,
    Grid,
    GridCell,
  },
  props: {
    disabled: {
      type: [Boolean, Object],
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    helpertextcontent: {
      type: String,
    },
  },
  data() {
    return {
      primaryUser: null,
      secondaryUser: null,
    };
  },
  computed: {
    primaryUserModel() {
      return this.value && this.value.length && this.value.length === 2 ? this.value[0] : this.primaryUser;
    },
    secondaryUserModel() {
      return this.value && this.value.length && this.value.length === 2 ? this.value[1] : this.secondaryUser;
    },
    primaryUserMudId() {
      return this.primaryUserModel && this.primaryUserModel.mudId;
    },
    disabledPrimaryUser() {
      return typeof this.disabled === 'boolean' ? this.disabled : this.disabled.primaryUser;
    },
    disabledSecondaryUser() {
      return typeof this.disabled === 'boolean' ? this.disabled : this.disabled.secondaryUser;
    },
  },
};
</script>
