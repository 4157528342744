export const notification = {
  methods: {
    notify(message, isPositive) {
      const iconTypeMap = {
        negative: 'error_outline',
        positive: 'check_circle_outline',
      };
      const type = isPositive ? 'positive' : 'negative';
      this.$q.notify({
        icon: iconTypeMap[type],
        type,
        message,
        actions: [{ icon: 'close' }],
      });
    },
    success(message) {
      this.notify(message, true);
    },
  },
};
