<template>
  <div
    :class="{
      'input-error': validationmessage,
      'input-disabled': disabled,
      'input-base': true,
      'hp-input-class': true,
    }"
  >
    <fieldset>
      <label v-if="fieldlabel">
        <legend>
          {{ fieldlabel }}
          <hp-tooltip
            :label="label"
            :helpertextcontent="helpertextcontent"
          >
            <template #helpertextcontent>
              <slot name="helpertextcontent" />
            </template>
          </hp-tooltip>
        </legend>
      </label>

      <q-field
        no-error-icon
        :error="!!validationmessage"
        hide-hint
        @blur="onBlur"
        @focus="onFocus"
      >
        <q-option-group
          :disable="disabled"
          :inline="inline"
          :options="options"
          :type="type"
          :value="value"
          @input="onInput"
        />
        <template #error>
          <div class="error">
            {{ validationmessage }}
          </div>
        </template>
      </q-field>
    </fieldset>
  </div>
</template>
<script>
import HpTooltip from '../tooltip/Tooltip.vue';

export default {
  name: 'HpOptionGroup',
  components: {
    HpTooltip,
  },
  props: {
    disabled: {
      type: Boolean,
    },
    helpertextcontent: {
      type: String,
    },
    inline: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    options: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
    },
    type: {
      type: String,
      validator(value) {
        return ['checkbox', 'radio'].includes(value);
      },
      default: 'checkbox',
    },
    validationmessage: {
      type: String,
    },
    value: {
      type: [Array, Boolean, String],
    },
  },
  computed: {
    fieldlabel() {
      return this.required ? `${this.label} *` : this.label;
    },

  },
  methods: {
    onBlur() {
      this.$emit('change', this.value);
    },
    onFocus() {
      this.$emit('focus', this.value);
    },
    onInput(value) {
      this.$emit('change', value);
      this.$emit('input', value);

      let displayValue = null;
      if (Array.isArray(value)) {
        displayValue = this.options.filter(r => value.includes(r.value)).map(s => s.label);
      } else {
        displayValue = this.options.find(r => r.value === value).label;
      }

      this.$emit('update-display-value', displayValue);
      if (this.$eventBus) this.$eventBus.$emit('controlUpdated', { label: this.label });
    },
  },
};
</script>
<style lang="scss" scoped>
fieldset {
  border: none;
  margin: 0;
  padding: 0;
  legend {
    display: block;
  }
}
</style>
