<template>
  <grid-cell span="12">
    <validation-provider
      v-slot="{ errors }"
      mode="eager"
      name="environmentKey"
      :rules="{ required: { allowFalse: false } }"
    >
      <hp-option-group
        :value="value"
        :disabled="disabled"
        :helpertextcontent="helpertextcontent"
        inline
        :label="label"
        :options="environments"
        :required="required"
        :validationmessage="errors[0] ? validationmessage : ''"
        @input="$emit('input', $event)"
      />
    </validation-provider>
  </grid-cell>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import HpOptionGroup from '@/componentLibrary/optionGroup/OptionGroup.vue';
import GridCell from '@/componentLibrary/grid/GridCell.vue';
import '@/modules/core/validators/formValidators';

export default {
  name: 'EnvironmentSelector',
  components: {
    GridCell,
    HpOptionGroup,
    ValidationProvider,
  },
  props: {
    label: {
      type: String,
      default: 'Choose the environment(s) you require',
    },
    value: {
      type: Array,
      default: () => [],
    },
    environments: {
      type: Array,
      default: () => [],
    },
    helpertextcontent: {
      type: String,
      default: 'Please select at least one option',
    },
    disabled: {
      type: Boolean,
    },
    validationmessage: {
      type: String,
      default: 'Please select at least one option',
    },
    required: {
      type: Boolean,
    },
  },
  computed: {
    environmentKey() {
      return this.value.join('');
    },
  },
};
</script>
