export default {
  routes: [
    {
      path: '/paas',
      name: 'paas',
      component: () => import('@/modules/paas/views/paasView/PaasView.vue'),
    },
    {
      path: '/paas/azure-sql',
      name: 'azure-sql',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/paas/views/azureSql/AzureSql.vue'
      ),
      children: [
        {
          path: '/paas/azure-sql/overview',
          name: 'azureSqlOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureSqlOverviewTab/AzureSqlOverviewTab.vue'
          ),
        },
        {
          path: '/paas/azure-sql/dashboard',
          name: 'paasAzureSqlDashboard',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureSqlDashboardTab/AzureSqlDashboardTab.vue'
          ),
        },
        {
          path: '/paas/azure-sql/form',
          name: 'azureSqlForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureSqlFormTab/AzureSqlFormTab.vue'
          ),
          meta: {
            requestType: 'AZURE_SQL',
            requestName: 'Azure SQL Database',
          },
        },
        {
          path: '/paas/azure-sql/faq',
          name: 'azureSqlFAQ',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
          ),
        },
      ],
    },
    {
      path: '/paas/azure-sql-managed-instance',
      name: 'azure-sql-managed-instance',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/paas/views/azureSqlManagedInstance/AzureSqlManagedInstance.vue'
      ),
      children: [
        {
          path: '/paas/azure-sql-managed-instance/overview',
          name: 'azureSqlManagedInstanceOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureSqlManagedInstanceOverviewTab/AzureSqlManagedInstanceOverviewTab.vue'
          ),
        },
        {
          path: '/paas/azure-sql-managed-instance/form',
          name: 'azureSqlManagedInstanceForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureSqlManagedInstanceFormTab/AzureSqlManagedInstanceFormTab.vue'
          ),
          meta: {
            requestType: 'AZURE_SQL_MANAGED_INSTANCE',
            requestName: 'Azure SQL Managed Instance',
          },
        },
        {
          path: '/paas/azure-sql-managed-instance/dashboard',
          name: 'azureSqlManagedInstanceDashboard',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureSqlManagedInstanceDashboardTab/AzureSqlManagedInstanceDashboardTab.vue'
          ),
        },
        {
          path: '/paas/azure-sql-managed-instance/faq',
          name: 'azureSqlManagedInstanceFaq',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
          ),
        },
      ],
    },
    {
      path: '/paas/azure-postgresql',
      name: 'paasAzurePostgreSql',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/paas/views/azurePostgreSql/AzurePostgreSql.vue'
      ),
      children: [
        {
          path: '/paas/azure-postgresql/overview',
          name: 'paasAzurePostgreSqlOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azurePostgreSqlOverviewTab/AzurePostgreSqlOverviewTab.vue'
          ),
        },
        {
          path: '/paas/azure-postgresql/form',
          name: 'paasAzurePostgreSqlForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azurePostgreSqlFormTab/AzurePostgreSqlFormTab.vue'
          ),
          meta: {
            requestType: 'AZURE_PAAS_POSTGRESQL',
            requestName: 'Azure PostgreSQL Database',
          },
        },
        {
          path: '/paas/azure-postgresql/dashboard',
          name: 'paasAzurePostgreSqlDashboard',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azurePostgreSqlDashboardTab/AzurePostgreSqlDashboardTab.vue'
          ),
        },
        {
          path: '/paas/azure-postgresql/faq',
          name: 'paasAzurePostgreSqlFaq',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
          ),
        },
      ],
    },
    {
      path: '/paas/azure-load-balancer',
      name: 'azureLoadBalancer',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/paas/views/azureLoadBalancer/AzureLoadBalancerView.vue'
      ),
      children: [
        {
          path: '/paas/azure-load-balancer/overview',
          name: 'azureLoadBalancerOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureLoadBalancerOverviewTab/AzureLoadBalancerOverviewTab.vue'
          ),
        },
        {
          path: '/paas/azure-load-balancer/form',
          name: 'azureLoadBalancerForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureLoadBalancerFormTab/AzureLoadBalancerFormTab.vue'
          ),
          meta: {
            requestType: 'AZURE_PAAS_LOAD_BALANCER',
            requestName: 'Azure Internal Load Balancer',
          },
        },
        {
          path: '/paas/azure-load-balancer/dashboard',
          name: 'azureLoadBalancerDashboard',
          component: () => import(
          /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureLoadBalancerDashboardTab/AzureLoadBalancerDashboardTab.vue'
          ),
        },
        {
          path: '/paas/azure-load-balancer/faq',
          name: 'azureLoadBalancerFaq',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
          ),
        },
      ],
    },
    {
      path: '/paas/azure-app-service',
      name: 'azureAppService',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/paas/views/azureAppService/AzureAppService.vue'
      ),
      children: [
        {
          path: '/paas/azure-app-service/overview',
          name: 'azureAppServiceOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureAppServiceOverviewTab/AzureAppServiceOverviewTab.vue'
          ),
        },
        {
          path: '/paas/azure-app-service/faq',
          name: 'azureAppServiceFaq',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/core/views/markdownFaq/MarkdownFaq.vue'
          ),
        },
        {
          path: '/paas/azure-app-service/form',
          name: 'azureAppServiceForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/azureAppServiceFormTab/AzureAppServiceFormTab.vue'
          ),
          children: [
            {
              path: '/paas/azure-app-service/form/internal',
              name: 'azureAppServiceInternalForm',
              component: () => import(
                /* webpackMode: "lazy" */
                '@/modules/paas/components/azureAppServiceInternalForm/AzureAppServiceInternalForm.vue'
              ),
            },
            {
              path: '/paas/azure-app-service/form/external',
              name: 'azureAppServiceExternalForm',
              component: () => import(
                /* webpackMode: "lazy" */
                '@/modules/paas/components/azureAppServiceExternalForm/AzureAppServiceExternalForm.vue'
              ),
            },
          ],
        },
      ],
    },
    {
      path: '/terraform-modules',
      name: 'terraformModules',
      component: () => import('@/modules/paas/views/terraformModules/TerraformModulesView.vue'),
      children: [
        {
          path: '/paas/terraform-modules/overview',
          name: 'terraformModulesOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/terraformModulesOverviewTab/TerraformModulesOverviewTab.vue'
          ),
        },
        {
          path: '/paas/terraform-modules/catalog',
          name: 'terraformModulesCatalog',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/paas/tabs/terraformModulesCatalogTab/TerraformModulesCatalogTab.vue'
          ),
        },
        {
          path: '/paas/terraform-modules/:id',
          name: 'app-service',
          component: () => import('@/modules/paas/components/terraformModulePage/TerraformModulePage.vue'),
        },
      ],
    },
  ],
};
