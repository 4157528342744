export default {
  routes: [
    {
      path: '/api-documentation',
      name: 'ApiDocumentation',
      component: () => import(
        '@/modules/apiDocumentation/views/apiDocumentation/ApiDocumentation.vue'
      ),
    },
  ],
};
