export default {
  routes: [{
    path: '/network/firewall-rules',
    name: 'firewallRules',
    component: () => import(
      /* webpackMode: "lazy" */
      '@/modules/networkPerimeter/views/firewallRulesView/FirewallRulesView.vue'
    ),
    children: [{
      path: '/network/firewall-rules/overview',
      name: 'firewallRulesOverview',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/networkPerimeter/tabs/firewallRulesOverviewTab/FirewallRulesOverviewTab.vue'
      ),
    }, {
      path: '/network/firewall-rules/form',
      name: 'firewallRulesForm',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/networkPerimeter/tabs/firewallRulesFormTab/FirewallRulesFormTab.vue'
      ),
    }],
  }],
};
