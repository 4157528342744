import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import * as msalService from '../services/msalService';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    const el = document.getElementById('scrollView');
    if (el) el.scrollTo(0, 0);
  },
});

router.onError(({ message }) => router.push({ name: ((message === '403') ? 'forbidden' : 'serverError') }));

router.beforeEach(async (to, _from, next) => {
  if (to.name === 'forbidden') next();

  const { account: { idTokenClaims: { upn } } } = await msalService.getToken(Vue.prototype.$msalInstance);

  return upn ? next() : next({ name: 'forbidden' });
});

export default router;
