import { startCase } from 'lodash';
import { RequestStatus, RequestType } from '@/modules/core/constants';

export default function (rawRequests) {
  const activities = rawRequests || [];
  this.activities = activities.reduce((accumulator, activity) => {
    const activityDetails = {
      name: activity.requestId.toString().split('-')[1] ?
        activity.requestId.toString().split('-')[1] : activity.requestId.toString(),
      ...activity,
      requestTypeLabel:
        RequestType[activity.requestType] ||
        startCase(activity.requestType),
      status: RequestStatus[activity.status] || activity.status,
    };
    accumulator.push(activityDetails);
    return accumulator;
  }, []);
}
