export default {
  id: 'home',
  name: 'home',
  path: '/',
  routeName: 'home',
  desc: '',
  graphic: 'home',
  breadcrumbTitle: 'Home',
  breadcrumbParent: '',
  hideBreadcrumb: true,
  menuTitle: 'Home',
};
