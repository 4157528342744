<template>
  <div
    :class="[
      'input-base hp-input-class',
      { 'input-disabled': disabled },
      { 'input-error': validationmessage }
    ]"
  >
    <label
      v-if="label"
      class="label-container"
    >
      {{ fieldLabel }}
    </label>
    <q-field
      :error-message="validationmessage"
      :error="!!validationmessage"
      no-error-icon
    >
      <template #control>
        <div class="chip-selector__chips row">
          <q-chip
            v-for="chip in chips"
            :key="chip.name"
            v-bind="chip"
            @click="handleChipClick(chip.name)"
          />
        </div>
      </template>
    </q-field>
  </div>
</template>

<script>
export default {
  name: 'ChipSelector',
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    required: {
      type: Boolean,
    },
    validationmessage: {
      type: String,
    },
    value: {
      required: true,
      type: Array,
    },
  },
  computed: {
    fieldLabel() {
      return this.required ? `${this.label} *` : this.label;
    },
    chips() {
      return this.options.map(({ name, label }) => ({
        name,
        label,
        disable: this.disabled,
        clickable: !this.disabled,
        outline: !this.value.includes(name),
        textColor: !this.disabled && this.value.includes(name) ? 'primary' : undefined,
        color: !this.disabled && this.value.includes(name) ? 'primary-selection-highlight' : undefined,
      }));
    },
  },
  methods: {
    handleChipClick(chipName) {
      const index = this.value.findIndex(l => l === chipName);
      const value = [...this.value];
      if (index >= 0) {
        value.splice(index, 1);
      } else {
        value.push(chipName);
      }
      const newValue = this.options.map(({ name }) => name).filter(o => value.includes(o));
      return this.$emit('input', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.chip-selector {
  &__chips {
    display: flex;
    gap: 8px;
  }
}

.label-container {
  margin-bottom: -12px;
}

:deep(.q-chip) {
  color: $color-secondary;
  margin: 0;
  width: 64px;

  .q-chip__content {
    justify-content: center;
  }

  .q-chip--outline {
    outline: 1px solid $color-surface-dark !important;
    border: none;
  }
}

</style>
