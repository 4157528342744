export default {
  routes: [
    {
      path: '/consultancy/cloud-consultancy',
      name: 'cloudConsultancy',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/consultancy/views/cloudConsultancyView/CloudConsultancyView.vue'
      ),
      children: [
        {
          path: '/consultancy/cloud-consultancy/overview',
          name: 'cloudConsultancyOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/consultancy/tabs/cloudConsultancyOverviewTab/CloudConsultancyOverviewTab.vue'
          ),
        },
        {
          path: '/consultancy/cloud-consultancy/form',
          name: 'cloudConsultancyForm',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/consultancy/tabs/cloudConsultancyFormTab/CloudConsultancyFormTab.vue'
          ),
        },
      ],
    },
    {
      path: '/consultancy/on-premise',
      name: 'onPremiseConsultancy',
      component: () => import(
        /* webpackMode: "lazy" */
        '@/modules/consultancy/views/onPremiseConsultancyView/onPremiseConsultancyView.vue'
      ),
      children: [
        {
          path: '/consultancy/on-premise/overview',
          name: 'onPremiseConsultancyOverview',
          component: () => import(
            /* webpackMode: "lazy" */
            '@/modules/consultancy/tabs/onPremiseConsultancyOverviewTab/OnPremiseConsultancyOverviewTab.vue'
          ),
        },
      ],
    },
  ],
};
