<template>
  <q-step
    :name="step"
    :title="title"
    :icon="icon"
    :done="currentStep > step"
    :header-nav="true"
    :disable="disable"
  >
    <slot name="draft" />
    <validation-observer
      ref="form"
      v-slot="{ handleSubmit }"
    >
      <form
        class="form-container"
        @submit.prevent="handleSubmit(submitForm)"
      >
        <slot />

        <q-stepper-navigation>
          <hp-button
            v-if="step"
            :outlined="true"
            class="q-mr-1"
            label="back"
            @click="$emit('previous')"
          />
          <hp-button
            v-if="(step || title !== 'Acknowledgements') && $slots.draft"
            :outlined="true"
            class="q-mr-1"
            label="Save As Draft"
            @click="$emit('draft')"
          />
          <hp-button
            :label="nextButtonLabel"
            :loading="processing"
            @click="handleSubmit(submitForm)"
          />
        </q-stepper-navigation>
      </form>
    </validation-observer>
  </q-step>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import HpButton from '@/componentLibrary/button/Button.vue';

export default {
  name: 'HpStep',
  components: {
    HpButton,
    ValidationObserver,
  },
  props: {
    step: {
      type: Number,
    },
    lastStep: {
      type: Boolean,
    },
    currentStep: {
      type: Number,
    },
    nextLabel: {
      type: String,
    },
    title: {
      type: String,
    },
    icon: {
      type: String,
      default: 'assignment',
    },
    processing: {
      type: Boolean,
      default: false,
    },
    disable: {
      type: Boolean,
    },
  },
  computed: {
    nextButtonLabel() {
      return this.nextLabel || (this.lastStep ? 'Submit' : 'Continue');
    },
  },
  methods: {
    setErrors(e) {
      this.$refs.form.setErrors(e);
    },
    submitForm() {
      this.$emit('next');
    },
  },
};
</script>

<style lang="scss">
.q-stepper {
  background-color: inherit !important;
  position: relative;
  left: -24px;
}
.q-stepper__tab--navigation {
  pointer-events: none;

  .q-stepper__title{
    font-weight: 500;
    font-size: 21px !important;
  }
}
.q-stepper__step-content {
  margin-top: 24px;
}

.q-stepper__tab--navigation.q-stepper__tab--done{
  pointer-events: inherit;
}
.q-stepper__tab--disabled {
   .q-stepper__title {
    font-weight: 500;
    font-size: 21px !important;
  }
}

div.q-stepper.q-stepper--vertical {
  .q-stepper__step {
    overflow: initial;
    position: relative;

    &::before, &::after {
      content: '';
      background: rgba(0, 0, 0, 0.12);
      position: absolute;
      left: 36px;
      width: 1px;
      z-index: 1;
    }

    &::before {
      top: 0;
      height: 4px;
    }

    &::after {
      top: 44px;
      bottom: 0;
    }

    .q-stepper__dot {
      &::before, &::after {
        display: none;
      }
    }

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
</style>
