<template>
  <div
    :class="{
      'input-disabled': disabled,
      'input-error': !!validationmessage,
      'input-label': !validationmessage && !disabled,
      'input-base': true,
      'hp-input-class': true,
    }"
  >
    <label class="label-container">
      {{ fieldlabel }}

      <hp-tooltip
        v-if="helpertextcontent || $slots.helpertextcontent"
        :label="label"
        :helpertextcontent="helpertextcontent"
      >
        <template #helpertextcontent>
          <slot name="helpertextcontent" />
        </template>
      </hp-tooltip>
    </label>
    <q-input
      outlined
      no-error-icon
      :autofocus="autofocus"
      :value="value"
      :counter="counter"
      :disable="disabled"
      :mask="mask"
      :maxlength="+maxlength"
      :error-message="validationmessage"
      :error="!!validationmessage"
      :type="type"
      :prefix="innerPrefix"
      :suffix="innerSuffix"
      :dense="dense"
      :readonly="readonly"
      :placeholder="placeholder"
      @input="updateModel"
      @focus="focusEl"
      @blur="blurEl"
      @change="change"
      @keydown="$emit('keydown', $event)"
      @keyup.enter="$emit('click', value)"
    >
      <template
        v-if="$slots.prepend || $scopedSlots.prepend"
        #prepend
      >
        <slot name="prepend" />
      </template>
      <template
        #append
      >
        <q-btn
          v-if="btn"
          round
          dense
          flat
          :icon="btn"
          @click="$emit('click', value)"
        />
        <slot
          v-if="$slots.append || $scopedSlots.append"
          name="append"
        />
      </template>
      <template
        v-if="prefix"
        #before
      >
        <strong>{{ prefix }}</strong>
      </template>

      <template
        v-if="suffix"
        #after
      >
        <strong>{{ suffix }}</strong>
      </template>
    </q-input>
  </div>
</template>

<script>
import HpTooltip from '../tooltip/Tooltip.vue';

export default {
  name: 'HpInput',
  components: {
    HpTooltip,
  },
  props: {
    autofocus: {
      type: Boolean,
    },
    mask: {
      type: String,
    },
    btn: {
      type: String,
    },
    counter: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    helpertextcontent: {
      default: '',
      type: String,
    },
    innerPrefix: {
      type: String,
    },
    innerSuffix: {
      type: String,
    },
    label: {
      type: String,
    },
    maxlength: {
      default: 255, // not sure on this number it is very much below the math max and should be long enough to hold most strings.
      type: [Number, String],
    },
    prefix: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    suffix: {
      type: String,
    },
    type: {
      default: 'text',
      type: String,
    },
    validationmessage: {
      type: String,
    },
    value: {
      type: [String, Number],
    },
    dense: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
  },
  computed: {
    fieldlabel() {
      return this.required ? `${this.label} *` : this.label;
    },
  },
  methods: {
    focusEl(event) {
      this.$emit('focus', event);
      if (this.$eventBus) this.$eventBus.$emit('controlFocus', { label: this.label });
    },
    blurEl(event) {
      this.$emit('blur', event);
    },
    change() {
      this.$emit('change', this.value);
      if (this.$eventBus) this.$eventBus.$emit('controlUpdated', { label: this.label });
    },
    updateModel(val) {
      const emitVal = (this.type === 'number' ? +val : val);
      this.$emit('input', emitVal);
      this.$emit('update-display-value', emitVal);
    },
  },
};
</script>
