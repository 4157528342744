import { apiClient } from '@/modules/core/services/api';
import PeopleSearchModel from '@/modules/core/models/peopleSearchModel';
import { SystemErrorModel } from '@/modules/core/models/systemErrorModel';

export const getPeople = async searchTerm => {
  try {
    const data = await apiClient.get(
      `search/${searchTerm}`,
    );
    return (new PeopleSearchModel(data)).entries;
  } catch (e) {
    throw new SystemErrorModel(e);
  }
};
