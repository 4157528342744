<template>
  <div data-test-id="request-type-context-error">
    <hp-alert
      type="error"
      heading="Error"
    >
      <p class="text-body2">
        The currently selected project
        <strong>
          {{ projectContextName }}
        </strong>
        does not support the provisioning of
        <strong>
          {{ requestName }}
        </strong>
        , due to project's Business Unit and/or Business Function values.
      </p>
      <p class="text-body2">
        If you would still like to request a
        <strong>
          {{ requestName }}
        </strong>
        , then please consider selecting a different project or switch to classic view with a supported Business Unit and/or Business Function combination.
      </p>
    </hp-alert>
    <hp-button
      label="Switch to Classic View"
      data-test-id="reset-context"
      @click="$emit('reset-context')"
    />
  </div>
</template>

<script>
import HpButton from '@/componentLibrary/button/Button.vue';
import HpAlert from '@/componentLibrary/alert/Alert.vue';

export default {
  name: 'RequestTypeContextError',
  components: {
    HpAlert,
    HpButton,
  },
  props: {
    projectContextName: {
      type: String,
      required: true,
    },
  },
  computed: {
    requestName() {
      const { requestName } = this.$route.meta || {};
      return requestName;
    },
  },
};
</script>
